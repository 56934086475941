
const Node = require('./Node');
const THREE = require('../../../externals/three');


class SceneHierarchy {
  constructor(gltf) {

    this.sceneId = gltf.scene || 0;
    this.sceneRef = gltf.scenes[this.sceneId];

    this.nodes = [];
    this.allNodes = [];
    this.rotationMatrix = new THREE.Matrix4();
    this.rotationMatrix.makeRotationAxis(new THREE.Vector3(1, 0, 0), 0.5 * Math.PI);
    gltf.rotationMatrix = this.rotationMatrix;

    this.sceneRef.nodes.forEach(nodeId => this.nodes.push(new Node(nodeId, gltf, null, this.allNodes, true)));
  }
}

module.exports = SceneHierarchy;