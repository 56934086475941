

const createDOMElement = require('../dom/sd-controls.components.dom.dom-util').createDomElement;
const setDomElementEventListener = require('../dom/sd-controls.components.dom.dom-util').setDomElementEventListener;
const GLOBAL_UTILS = require('../../../../../shared/util/GlobalUtils');
const MenuComponent = require('../base/sd-controls.components.base').MenuComponent;

const log = {
  showMessage: () => { }
}

/**
 * Creates the file menu element.
 *
  * @param {String} id The id of the parameter or name of the setting. Unused in case of accordion.
   * @param {TYPES} type The type of menu element that is created.
   * @param {Object} param The parameters that should be used for this menu element.
   *  They can vary widely for different element but must contain an id and name.
   *  The specific values needed can be seen in the createElement functions.
   * @param {Object} controlsContext The controls context which holds all required dependencies, which include following
   * @param {String} [controlsContext.runTimeId] The runtime id.
   * @param {DomElement} [controlsContext.container] The dom object which is container for this controls.
   * @param {Object} [controlsContext.options] The viewer options.
   * @param {Object} [controlsContext.options.settings] If true, these are the controls for the settings
   * @param {Object} [controlsContext.options.editMode] If true, and this is not a settings object, the controls are made in edit mode
   * @param {DomElement} rowParent Changes parent of row, which is by default container. This is mainly for accordions.
   */
function FileInputMenuComponent(id, type, param, controlsContext, parentRow) {
  MenuComponent.call(this, type, param, controlsContext, parentRow);
  log.showMessage = controlsContext.options && controlsContext.options.showMessage ? controlsContext.options.showMessage : () => { };

  let title = param.name,
    drag_handler_dummy,
    drop_handler,
    files_dropped = null;

  // Change the label to show 'Select file', or the selected file name
  //this.row.removeChild(this.label);
  this.label.innerHTML = 'Select file…';

  // create the button dom element
  this.inputComponent = createDOMElement('button', {
    id: this.slugId + '_button', parent: this.inputContainer, innerHTML: title,
    classList: ['uk-button', 'uk-button-default', 'uk-button-small', 'uk-width-expand', 'text-transform-none', 'uk-padding-remove', 'sd-control-button'],
    attributes: { 'type': 'button', 'name': this.slugId + '_button' }
  });

  // create the hidden file input dom element (we don't want to see the ugly file input button)
  this.fileinput = createDOMElement('input', {
    id: this.slugId + '_fileinput', parent: this.inputContainer, style: 'display:none',
    accept: param.format.join(','),
    attributes: { 'type': 'file', 'name': this.slugId + '_fileinput' }
  });
  this.fileinput.style.display = 'none';

  // trigger fileinput click from clicks on the button
  this.inputComponent.addEventListener('click', function () {
    this.fileinput.click();
  }.bind(this));

  // callback for event listener, to get the current value, we return the select File object or an empty string
  this.valueConversion = function (el, fileinput) {
    if (files_dropped && files_dropped.length > 0) {
      let file = files_dropped[0];
      files_dropped = null;
      return file;
    } else if (fileinput.files.length > 0) {
      return fileinput.files[0];
    } else {
      return '';
    }
  };

  // helper for checking format
  this.checkMimeType = function (type) {
    return param.format.includes(type);
  };

  // helper for getting parameter definition
  this.getParamDef = function () {
    return param;
  };

  // helper for checking maximum blob size
  this.checkSize = function (size) {
    return size <= param.max;
  };

  // helper for clearing selected file
  this.clear = function () {
    files_dropped = null;
    this.pushToValueStack(null);
    this.currentValue = null;
    if (this.fileinput.files.length > 0) {
      this.fileinput.value = null;
    } else {
      this.change_handler();
    }
  };

  // set the event listeners
  this.change_handler = setDomElementEventListener(controlsContext.api, id, this, this.fileinput, 'change', this.update.bind(this));

  // drag event handling
  drag_handler_dummy = function (e) {
    e.stopPropagation();
    e.preventDefault();
  };
  this.inputComponent.addEventListener('dragenter', drag_handler_dummy, false);
  this.inputComponent.addEventListener('dragover', drag_handler_dummy, false);

  drop_handler = function (e) {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer) {
      files_dropped = e.dataTransfer.files;
    }
    this.change_handler();
  };
  this.inputComponent.addEventListener('drop', drop_handler, false);
}

FileInputMenuComponent.prototype = Object.create(MenuComponent.prototype);

FileInputMenuComponent.prototype.uploadError = function () {
  this.label.innerHTML = 'upload failed';
  this.label.classList.add('uk-text-danger');
}

FileInputMenuComponent.prototype.removeUploadError = function () {
  this.label.innerHTML = '';
  this.label.classList.remove('uk-text-danger');
}

/**
 * Update the menu elements.
 *
 * @param {String} value The new value
 */
FileInputMenuComponent.prototype.update = function (value) {

  if(this.inContentEditMode){
    return false;
  }

  this.pushToValueStack(value);
  this.currentValue = value;

  if (value instanceof File) {

    // get the format, if there is none guess it from the name
    if (value.type.length > 0) {
      value._type = value.type;
    } else {
      value._type = GLOBAL_UTILS.guessMimeTypeFromFilename(value.name);
    }
    // deny update if the format is not allowed
    if (!this.checkMimeType(value._type)) {
      this.uploadError();
      log.showMessage('warning', 'Allowed file types: ' + this.getParamDef().format.join(', '));
      return false;
    }
    // deny update if the file size is exceeded
    if (!this.checkSize(value.size)) {
      this.uploadError();
      log.showMessage('warning', 'Maximum file size: ' + this.getParamDef().max + ' bytes');
      return false;
    }
    this.removeUploadError();
    this.label.innerHTML = '<div uk-spinner="ratio: 0.5"></div>';
    this.fileName = value.name;
  } else if (typeof value === 'string' && value.length > 0) {

    // show the filename, ignore file id, instead attach an icon to the label which already shows the filename
    if (this.fileName) { // there might be no filename
      if(this.fileName.length > 22)
      {
        this.label.innerHTML = `${this.fileName.substr(0, 20)}... \u274C`;
      }
      else 
      {
         this.label.innerHTML = `${this.fileName}  \u274C`;
      }
    } else {
      this.label.innerHTML = 'Saved file  \u274C';
    }
    // make label clickable to remove selected file
    this.label.onclick = () => {
      this.clear();
    };
  } else {
    this.label.innerHTML = 'Select file…';
    this.label.onclick = null;
  }
  return true;
}

FileInputMenuComponent.prototype.setEditable = function (val) {

  if (val) {
      this.inputComponent.contentEditable = true;
      this.inputComponent.addEventListener('input', function (event) {
          this.wasEdited = true;
          this.newName = event.target.innerText;
      }.bind(this));
  } else {
      this.inputComponent.contentEditable = false;
  }
  this.inContentEditMode = val;
}

module.exports = FileInputMenuComponent;
