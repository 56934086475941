
const WebGLConstants = require('./WebGLConstants');
const ELEM_IN_TYPE = {
  'SCALAR': 1,
  'VEC2': 2,
  'VEC3': 3,
  'VEC4': 4,
};

const SIZE_OF_ELEM_IN_BYTES = {
  [WebGLConstants.UNSIGNED_BYTE]: 1,
  [WebGLConstants.UNSIGNED_SHORT]: 2,
  [WebGLConstants.UNSIGNED_INT]: 4,
  [WebGLConstants.FLOAT]: 4
};



class Accessor {
  constructor(id, gltf, buffer, bufferView) {
    this.buffer = buffer;
    this.bufferView = bufferView;
    this.id = id;
    this.accessorRef = gltf.accessors[id];
    this.type = this.accessorRef.type;
    this.componentType = this.accessorRef.componentType;
    this.offsetForInterleaved = this.bufferView.numOfTypeArrayElementsPerVertex;
    this.byteOffset = this.accessorRef.byteOffset || 0;
    this.min = this.accessorRef.min;
    this.max = this.accessorRef.max;

    // total elements in structure, for example, vec3 has x,y,z therefore 3
    this.elementComponentsSize = ELEM_IN_TYPE[this.type];

    // size of each element. For example vec3 has floats, and each float is 4 sbytes
    this.bytesPerElement = SIZE_OF_ELEM_IN_BYTES[this.componentType];
    const isFloatArray = this.componentType === WebGLConstants.FLOAT;

    // count of elements, for example 24 vec3 elements is 24
    this.count = this.accessorRef.count;

    // total byte length, for example count of each vec3 24, count of each element in vec3 x,y,z and each element byte size 4
    // therefore 24 * 3 * 4
    this.byteLength = this.count * this.elementComponentsSize * this.bytesPerElement;


    const dataView = this.bufferView.dataView;

    this.bufferArray = null;

    let array = [];

    if (this.bufferView.isInterleaved) {

      if (this.bufferView.interleavedBuffer == null) {
     
        array = this._processInterleavedBufferArray(dataView, isFloatArray);
      }
    } else {

      array = this._processBufferArray(dataView, isFloatArray);

    }

    // final array
    switch (this.bytesPerElement) {
      case 1:
        this.bufferArray = new Uint8Array(array);
        break;
      case 2:
        this.bufferArray = new Uint16Array(array);
        break;
      case 4:
        if (isFloatArray) {
          this.bufferArray = new Float32Array(array);
        } else {
          this.bufferArray = new Uint32Array(array);
        }
        break;
    }

    this.array = array;
    if (this.bufferView.isInterleaved) {
      this.bufferView.interleavedBuffer = this.bufferArray;
    }
  }

  _processInterleavedBufferArray(dataView, isFloatArray) {
    const array = [];
    let k = 0;
    for (let i = 0; i < this.bufferView.byteLength; i += this.bytesPerElement) {

      let byte = 0;
      switch (this.bytesPerElement) {
        case 1:
          byte = dataView.getUint8(i, true);
          break;
        case 2:
          byte = dataView.getUint16(i, true);
          break;
        case 4:
          if (isFloatArray) {
            byte = dataView.getFloat32(i, true);
            if (this.min) {
              if (byte < this.min[k]) {
                byte = this.min[k];
              }
            }
            if (this.max) {
              if (byte > this.max[k]) {
                byte = this.max[k];
              }
            }
            k++;
            if (k >= this.elementComponentsSize) {
              k = 0;
            }
          } else {
            byte = dataView.getUint32(i, true);
          }
          break;
      }
      array.push(byte);
    }

    return array;
  }

  _processBufferArray(dataView, isFloatArray) {
    const array = [];
    let k = 0;
    for (let i = this.byteOffset; i < this.byteOffset + this.byteLength; i += this.bytesPerElement) {

      let byte = 0;
      switch (this.bytesPerElement) {
        case 1:
          byte = dataView.getUint8(i, true);
          break;
        case 2:
          byte = dataView.getUint16(i, true);
          break;
        case 4:
          if (isFloatArray) {
            byte = dataView.getFloat32(i, true);
            if (this.min) {
              if (byte < this.min[k]) {
                byte = this.min[k];
              }
            }
            if (this.max) {
              if (byte > this.max[k]) {
                byte = this.max[k];
              }
            }
            k++;
            if (k >= this.elementComponentsSize) {
              k = 0;
            }
          } else {
            byte = dataView.getUint32(i, true);
          }
          break;
      }
      array.push(byte);
    }

    return array;
  }
}

module.exports = Accessor;