"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var sd_controls_menu_element_factory_1 = require("../../components/util/sd-controls.menu-element.factory");
var sd_controls_constants_types_1 = require("../../constants/sd-controls.constants.types");
var enums_1 = require("../../enums/enums");
var sd_controls_setting_1 = require("./sd.controls.setting");
var LoadLightSceneDropdownSetting = /** @class */ (function (_super) {
    __extends(LoadLightSceneDropdownSetting, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function LoadLightSceneDropdownSetting(api, controlsContext, settingsContext) {
        var _this = _super.call(this, api, "lights.lightScene", sd_controls_constants_types_1.TYPES.DROPDOWN, "Load Light Scene", "Load one of the stored light scene definitions", enums_1.SettingsGroups.LIGHTS, Number.POSITIVE_INFINITY) || this;
        _this.controlsContext = controlsContext;
        _this.settingsContext = settingsContext;
        // #region Properties (3)
        _this.createInitalLightsCalled = false;
        _this.valueConversion = {
            update: "selectedIndex",
            f: function (menuEl, obj) {
                return obj.options[obj.selectedIndex].innerHTML;
            },
            i: function (menuEl, val) {
                return menuEl.choices.indexOf(val);
            }
        };
        _this.choices = _this.api.scene.lights.getAllLightScenes().data;
        return _this;
    }
    // #endregion Constructors (1)
    // #region Public Methods (2)
    LoadLightSceneDropdownSetting.prototype.createLightElements = function () {
        if (this.createInitalLightsCalled)
            return;
        this.createInitalLightsCalled = true;
        this.controlsContext.controlsEvents.trigger(enums_1.LightEvents.CLEAR_LIGHTS);
        var choices = this.api.scene.lights.getAllLightScenes().data;
        this.choices = choices;
        var v = this.api.scene.lights.getLightScene().data.id;
        for (var _i = 0, _a = this.getMenuElements(); _i < _a.length; _i++) {
            var menuEl = _a[_i];
            if (this.choices.includes(v)) {
                menuEl.createChoices(this.choices, this.valueConversion.i(menuEl, v));
            }
        }
        this.createLights(v);
    };
    LoadLightSceneDropdownSetting.prototype.customUpdate = function (id, v, event, menuEl) {
        this.controlsContext.controlsEvents.trigger(enums_1.LightEvents.CLEAR_LIGHTS);
        var choices = this.api.scene.lights.getAllLightScenes().data;
        this.choices = choices;
        menuEl.createChoices(choices, this.valueConversion.i(menuEl, v));
        this.createLights(v);
    };
    // #endregion Public Methods (2)
    // #region Private Methods (1)
    LoadLightSceneDropdownSetting.prototype.createLights = function (v) {
        var _this = this;
        var lights = [];
        if (this.choices.includes(v)) {
            this.api.scene.lights.setLightSceneFromID(v).data;
            lights = this.api.scene.lights.getLightScene(v).data.lights;
        }
        else {
            lights = this.api.scene.lights.getLightScene().data.lights;
        }
        Object.keys(lights).forEach(function (key) {
            var light = lights[key];
            var setting = _this.settingsContext.createLightSetting(light);
            _this.settingsContext.settings[light.id] = setting;
            var elem = sd_controls_menu_element_factory_1.createMenuElement(setting.type, setting, _this.controlsContext, _this.controlsContext.groups.getGroup(setting.groupId));
            elem.row.parentNode.insertBefore(elem.row, _this.settingsContext.settings["add.lights.scene"].getMenuElements()[0].row);
            _this.controlsContext.controlsEvents.trigger(enums_1.LightEvents.ADD, elem);
        });
    };
    return LoadLightSceneDropdownSetting;
}(sd_controls_setting_1.Setting));
exports.LoadLightSceneDropdownSetting = LoadLightSceneDropdownSetting;
