const TextureLoader = (require('../TextureLoader'));

const DATA_URI_REGEX = /^data:(.*?)(;base64)?,(.*)$/;

class Texture {
  constructor(id, gltf) {

    this.id = id;
    this.textureRef = gltf.textures[this.id];
    this.imageRef = gltf.images[this.textureRef.source];
    this.texture = null;

    this.onLoad = new Promise((resolve, reject) => {

      const url = DATA_URI_REGEX.test(this.imageRef.uri) ? this.imageRef.uri : `${gltf.baseUrl}/${this.imageRef.uri}`;

      TextureLoader.loadTexture(url).then(tex => {
        tex.flipY = false;
        this.texture = tex;
        resolve(this);
      }, any => reject(any));

    });
  }

  toJSONTexture() {
    return {
      href: this.href
    };
  }
}


module.exports = Texture;