
let OrbitControlsProxyStandardImplementation = function (___handlers) {
  const _handlers = ___handlers,
        OrbitControlsProxyInterface = require('../interfaces/OrbitControlsProxyInterface');

  let that;

  class OrbitControlsProxyStandardImplementation extends OrbitControlsProxyInterface {

    constructor() {
      super();
      that = this;
    }

    getClientSize() {
      return {
        width: _handlers.renderingHandler.getDomElement().clientWidth,
        height: _handlers.renderingHandler.getDomElement().clientHeight,
      };
    }

    onStateChange() {
      document.addEventListener('mousemove', that.onMouseMove, { passive: false }, false);
      document.addEventListener('mouseup', that.onMouseUp, { passive: false }, false);
      document.addEventListener('mouseout', that.onMouseUp, { passive: false }, false);
    }

    onStateChangeNone() {
      document.removeEventListener('mousemove', that.onMouseMove, false);
      document.removeEventListener('mouseup', that.onMouseUp, false);
      document.removeEventListener('mouseout', that.onMouseUp, false);
    }

    dispose() {
      _handlers.renderingHandler.getDomElement().removeEventListener('contextmenu', that.onContextMenu, false);
      _handlers.renderingHandler.getDomElement().removeEventListener('mousedown', that.onMouseDown, false);
      _handlers.renderingHandler.getDomElement().removeEventListener('mousewheel', that.onMouseWheel, false);
      _handlers.renderingHandler.getDomElement().removeEventListener('MozMousePixelScroll', that.onMouseWheel, false); // firefox

      _handlers.renderingHandler.getDomElement().removeEventListener('touchstart', that.onTouchStart, false);
      _handlers.renderingHandler.getDomElement().removeEventListener('touchend', that.onTouchEnd, false);
      _handlers.renderingHandler.getDomElement().removeEventListener('touchmove', that.onTouchMove, false);

      document.removeEventListener('mousemove', that.onMouseMove, false);
      document.removeEventListener('mouseup', that.onMouseUp, false);
      document.removeEventListener('mouseout', that.onMouseUp, false);

      window.removeEventListener('keydown', that.onKeyDown, false); 
    }

    initialize() {
      _handlers.renderingHandler.getDomElement().addEventListener('contextmenu', that.onContextMenu, {passive: false}, false);

      _handlers.renderingHandler.getDomElement().addEventListener('mousedown', that.onMouseDown, {passive: false}, false);
      _handlers.renderingHandler.getDomElement().addEventListener('mousewheel', that.onMouseWheel, {passive: false}, false);
      _handlers.renderingHandler.getDomElement().addEventListener('MozMousePixelScroll', that.onMouseWheel, {passive: false}, false); // firefox

      _handlers.renderingHandler.getDomElement().addEventListener('touchstart', that.onTouchStart, {passive: false}, false);
      _handlers.renderingHandler.getDomElement().addEventListener('touchend', that.onTouchEnd, {passive: false}, false);
      _handlers.renderingHandler.getDomElement().addEventListener('touchmove', that.onTouchMove, {passive: false}, false);

      window.addEventListener('keydown', that.onKeyDown, {passive: false}, false);
    }

  }

  return new OrbitControlsProxyStandardImplementation();
};

module.exports = OrbitControlsProxyStandardImplementation;
