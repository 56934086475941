"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ParameterType_1 = require("../../../../modules/enums/controls/ParameterType");
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
/**
 * Definition for empty custom
 */
var StaticHTMLCustomComponent = /** @class */ (function (_super) {
    __extends(StaticHTMLCustomComponent, _super);
    // #region Constructors (1)
    function StaticHTMLCustomComponent(id, parameterDefinition, parent, domManager, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, ParameterType_1.ParameterType.STATICHTML, id, parameterDefinition, parent, domManager, editMode) || this;
        _this.createElement();
        return _this;
    }
    Object.defineProperty(StaticHTMLCustomComponent.prototype, "size", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return interfaces_1.ControlElementSize.FULL_WIDTH;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Protected Methods (4)
    StaticHTMLCustomComponent.prototype.createElement = function () {
        this.createRow();
        this.row.innerHTML = this._parameterDefinition.content;
        this.row.classList.remove("uk-padding-small", "uk-margin-remove");
        this.row.classList.add("uk-padding-remove-left", "uk-margin-remove-left", "uk-margin-remove-top");
    };
    StaticHTMLCustomComponent.prototype.createInput = function () {
        // no implementation
    };
    StaticHTMLCustomComponent.prototype.createInputEvents = function () {
        // no implementation
    };
    StaticHTMLCustomComponent.prototype.update = function (v, event) {
        // no implementation
    };
    return StaticHTMLCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.StaticHTMLCustomComponent = StaticHTMLCustomComponent;
