"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StringInputCustomComponent_1 = require("../custom-components/StringInputCustomComponent");
var BooleanInputCustomComponent_1 = require("../custom-components/BooleanInputCustomComponent");
var Vector3CustomComponent_1 = require("../custom-components/Vector3CustomComponent");
var ButtonCustomComponent_1 = require("../custom-components/ButtonCustomComponent");
var ChecklistCustomComponent_1 = require("../custom-components/ChecklistCustomComponent");
var ParameterType_1 = require("../../../../modules/enums/controls/ParameterType");
var ColorCustomComponent_1 = require("../custom-components/ColorCustomComponent");
var DropdownCustomComponent_1 = require("../custom-components/DropdownCustomComponent");
var FileCustomComponent_1 = require("../custom-components/FileCustomComponent");
var ModalCustomComponent_1 = require("../custom-components/ModalCustomComponent");
var SliderCustomComponent_1 = require("../custom-components/SliderCustomComponent");
var GroupCustomComponent_1 = require("../custom-components/GroupCustomComponent");
var StaticHTMLCustomComponent_1 = require("../custom-components/StaticHTMLCustomComponent");
var StaticHTMLSettingComponent_1 = require("../settings-components/StaticHTMLSettingComponent");
/**
 * The controls component factory.
 */
var SDControlsComponentFactory = /** @class */ (function () {
    // #region Constructors (1)
    /**
     * The controls component factory constructor.
     */
    function SDControlsComponentFactory(_domManager, _tinyColorFactory, _fileHelper, _useSpectrum) {
        this._domManager = _domManager;
        this._tinyColorFactory = _tinyColorFactory;
        this._fileHelper = _fileHelper;
        this._useSpectrum = _useSpectrum;
    }
    // #endregion Constructors (1)
    // #region Public Methods (1)
    /**
     * Create the user defined parameter.
     * @param { IControlParameterDefinition } parameterDefition
     */
    SDControlsComponentFactory.prototype.createUserDefinedParameter = function (parameterDefition, container, editMode) {
        var type = parameterDefition.type;
        var id = this.generateUUID();
        switch (type) {
            case ParameterType_1.ParameterType.STRING:
                return new StringInputCustomComponent_1.StringInputCustomComponent(id, parameterDefition, container, this._domManager, editMode);
            case ParameterType_1.ParameterType.CHECKBOX:
                return new BooleanInputCustomComponent_1.BooleanInputCustomComponent(id, parameterDefition, container, this._domManager, editMode);
            case ParameterType_1.ParameterType.VECTOR3:
                return new Vector3CustomComponent_1.Vector3CustomComponent(id, parameterDefition, container, this._domManager, editMode);
            case ParameterType_1.ParameterType.BUTTON:
                return new ButtonCustomComponent_1.ButtonCustomComponent(id, parameterDefition, container, this._domManager, editMode);
            case ParameterType_1.ParameterType.CHECKLIST:
                return new ChecklistCustomComponent_1.ChecklistCustomComponent(id, parameterDefition, container, this._domManager, editMode);
            case ParameterType_1.ParameterType.GROUP:
                return new GroupCustomComponent_1.GroupCustomComponent(id, parameterDefition, container, this._domManager, this, editMode);
            case ParameterType_1.ParameterType.COLOR:
                return new ColorCustomComponent_1.ColorCustomComponent(id, parameterDefition, container, this._domManager, this._useSpectrum, this._tinyColorFactory);
            case ParameterType_1.ParameterType.DROPDOWN:
                return new DropdownCustomComponent_1.DropdownCustomComponent(id, parameterDefition, container, this._domManager, editMode);
            case ParameterType_1.ParameterType.FILE:
                return new FileCustomComponent_1.FileCustomComponent(id, parameterDefition, container, this._domManager, this._fileHelper, editMode);
            case ParameterType_1.ParameterType.MODAL:
                return new ModalCustomComponent_1.ModalCustomComponent(id, parameterDefition, container, this._domManager, editMode);
            case ParameterType_1.ParameterType.SLIDER:
                return new SliderCustomComponent_1.SliderCustomComponent(id, parameterDefition, container, this._domManager, editMode);
            case ParameterType_1.ParameterType.STATICHTML:
                return new StaticHTMLCustomComponent_1.StaticHTMLCustomComponent(id, parameterDefition, container, this._domManager, editMode);
        }
        throw new Error("Cannot create input component of type  " + parameterDefition.type);
    };
    SDControlsComponentFactory.prototype.createSetting = function (settingDefinition, container) {
        var type = settingDefinition.type;
        switch (type) {
            case ParameterType_1.ParameterType.STATICHTML:
                return new StaticHTMLSettingComponent_1.StaticHTMLSettingComponent(settingDefinition, container, this);
        }
        throw new Error("Cannot create setting component of type " + settingDefinition.type);
    };
    // #endregion Public Methods (1)
    // #region Private Methods (1)
    /**
     * Create unique id.
     * @returns { string }
     */
    SDControlsComponentFactory.prototype.generateUUID = function () {
        var d = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };
    return SDControlsComponentFactory;
}());
exports.SDControlsComponentFactory = SDControlsComponentFactory;
