"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var ParameterType_1 = require("../../../../modules/enums/controls/ParameterType");
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var ColorCustomComponent = /** @class */ (function (_super) {
    __extends(ColorCustomComponent, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function ColorCustomComponent(id, parameterDefinition, parent, domManager, _useSpectrum, _sdTinyColorFactory, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, ParameterType_1.ParameterType.COLOR, id, parameterDefinition, parent, domManager, editMode) || this;
        _this._useSpectrum = _useSpectrum;
        _this._sdTinyColorFactory = _sdTinyColorFactory;
        _this._inputId = id + "-color";
        _this._defaultValue = _sdTinyColorFactory.createTinyColor(_this._parameterDefinition.value);
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(ColorCustomComponent.prototype, "colorPicker", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._colorPicker;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorCustomComponent.prototype, "size", {
        get: function () {
            return interfaces_1.ControlElementSize.QTR_WIDTH;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (2)
    // #region Protected Methods (3)
    ColorCustomComponent.prototype.createInput = function () {
        this._useSpectrum ? this.createSpectrumControls() : this.createNonSpectrumControls();
    };
    ColorCustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._inputComponent, "change", 10, this.update.bind(this));
    };
    ColorCustomComponent.prototype.update = function (v, event) {
        this._parameterDefinition.update(v);
    };
    // #endregion Protected Methods (3)
    // #region Private Methods (2)
    ColorCustomComponent.prototype.createNonSpectrumControls = function () {
        this._inputComponent = this._domManager.createDomElement("input", this._inputId, this._inputContainer, ["uk-input", "uk-form-small", "uk-form-width-xsmall"], { name: this._inputId });
        this._inputComponent.setAttribute("type", "color");
        this._inputComponent.value = this._defaultValue.toHexString();
        this._colorPicker = this._inputComponent;
    };
    ColorCustomComponent.prototype.createSpectrumControls = function () {
        var _this = this;
        this._inputComponent = this._domManager.createDomElement("input", this._inputId, this._inputContainer, ["uk-hidden"], { type: 'text' });
        $("#" + this._inputId).spectrum({
            color: this._defaultValue.toRgbString(),
            replacerClassName: "uk-padding-remove",
            showInput: true,
            showInitial: true,
            preferredFormat: "rgb",
            change: function (color) {
                _this._inputComponent.setAttribute("value", color.toHexString());
                var event = document.createEvent("Event");
                event.initEvent("change", false, true);
                _this._inputComponent.dispatchEvent(event);
            }
        });
        this._colorPicker = this.row.getElementsByClassName("sp-replacer")[0];
    };
    return ColorCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.ColorCustomComponent = ColorCustomComponent;
