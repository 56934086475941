/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiImplementationV1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer API V1
 *
 * @module ApiImplementationV1
 * @author ShapeDiver <contact@shapediver.com>
 */

/**
 * The exception used for reporting missing implementations.
 */
//var MissingImplementationException = require('../../shared/exceptions/MissingImplementationException');

/**
  * Imported plugin constant definitions
  */
var pluginConstants = require('../../shared/constants/PluginConstantsGlobal');

/**
  * Imported messaging constant definitions
  */
var messagingConstants = require('../../shared/constants/MessagingConstants');

/**
 * Import GlobalUtils
 */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
 * Import ThreeDManager constants
 */
var threeDManagerConstants = require('../../3d/viewport/ThreeDManagerConstants');

/**
 * Utility for converting color strings
 */
var toTinyColor = require('../../shared/util/toTinyColor');

/**
 * ApiInterfaceV1
 */
var ApiInterfaceV1 = new (require('./ApiInterfaceV1'))();

/**
 * We need three.js for draggable shapes
 */
let THREE = require('../../externals/three');

/**
 * ShapeDiver 3D Viewer API V1
 *
 * @class
 * @implements {module:ApiInterfaceV1~ApiInterfaceV1}
 * @param {Object} references - Object containing references to various handlers
 * @param {Object} references.app - Reference to the complete app (will likely be removed at some point)
 * @param {Object} references.viewportVisibilityHandler - Reference to the viewport visibility handler
 * @param {Object} references.exportHandler - Reference to the export handler
 * @param {Object} references.loggingHandler - Reference to the logging handler
 * @param {Object} references.messagingHandler - Reference to the messaging handler
 * @param {Object} references.parameterHandler - Reference to the parameter handler
 * @param {Object} references.pluginHandler - Reference to the plugin handler
 * @param {Object} references.processStatusHandler - Reference to the process status handler
 * @param {Object} references.sceneManager - Reference to the scene manager
 * @param {Object} references.threeDManager - Reference to the 3D manager
 */
var ApiImplementationV1 = function(___refs) {

  var that = this;

  ////////////
  ////////////
  //
  // Preparation
  //
  ////////////
  ////////////

  // shortcuts for accessing ViewerApp functionality
  var _app = ___refs.app;
  var _viewportVisibilityHandler = ___refs.viewportVisibilityHandler;
  var _exportHandler = ___refs.exportHandler;
  var _messagingHandler = ___refs.messagingHandler;
  var _parameterHandler = ___refs.parameterHandler;
  var _pluginHandler = ___refs.pluginHandler;
  var _processStatusHandler = ___refs.processStatusHandler;
  var _sceneManager = ___refs.sceneManager;
  var _threeDManager = ___refs.threeDManager;

  // inject logging functionality
  GlobalUtils.inject(___refs.loggingHandler, that);

  // get access to API v2
  var _apiv2 = _app.api({version: 2});
  var _apiv2_runtime_id = _apiv2.getRuntimeId();

  // inject global helpers
  require('../../shared/mixins/GlobalMixin').call(this);

  // include enums from interface definition
  this.STATUSCODE = ApiInterfaceV1.STATUSCODE;
  this.TYPE = ApiInterfaceV1.TYPE;
  this.VISUALIZATION = ApiInterfaceV1.VISUALIZATION;

  /**
   * Keep tokens for process message subscriptions
   */
  var _processSubscriptions = {};

  /**
   * Command result callback
   */
  var _commandResultCallback;

  /**
   * Helper for calling the command result callback
   */
  var _callCommandResultCallback = function(cmd, res) {
    if (typeof _commandResultCallback === 'function') {
      try {
        _commandResultCallback(cmd, res);
      } catch (e) {
        that.error('ApiImplementationV1._callCommandResultCallback', 'Exception in callback:', cmd, e);
      }
    }
    return res;
  };

  /**
   * Calling the command result callback must be accessible by package.js
   */
  this._callCommandResultCallback = _callCommandResultCallback;

  ////////////
  ////////////
  //
  // Preparation for draggable shapes
  //
  ////////////
  ////////////

  /**
   * Name of interaction group for draggable shapes
   */
  const _interactionGroupName = _apiv2_runtime_id;

  /**
   * Add interaction group for draggable shapes
   */
  var _createInteractionGroup = function() {
    let res = _apiv2.scene.updateInteractionGroups(
      {
        id: _interactionGroupName,
        draggable: true,
        selectable: false,
        hoverable: false
      }
    );
    if (res.data !== true) {
      that.error('ApiImplementationV1._createInteractionGroup', 'Could not add interaction group', res.err);
      return false;
    }
    return true;
  };

  /**
   * Container for last positions of draggable shapes
   */
  var _draggableShapePositions = {};

  /**
  * Event listener function for draggable objects
  */
  var _draggableShapeEventListener = function(event) {
    if (event.type === _apiv2.scene.EVENTTYPE.DRAG_START) {
      _sendExternalMsg('ObjectDragStarted');
    } else if (event.type === _apiv2.scene.EVENTTYPE.DRAG_END) {
      _sendExternalMsg('ObjectDragEnded');
    }
    if (event.dragPosAbs && event.scenePath) {
      let scenePathParts = event.scenePath.split('.');
      _draggableShapePositions[scenePathParts[1]] = event.dragPosAbs;
    }
  };

  /**
  * Container for draggable shape event listener tokens
  */
  var _draggableShapeEventListenerTokens = [];

  /**
  * Function for adding/removing event listeners for draggable objects
  */
  var _addOrClearDraggableShapeEventListeners = function() {
    if ( Object.keys(_draggableShapePositions).length > 0 ) {
      let response;
      response = _apiv2.scene.addEventListener(_apiv2.scene.EVENTTYPE.DRAG_START + '.' + _apiv2_runtime_id, _draggableShapeEventListener);
      if (response.data) _draggableShapeEventListenerTokens.push(response.data);
      response = _apiv2.scene.addEventListener(_apiv2.scene.EVENTTYPE.DRAG_MOVE + '.' + _apiv2_runtime_id, _draggableShapeEventListener);
      if (response.data) _draggableShapeEventListenerTokens.push(response.data);
      response = _apiv2.scene.addEventListener(_apiv2.scene.EVENTTYPE.DRAG_END + '.' + _apiv2_runtime_id, _draggableShapeEventListener);
      if (response.data) _draggableShapeEventListenerTokens.push(response.data);
    } else {
      _draggableShapeEventListenerTokens.forEach((token) => {
        _apiv2.scene.removeEventListener(token);
      });
      _draggableShapeEventListenerTokens = [];
    }
  };

  ////////////
  ////////////
  //
  // External messaging
  //
  ////////////
  ////////////

  /**
   * Keep tokens for message subscriptions for messaging callback
   */
  var _messagingCbSubscriptions = [];

  /**
   * External message callback. Only a single one can be set, exactly as it was implemented in the old viewer.
   */
  var _messagingCb;

  /**
   * Function which gets invoked for external messaging
   */
  var _sendExternalMsg = function(msg) {
    if (typeof _messagingCb === 'function') {
      try {
        _messagingCb(msg);
      } catch (e) {
        that.error('ApiImplementationV1._sendExternalMsg', 'Exception in messaging callback ('+msg+'):', e);
      }
    }
  };

  /**
   * Clear subscriptions for messaging callback
   */
  var _clearMessagingCbSubscriptions = function() {
    _messagingCbSubscriptions.forEach((token) => {
      _messagingHandler.unsubscribeFromMessageStream(token);
    });
    _messagingCbSubscriptions = [];
  };

  /**
   * Create subscriptions for messaging callback
   */
  var _createMessagingCbSubscriptions = function() {
    if (_messagingCbSubscriptions.length > 0) return;

    // we implement the following external messages, some of them are handled elsewhere in this file
    // LoadStaticMeshDone
    // ObjectDragStarted
    // ObjectDragEnded
    // GeometryUpdateVisible
    // InitialSceneReady
    // SessionTimeout - not required anymore (sessions are re-initiated automatically)

    // callback for SCENE_SUBSCENE_PUBLISHED
    let t = messagingConstants.messageTopics.SCENE_SUBSCENE_PUBLISHED;
    let subTokens = _messagingHandler.subscribeToMessageStream(t, () => {
      _sendExternalMsg('GeometryUpdateDone');
      _sendExternalMsg('GeometryUpdateVisible');
    });
    _messagingCbSubscriptions = _messagingCbSubscriptions.concat(subTokens);

    // callback for PLUGIN_ACTIVE
    t = messagingConstants.messageTopics.PLUGIN_ACTIVE;
    subTokens = _messagingHandler.subscribeToMessageStream(t, (topic, msg) => {
      let pf = msg.getUniquePartByType(messagingConstants.messageDataTypes.PLUGIN_RUNTIME_ID);
      if (pf) {
        let n = _pluginHandler.getPluginByRuntimeId(pf.data).getShortName() + 'Loaded';
        _sendExternalMsg(n);
      }
    });
    _messagingCbSubscriptions = _messagingCbSubscriptions.concat(subTokens);

    // callback for SCENE_VISIBILITY_ON
    t = messagingConstants.messageTopics.SCENE_VISIBILITY_ON;
    let subTokensVisibility = _messagingHandler.subscribeToMessageStream(t, () => {
      _messagingHandler.unsubscribeFromMessageStream(subTokensVisibility);
      _sendExternalMsg('InitialSceneReady');
    });
    _messagingCbSubscriptions = _messagingCbSubscriptions.concat(subTokensVisibility);

  };

  ////////////
  ////////////
  //
  // API methods
  //
  ////////////
  ////////////

  /** @inheritdoc */
  this.activateTopView = function(v) {
    let res = _threeDManager.updateSetting('camera.type',
      v ? threeDManagerConstants.cameraViewTypes.TOP : threeDManagerConstants.cameraViewTypes.PERSPECTIVE
    );
    return _callCommandResultCallback('activateTopView', res);
  };

  /** @inheritdoc */
  this.addDraggableShape = function(id_, type_, diameter_, color_, loc_) {
    //let scope = 'ApiImplementationV1.addDraggableShape';
    let res, f;
    f = 'addDraggableShape';

    // parameter sanity check
    if (!GlobalUtils.typeCheck(id_, 'string')) return _callCommandResultCallback(f, false);
    if (!GlobalUtils.typeCheck(type_, 'string')) return _callCommandResultCallback(f, false);
    if (!GlobalUtils.typeCheck(diameter_, 'notnegative')) return _callCommandResultCallback(f, false);
    if (!GlobalUtils.typeCheck(color_, 'color')) return _callCommandResultCallback(f, false);
    if (!GlobalUtils.typeCheck(loc_, 'vector3obj')) return _callCommandResultCallback(f, false);

    // check if id exists already
    res = _apiv2.scene.get({id: id_}, _apiv2_runtime_id);
    if (res.err || res.data.length > 0) return _callCommandResultCallback(f, false);

    // check if type is supported
    let geom;
    if (type_ === 'box') {
      geom = new THREE.BoxBufferGeometry( diameter_, diameter_, diameter_ );
    }
    else if (type_ === 'sphere') {
      geom = new THREE.SphereBufferGeometry( 0.5 * diameter_, 20, 20 );
    }
    else if (type_ === 'dodecahedron') {
      geom = new THREE.DodecahedronBufferGeometry( 0.5 * diameter_ );
    }
    if (geom === undefined) return _callCommandResultCallback(f, false);

    // create / update interaction group for draggable shapes
    if (!_createInteractionGroup()) return _callCommandResultCallback(f, false);

    // define asset to be added, make them part of interaction group
    let assets = [
      {
        id: id_,
        name: id_,
        material: id_ + '_m',
        interactionGroup: _interactionGroupName,
        content: [
          {
            format: 'three',
            data: {
              threeObject: new THREE.Mesh(geom)
            },
            transformations : new THREE.Matrix4().makeTranslation(loc_.x, loc_.y, loc_.z),
          }
        ]
      },
      {
        id: id_ + '_m',
        content: [
          {
            format: 'material',
            data: {
              version: '3.0',
              color: color_,
            }
          }
        ]
      }
    ];

    // add asset
    _apiv2.scene.updateAsync(assets, _apiv2_runtime_id);

    // store initial position of draggable shape
    _draggableShapePositions[id_] = GlobalUtils.toVector3(loc_);

    // manage event listeners
    _addOrClearDraggableShapeEventListeners();

    // because this function had been defined using a boolean return value, we can't return a Promise
    // which would allow to check success
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.cameraPath = function(positions, targets, duration) {
    let f = 'cameraPath';
    if ( positions.length !== targets.length )
      return _callCommandResultCallback(f, false);
    if ( positions.length <= 0 )
      return _callCommandResultCallback(f, false);
    var options = {};
    if ( duration ) options.duration = duration;
    _threeDManager.cameraHandler.setCameraPath(positions, targets, options);
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.changeEnvMap = function(name) {
    _threeDManager.updateSettingAsync('material.environmentMap', name);
    return true;
  };

  /** @inheritdoc */
  this.clearMessagingCallback = function() {
    _clearMessagingCbSubscriptions();
    _messagingCb = undefined;
  };

  /** @inheritdoc */
  this.clearProcessCallback = function(token) {
    // allow one subscription per token only (for now, easier to implement)
    if ( !_processSubscriptions.hasOwnProperty(token) ) {
      return false;
    }
    _messagingHandler.unsubscribeFromMessageStream(_processSubscriptions[token].subToken);
    delete _processSubscriptions[token];
    return true;
  };

  /** @inheritdoc */
  this.deregisterPlugin = function(runtimeId) {
    return _pluginHandler.deregisterPlugin(runtimeId);
  };

  /** @inheritdoc */
  this.disablePan = function(bDisable) {
    let f = 'disablePan';
    if ( _threeDManager.getSetting('camera.enablePan') === !bDisable )
      return _callCommandResultCallback(f, false);
    let res = _threeDManager.updateSetting('camera.enablePan', !bDisable);
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.disableZoom = function(bDisable) {
    let f = 'disableZoom';
    if ( _threeDManager.getSetting('camera.enableZoom') === !bDisable )
      return _callCommandResultCallback(f, false);
    let res = _threeDManager.updateSetting('camera.enableZoom', !bDisable);
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.fadeInViewer = function() {
    _viewportVisibilityHandler.showScene(true);
    return true;
  };

  /** @inheritdoc */
  this.findTexturedGeometry = function() {
    let geomNames = that.getGeometryNames();
    let names = [];
    let p, n;
    for (p in geomNames) {
      for (n in geomNames[p]) {
        names.push(n);
      }
    }
    return _callCommandResultCallback('findTexturedGeometry', names);
  };

  /** @inheritdoc */
  this.getApiVersion = function() {
    return '1.0.0';
  };

  /** @inheritdoc */
  this.getBackgroundColor = function() {
    let color = _threeDManager.getSetting('render.clearColor');
    let alpha = _threeDManager.getSetting('render.clearAlpha');
    let tc = toTinyColor(color);
    tc.setAlpha(alpha);
    return tc.toString('hex8');
  };

  /** @inheritdoc */
  this.getCamera = function() {
    let res = _threeDManager.cameraHandler.getPositionAndTarget();
    return _callCommandResultCallback('getCamera', res);
  };

  /** @inheritdoc */
  this.getDraggableShapePosition = function(id_) {
    let f = 'getDraggableShapePosition';
    if ( !_draggableShapePositions.hasOwnProperty(id_) ) return _callCommandResultCallback(f, false);
    let res = _draggableShapePositions[id_];
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.getExportDefinitions = function() {
    let res = _exportHandler.getExportDefinitions();
    _callCommandResultCallback('getExportDefinitions', res);
  };

  /** @inheritdoc */
  this.getGeometryNames = function(creatorId) {
    return _sceneManager.getGeometryNames(creatorId);
  };

  /** @inheritdoc */
  this.getGeometryPathsByName = function(name, creatorId) {
    return _sceneManager.getGeometryPathsByName(name, creatorId);
  };

  /** @inheritdoc */
  this.getModelData = function(properties) {
    let dataItemsArray = _sceneManager.getModelData(properties);
    let dataItemsObj = {};
    dataItemsArray.forEach( (item) => {
      dataItemsObj[item.id] = item;
    });
    return _callCommandResultCallback('getModelData', dataItemsObj);
  };

  /** @inheritdoc */
  this.getParameterDefinitions = function(s) {
    let res = _parameterHandler.getParameterDefinitions(s);
    return _callCommandResultCallback('getParameterDefinitions', res);
  };

  /** @inheritdoc */
  this.getParameterValues = function() {
    let res = _parameterHandler.getParameterValuesCompat();
    return _callCommandResultCallback('getParameterValues', res);
  };

  /** @inheritdoc */
  this.getScreenshot = function() {
    let dataUrl = _threeDManager.renderingHandler.getScreenshot();
    return _callCommandResultCallback('getScreenshot', dataUrl);
  };

  /** @inheritdoc */
  this.getStatus = function() {
    let res, f;
    f = 'getStatus';
    // check whether at least one plugin failed
    let stat = _pluginHandler.getStatusDescription();
    let pluginsFailed = !stat.every((s) => (s.status !== pluginConstants.pluginStatuses.FAILED));
    if (pluginsFailed) {
      res = {statusCode: 3, statusText: 'Error', statusInfo: 'At least one plugin failed'};
      return _callCommandResultCallback(f, res);
    }
    // check whether all plugins are active
    let pluginsActive = stat.every((s) => (s.status === pluginConstants.pluginStatuses.ACTIVE));
    if (!pluginsActive) {
      res = {statusCode: 2, statusText: 'Initializing', statusInfo: 'The viewer is still initializing'};
      return _callCommandResultCallback(f, res);
    }
    // check whether viewer is busy
    let busy = _processStatusHandler.getSummary().busy;
    if (busy) {
      res = {statusCode: 1, statusText: 'Busy', statusInfo: 'Viewer is processing a parameter update'};
      return _callCommandResultCallback(f, res);
    }
    res = {statusCode: 0, statusText: 'Ready', statusInfo: 'Viewer is fully loaded, geometry is being displayed'};
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.hideControls = function() {
    let scope = 'ApiImplementationV1.hideControls';
    that.error(scope, 'Requires UI');
    return _callCommandResultCallback('hideControls', false);
  };

  /** @inheritdoc */
  this.hideFullscreenToggle = function() {
    let scope = 'ApiImplementationV1.hideFullscreenToggle';
    that.error(scope, 'Requires UI');
    return false;
  };

  /** @inheritdoc */
  this.hideZoomToggle = function() {
    let scope = 'ApiImplementationV1.hideZoomToggle';
    that.error(scope, 'Requires UI');
    return false;
  };

  /** @inheritdoc */
  this.loadStaticMesh = function(baseUrl_, objFile_, mtlFile_, id_, texturePath_, sidedNess_) {
    //let scope = 'ApiImplementationV1.loadStaticMesh';

    // sanity check
    if (arguments.length < 4 || !GlobalUtils.typeCheck(id_, 'string') || id_.length === 0)
      id_ = GlobalUtils.createRandomId();
    if (!GlobalUtils.typeCheck(objFile_, 'string') || objFile_.length === 0) return false;

    // check if id exists already
    let res = _apiv2.scene.get({id: id_}, _apiv2_runtime_id);
    if (res.err || res.data.length > 0) return false;

    // define asset to be added
    let assets = [
      {
        id: id_,
        material: 'material_1',
        version: '1',
        duration: 0,
        content: [
          {
            format: 'obj',
            data: {
              objUrl: objFile_
            }
          }
        ]
      }
    ];
    if (GlobalUtils.typeCheck(mtlFile_,'string')) assets[0].content.data.mtlUrl = mtlFile_;
    if (GlobalUtils.typeCheck(baseUrl_,'string')) assets[0].content.data.path = baseUrl_;
    if (GlobalUtils.typeCheck(texturePath_,'string')) assets[0].content.data.texturePath = texturePath_;
    if (GlobalUtils.typeCheck(sidedNess_,'string')) assets[0].content.data.side = sidedNess_;

    // add asset
    _apiv2.scene.updateAsync(assets, _apiv2_runtime_id)
      .then(
        function() {
          _sendExternalMsg('LoadStaticMeshDone');
        }
      )
    ;

    return true;
  };

  /** @inheritdoc */
  this.refreshPlugin = function(runtimeId, token) {
    // create token if none was given
    token = messagingConstants.makeMessageToken(token);
    // call parameterHandler
    var r = _parameterHandler.refreshPlugin(runtimeId, token);
    // check if an error occured
    if ( r.err ) return false;
    return true;
  };

  /** @inheritdoc */
  this.registerPlugin = function(plugin) {
    return _pluginHandler.registerPlugin(plugin);
  };

  /** @inheritdoc */
  this.removeExternalTexture = function(name, mapNames) {
    let res, f;
    f = 'removeExternalTexture';

    // parameter sanity check
    if (!GlobalUtils.typeCheck(name, 'string')) return _callCommandResultCallback(f, false);

    let mapNamesDefault = ['color','alpha','roughness','metalness','normal','bump'];
    if (typeof mapNames === 'string') {
      try {
        let tmp = JSON.parse(mapNames);
        mapNames = tmp;
        if (!Array.isArray(mapNames)) mapNames = [mapNames];
      }
      catch(err) {
        mapNames = mapNamesDefault;
      }
    }
    else if (!Array.isArray(mapNames)) {
      mapNames = mapNamesDefault;
    }

    // rename properties
    if (mapNames.includes('color')) {
      mapNames.splice(mapNames.findIndex((s)=>(s === 'color')), 1);
      mapNames.push('bitmap');
    }
    if (mapNames.includes('alpha')) {
      mapNames.splice(mapNames.findIndex((s)=>(s === 'alpha')), 1);
      mapNames.push('transparency');
    }

    // get geometry names, look for first matching name and plugin
    let geomNames = that.getGeometryNames();
    let p, plugin;
    for (p in geomNames) {
      if (geomNames[p].hasOwnProperty(name)) {
        plugin = p;
        break;
      }
    }
    if (!plugin) return _callCommandResultCallback(f, false);

    // get all assets named "name" defined by plugin
    res = _apiv2.scene.get({name: name}, plugin);
    if (res.err || res.data.length <= 1) return _callCommandResultCallback(f, false);

    // filter the asset which contains a material
    res = res.data.filter((a) => (a.material));
    if (res.length !== 1) return _callCommandResultCallback(f, false);
    let asset = res[0];

    // get persistent material asset, copy it, adapt it
    res = _apiv2.scene.getPersistent({id: asset.material}, plugin);
    if (res.err || res.data.length !== 1) return _callCommandResultCallback(f, false);
    let material = GlobalUtils.deepCopy(res.data[0]);
    if (!Array.isArray(material.content) || material.content.length <= 0) return _callCommandResultCallback(f, false);
    if (material.content[0].format !== 'material') return _callCommandResultCallback(f, false);
    let material_content = material.content[0].data;

    // set new material persistently for geometry name
    mapNames.forEach((t) => {
      let tn = t + 'texture';
      delete material_content[tn];
    });

    // define assets for persistent update
    _apiv2.scene.updatePersistentAsync([material], plugin);

    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.removeDraggableShape = function(id_) {
    let f = 'removeDraggableShape';
    if (!_draggableShapePositions.hasOwnProperty(id_)) return _callCommandResultCallback(f, false);
    _apiv2.scene.removeAsync({id: id_}, _apiv2_runtime_id);
    delete _draggableShapePositions[id_];
    _addOrClearDraggableShapeEventListeners();
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.removeStaticMesh = function(id_) {
    if (!GlobalUtils.typeCheck(id_, 'string') || !id_.length > 0) return false;

    // check if id exists already
    let res = _apiv2.scene.get({id: id_}, _apiv2_runtime_id);
    if (res.err || res.data.length !== 1) return false;

    // remove
    _apiv2.scene.removeAsync({id: id_}, _apiv2_runtime_id);
    return true;
  };

  /** check for a {@link module:ApiInterfaceV1~ExportRequestObject} */
  var _isExportRequestObject = function(o) {
    if ( !o || typeof o !== 'object' )
      return false;
    // at least one of id, idOrName, or name must exist
    if ( !GlobalUtils.typeCheck(o.id, 'string') && !GlobalUtils.typeCheck(o.idOrName, 'string') && !GlobalUtils.typeCheck(o.name, 'string') )
      return false;
    // if plugin exists, it must be a string
    if ( o.plugin && typeof o.plugin !== 'string' )
      return false;
    // got it
    return true;
  };

  /** @inheritdoc */
  this.requestExport = function(id, beSilent, plugin, token) {
    let f = 'requestExport';
    // check if id is a ExportRequestObject
    var ero;
    if ( typeof id === 'string' ) {
      ero = {idOrName: id};
      if ( typeof plugin === 'string' )
        ero.plugin = plugin;
    } else if ( _isExportRequestObject(id) ) {
      ero = id;
    } else {
      return _callCommandResultCallback(f, false);
    }
    // pass on token or create one
    token = messagingConstants.makeMessageToken(token);
    // call exportHandler
    var r = _exportHandler.requestExport(ero, token);
    // check if an error occured
    if ( r !== pluginConstants.requestExportResults.CACHE && r !== pluginConstants.requestExportResults.LOAD ) {
      return _callCommandResultCallback(f, false);
    }
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.requestSceneUpdate = function() {
    // for all registered plugins call refreshPlugin
    let statii = _pluginHandler.getStatusDescription();
    let res = true;
    for (let stat of statii) {
      // status must be active
      if (stat.status !== pluginConstants.pluginStatuses.ACTIVE)
        continue;
      // call parameterHandler
      let r = _parameterHandler.refreshPlugin(stat.id);
      // check if an error occured
      if ( r.err ) res = false;
    }
    return res;
  };

  /** @inheritdoc */
  this.resetCamera = function(tween, duration) {
    let options = {duration: 0};
    if (tween === undefined) tween = true;
    if (tween) {
      if (duration) options.duration = duration;
      else options.duration = 800;
    }
    _threeDManager.cameraHandler.resetPositionAndTarget(options);
    return true;
  };

  /** @inheritdoc */
  this.restrictCamera = function(component, blnMax, value) {
    let f = 'restrictCamera';
    // get component
    let c;
    if ( typeof component === 'number') {
      if ( component === 0 ) c = 'x';
      else if ( component === 1 ) c = 'y';
      else if ( component === 2 ) c = 'z';
      else
        return _callCommandResultCallback(f, false);
    }
    else if ( typeof component === 'string') {
      if ( component !== 'x' && component !== 'y' && component !== 'z' )
        return _callCommandResultCallback(f, false);
      c = component;
    }
    else
      return _callCommandResultCallback(f, false);
    // min or max
    let s = 'min';
    if (blnMax) s = 'max';
    // check value
    if (typeof value !== 'number')
      return _callCommandResultCallback(f, false);
    // change current value
    let v = _threeDManager.getSetting('camera.restrictions.position.cube');
    v[s][c] = value;
    let res = _threeDManager.updateSetting('camera.restrictions.position.cube', v);
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.restrictPan = function(component, blnMax, value) {
    let f = 'restrictPan';
    // get component
    let c;
    if ( typeof component === 'number') {
      if ( component === 0 ) c = 'x';
      else if ( component === 1 ) c = 'y';
      else if ( component === 2 ) c = 'z';
      else
        return _callCommandResultCallback(f, false);
    }
    else if ( typeof component === 'string') {
      if ( component !== 'x' && component !== 'y' && component !== 'z' ) return false;
      c = component;
    }
    else
      return _callCommandResultCallback(f, false);
    // min or max
    let s = 'min';
    if (blnMax) s = 'max';
    // check value
    if (typeof value !== 'number')
      return _callCommandResultCallback(f, false);
    // change current value
    let v = _threeDManager.getSetting('camera.restrictions.target.cube');
    v[s][c] = value;
    let res = _threeDManager.updateSetting('camera.restrictions.target.cube', v);
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.restrictZoom = function(min, max) {
    let res = _threeDManager.updateSetting('camera.restrictions.zoom', {minDistance: min, maxDistance: max});
    return _callCommandResultCallback('restrictZoom', res);
  };

  /** @inheritdoc */
  this.setAutoRotate = function(speed) {
    let f = 'setAutoRotate';
    if ( typeof speed === 'number' ) {
      let speedUpdated = that.setAutoRotationSpeed(speed);
      let toggleUpdated = _threeDManager.updateSetting('camera.enableAutoRotation', speed !== 0);
      let res = speedUpdated || toggleUpdated;
      return _callCommandResultCallback(f, res);
    }
    else if (typeof speed === 'boolean') {
      let res = _threeDManager.updateSetting('camera.enableAutoRotation', speed);
      return _callCommandResultCallback(f, res);
    }
    else {
      return _callCommandResultCallback(f, false);
    }
  };

  /** @inheritdoc */
  this.setAutoRotation = function(speed) {
    return that.setAutoRotate(speed);
  };

  /** @inheritdoc */
  this.setAutoRotationSpeed = function(speed) {
    if ( typeof speed !== 'number' ) return false;
    return _threeDManager.updateSetting('camera.autoRotationSpeed', speed);
  };

  /** @inheritdoc */
  this.setBackgroundColor = function(color) {
    let f = 'setBackgroundColor';
    var c = toTinyColor(color);
    if (!c.isValid())
      return _callCommandResultCallback(f, false);
    if (!_threeDManager.updateSetting('render.clearColor',c.toString('hex6')))
      return _callCommandResultCallback(f, false);
    if (!_threeDManager.updateSetting('render.clearAlpha',c.getAlpha()))
      return _callCommandResultCallback(f, false);
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.setBumpAmplitude = function(bumpAmplitude) {
    let f = 'setBumpAmplitude';
    _apiv2.updateSettingAsync('defaultMaterial.bumpAmplitude',bumpAmplitude);
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.setBusyGraphic = function() {
    let scope = 'ApiImplementationV1.setBusyGraphic';
    that.error(scope, 'Requires UI');
  };

  /** @inheritdoc */
  this.setBusyGraphicPosition = function() {
    let scope = 'ApiImplementationV1.setBusyGraphicPosition';
    that.error(scope, 'Requires UI');
  };

  /** @inheritdoc */
  this.setButtonColor = function() {
    let scope = 'ApiImplementationV1.setButtonColor';
    that.error(scope, 'Not implemented yet');
    return _callCommandResultCallback('setButtonColor', false);
  };

  /** @inheritdoc */
  this.setCamera = function(position, target, tween, duration) {
    let options = {duration: 0};
    if (tween === undefined) tween = true;
    if (tween) {
      if (duration) options.duration = duration;
      else options.duration = 800;
      // if this should be stored in the settings
      //_threeDManager.updateSetting('camera.cameraMovementDuration', options.duration);
    }
    // if this should be stored in the settings
    //return _threeDManager.updateSettingAsync('camera.defaults', {position: position, target: target});
    // if this should not be stored in the settings
    let res = _threeDManager.cameraHandler.setPositionAndTarget(position, target, options);
    return _callCommandResultCallback('setCamera', res);
  };

  /** @inheritdoc */
  this.setCommandResultCallback = function(cb_) {
    _commandResultCallback = cb_;
  };

  /** @inheritdoc */
  this.setDefaultMaterialColor = function(color) {
    let f = 'setDefaultMaterialColor';
    _apiv2.updateSettingAsync('defaultMaterial.color',color);
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.setExternalTexture = function(name, mapContainer) {
    let res, f;
    f = 'setExternalTexture';

    // parameter sanity check
    if (!GlobalUtils.typeCheck(name, 'string')) return _callCommandResultCallback(f, false);
    if (typeof mapContainer === 'string') {
      try {
        let mapContainerObject = JSON.parse(mapContainer);
        mapContainer = mapContainerObject;
      }
      catch(err) {
        mapContainer = {color: mapContainer};
      }
    }
    else if (mapContainer && typeof mapContainer === 'object') {
      mapContainer = GlobalUtils.deepCopy(mapContainer);
    } else
      return _callCommandResultCallback(f, false);

    // rename properties
    if (mapContainer.color) {
      mapContainer.bitmap = mapContainer.color;
      delete mapContainer.color;
    }
    if (mapContainer.alpha) {
      mapContainer.transparency = mapContainer.alpha;
      delete mapContainer.alpha;
    }

    // get geometry names, look for first matching name and plugin
    let geomNames = that.getGeometryNames();
    let p, plugin;
    for (p in geomNames) {
      if (geomNames[p].hasOwnProperty(name)) {
        plugin = p;
        break;
      }
    }
    if (!plugin) return _callCommandResultCallback(f, false);

    // get all assets named "name" defined by plugin
    res = _apiv2.scene.get({name: name}, plugin);
    if (res.err || res.data.length <= 1) return _callCommandResultCallback(f, false);

    // filter the asset which contains a material
    res = res.data.filter((a) => (a.material));
    if (res.length !== 1) return _callCommandResultCallback(f, false);
    let asset = res[0];

    // get original material asset, copy it, adapt it
    res = _apiv2.scene.get({id: asset.material}, plugin);
    if (res.err || res.data.length !== 1) return _callCommandResultCallback(f, false);
    if (!Array.isArray(res.data[0].content) || res.data[0].content.length <= 0) return _callCommandResultCallback(f, false);
    let material = {};
    material.content = GlobalUtils.deepCopy(res.data[0].content);
    if (material.content[0].format !== 'material') return _callCommandResultCallback(f, false);
    let material_content = material.content[0].data;

    // set new material persistently for geometry name
    ['bitmap','metalness','roughness','bump','normal','transparency'].forEach((t) => {
      if (mapContainer[t] && GlobalUtils.typeCheck(mapContainer[t],'string')) {
        let tn = t + 'texture';
        if ( material_content[tn] && typeof material_content[tn] === 'object' ) {
          material_content[tn].href = mapContainer[t];
        } else {
          material_content[tn] = mapContainer[t];
        }
      }
    });

    // define assets for persistent update
    material.id = asset.material;
    _apiv2.scene.updatePersistentAsync([material], plugin);

    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.setFOV = function(angle) {
    let res = _threeDManager.updateSetting('camera.fov', angle);
    return _callCommandResultCallback('setFOV', res);
  };

  /** @inheritdoc */
  this.setPointSize = function(size) {
    if (typeof size !== 'number')
      size = 1;
    let res = _threeDManager.updateSetting('render.pointSize', size);
    return _callCommandResultCallback('setPointSize', res);
  };

  /** @inheritdoc */
  this.setToggleAmbientOcclusion = function(toggle) {
    return _threeDManager.updateSetting('render.ambientOcclusion', toggle);
  };

  /** @inheritdoc */
  this.setToggleCastShadow = function(path, bCast) {
    _threeDManager.setToggleCastShadow(path, bCast);
  };

  /** @inheritdoc */
  this.setControlDamping = function(damping) {
    let res = _threeDManager.updateSetting('camera.damping', damping);
    return _callCommandResultCallback('setControlDamping', res);
  };

  /** @inheritdoc */
  this.setDamping = function(damping) {
    return that.setControlDamping(damping);
  };

  /** @inheritdoc */
  this.setEdgeColor = function() {
    let scope = 'ApiImplementationV1.setEdgeColor';
    that.warn(scope, 'Not implemented'); // will not be implemented anymore
    return _callCommandResultCallback('setEdgeColor', false);
  };

  /** @inheritdoc */
  this.setEdgeColorByObject = function() {
    let scope = 'ApiImplementationV1.setEdgeColorByObject';
    that.warn(scope, 'Not implemented'); // will not be implemented anymore
    return _callCommandResultCallback('setEdgeColorByObject', false);
  };

  /** @inheritdoc */
  this.setIgnoreMouse = function(ignoreMouse) {
    _apiv2.updateSettingAsync('scene.ignorePointerEvents', ignoreMouse);
    _callCommandResultCallback('setIgnoreMouse', true);
    return true;
  };

  /** @inheritdoc */
  this.setMessagingCallback = function(cb) {
    if (typeof cb === 'function') {
      _createMessagingCbSubscriptions();
      _messagingCb = cb;
    }
  };

  /** check for a {@link module:ApiInterfaceV1~ParameterUpdateObject} */
  var _isParameterUpdateObject = function(o) {
    if ( !o || typeof o !== 'object' )
      return false;
    // at least one of id, idOrName, or name must exist
    if ( typeof o.id !== 'string' && typeof o.idOrName !== 'string' && typeof o.name !== 'string' )
      return false;
    // if plugin exists, it must be a string
    if ( o.plugin && typeof o.plugin !== 'string' )
      return false;
    // value must exist
    if ( o.value === undefined )
      return false;
    // got it
    return true;
  };

  /** @inheritdoc */
  this.setParameterValue = function(id, value) {
    let f = 'setParameterValue';
    let scope = 'ApiImplementationV1.' + f;
    // check if id is a ParameterUpdateObject
    var puo, token;
    if ( typeof id === 'string' ) {
      puo = {idOrName: id, value: value};
    } else if ( _isParameterUpdateObject(id) ) {
      puo = id;
      // in this case, value is used as token
      token = value;
    } else {
      return _callCommandResultCallback(f, false);
    }
    // create token if none was given
    token = messagingConstants.makeMessageToken(token);
    // call parameterHandler
    var r = _parameterHandler.setParameterValue(puo, token);
    // check if an error occured
    if ( r.err ) {
      that.error(scope, '_parameterHandler.setParameterValue returned error', r);
      return _callCommandResultCallback(f, false);
    } else if ( r.warn ) {
      that.warn(scope, '_parameterHandler.setParameterValue returned warning', r);
      return _callCommandResultCallback(f, false);
    } else {
      that.debug(scope, '_parameterHandler.setParameterValue returned', r);
    }
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.setParameterValues = function(ids, values) {
    let f = 'setParameterValues';
    var scope = 'ApiImplementationV1.' + f;
    // input sanity check, and create array of ParameterUpdateObject
    var puoArr = [], token;
    // ids must be an array
    if ( !Array.isArray(ids) )
      return _callCommandResultCallback(f, false);
    // check how ids are given
    if ( ids.every( function(v) { return typeof v === 'string'; } ) ) {
      // values must be an array of same length
      if ( !Array.isArray(values) )
        return false;
      if ( values.length !== ids.length )
        return false;
      for ( let i=0; i<ids.length; i++ ) {
        puoArr.push( {idOrName: ids[i], value: values[i]} );
      }
    }
    else if ( ids.every( function(v) { return _isParameterUpdateObject(v); } ) ) {
      puoArr = ids;
      // in this case, Values is used as token
      token = values;
    }
    // create token if none was given
    token = messagingConstants.makeMessageToken(token);
    // call parameterHandler
    var r = _parameterHandler.setMultipleParameterValues(puoArr, token);
    // check if an error occured
    if ( r.err ) {
      that.error(scope, '_parameterHandler.setParameterValue returned error', r);
      return _callCommandResultCallback(f, false);
    } else if ( r.warn ) {
      that.warn(scope, '_parameterHandler.setParameterValue returned warning', r);
      return _callCommandResultCallback(f, false);
    } else {
      that.debug(scope, '_parameterHandler.setParameterValue returned', r);
    }
    return _callCommandResultCallback(f, true);
  };

  /** @inheritdoc */
  this.setProcessCallback = function(token, cb) {
    var scope = 'ApiImplementationV1.setProcessCallback';

    // allow one subscription per token only (for now, easier to implement)
    if ( _processSubscriptions.hasOwnProperty(token) ) {
      return;
    }

    // compose topic for subscription, subscribe
    let t = messagingConstants.messageTopics.PROCESS + '.' + token;
    let subToken = _messagingHandler.subscribeToMessageStream(t, (topic, msg) => {

      // get process token from topic (string after first dot)
      let topicToken = topic.substring(topic.indexOf('.') + 1);

      // get handle to process subscription object
      if ( !_processSubscriptions.hasOwnProperty(token) ) {
        that.warn(scope, 'Message for unknown token:', msg);
        return;
      }
      let s = _processSubscriptions[token];

      // handle msgs which have a part messagingConstants.messageDataTypes.PROCESS_FORK
      // in that case replace array of process tokens
      let pf = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_FORK);
      if ( pf && Array.isArray(pf.data) ) {
        // check new process tokens which shall replace topicToken,
        // every one of them must be a sub-token of the original one (i.e. define a sub-topic, otherwise we won't receive its messages)
        let r = pf.data.every( (n) => {
          return n.startsWith(token + '.');
        });
        if (!r) {
          that.error(scope, 'Forked process token must be a sub-token of ' + token);
        }
        else {
          // remove old process token
          let newProcTokens = s.procTokens;
          newProcTokens = newProcTokens.splice(newProcTokens.indexOf(topicToken), 1);
          // add new process tokens and remember them
          newProcTokens = newProcTokens.concat(pf.data);
          s.procTokens = newProcTokens;
        }
        try {
          cb(topic, msg);
        } catch (e) {
          that.error(scope, 'Exception in process callback:', e);
        }
        return;
      }

      // handle msgs which have a part messagingConstants.messageDataTypes.PROCESS_ERROR or PROCESS_SUCCESS
      // in that case remember status for the corresponding process token
      let pe = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_ERROR);
      let pa = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_ABORT);
      let ps = msg.getUniquePartByType(messagingConstants.messageDataTypes.PROCESS_SUCCESS);
      if ( pe ) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_ERROR;
      }
      else if ( pa ) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_ABORT;
      }
      else if ( ps ) {
        s.procTokenStat[topicToken] = messagingConstants.messageDataTypes.PROCESS_SUCCESS;
      }

      // once we have collected a status for all process tokens, cancel the subscription
      let r = s.procTokens.every( (t) => {
        return s.procTokenStat.hasOwnProperty(t);
      });
      if (r) {
        that.debug(scope, 'Clearing process callback for token ' + token);
        that.clearProcessCallback(token);
      }

      try {
        cb(topic, msg);
      } catch (e) {
        that.error(scope, 'Exception in process callback:', e);
      }
    });
    // store subscription token and process token
    _processSubscriptions[token] = {
      subToken: subToken, // subscription token
      procTokens: [token], // list of process tokens (process might be forked)
      procTokenStat: {} // status for each process token
    };
    return token;
  };

  /** @inheritdoc */
  this.setRotateSpeed = function(speed) {
    let res = _threeDManager.updateSetting('camera.rotationSpeed', speed);
    return _callCommandResultCallback('setRotateSpeed', res);
  };

  /** @inheritdoc */
  this.setRotationSpeed = function(speed) {
    return that.setRotateSpeed(speed);
  };

  /** @inheritdoc */
  this.setZoomExtentFactor = function(factor) {
    let res = _threeDManager.updateSetting('camera.zoomExtentsFactor', factor);
    return _callCommandResultCallback('setZoomExtentFactor', res);
  };

  /** @inheritdoc */
  this.setZoomSpeed = function(speed) {
    let res = _threeDManager.updateSetting('camera.zoomSpeed', speed);
    return _callCommandResultCallback('setZoomSpeed', res);
  };

  /** @inheritdoc */
  this.showControls = function() {
    let scope = 'ApiImplementationV1.showControls';
    that.error(scope, 'Requires UI');
    return _callCommandResultCallback('showControls', false);
  };

  /** @inheritdoc */
  this.showEdges = function() {
    let scope = 'ApiImplementationV1.showEdges';
    that.warn(scope, 'Not implemented'); // will not be implemented anymore
    return _callCommandResultCallback('showEdges', false);
  };

  /** @inheritdoc */
  this.showFullscreenToggle = function() {
    let scope = 'ApiImplementationV1.showFullscreenToggle';
    that.error(scope, 'Requires UI');
    return false;
  };

  /** @inheritdoc */
  this.showGrid = function(show) {
    let f = 'showGrid';
    if (typeof show !== 'boolean') show = true;
    let b = _threeDManager.getSettingShallow('gridVisibility');
    if ( b == show )
      return _callCommandResultCallback(f, false);
    let res = _threeDManager.updateSetting('gridVisibility', show);
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.showGroundPlane = function(show) {
    let f = 'showGroundPlane';
    if (typeof show !== 'boolean') show = true;
    let b = _threeDManager.getSettingShallow('groundPlaneVisibility');
    if ( b == show )
      return _callCommandResultCallback(f, false);
    let res = _threeDManager.updateSetting('groundPlaneVisibility', show);
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.showShadows = function(show) {
    let f = 'showShadows';
    if (typeof show !== 'boolean') show = true;
    let b = _threeDManager.getSettingShallow('render.shadows');
    if ( b == show )
      return _callCommandResultCallback(f, false);
    let res = _threeDManager.updateSetting('render.shadows', show);
    return _callCommandResultCallback(f, res);
  };

  /** @inheritdoc */
  this.showStaticMesh = function(id_, show_) {
    if (!GlobalUtils.typeCheck(id_, 'string') || id_.length === 0) return false;
    if (!GlobalUtils.typeCheck(show_, 'boolean')) return false;

    // check if id exists already
    let res = _apiv2.scene.get({id: id_}, _apiv2_runtime_id);
    if (res.err || res.data.length !== 1) return false;

    // show/hide
    _apiv2.scene.updateAsync({id: id_, visible: show_}, _apiv2_runtime_id);

    return true;
  };

  /** @inheritdoc */
  this.showZoomToggle = function() {
    let scope = 'ApiImplementationV1.showZoomToggle';
    that.error(scope, 'Requires UI');
    return false;
  };

  /** @inheritdoc */
  this.toggleDisablePan = function() {
    let b = _threeDManager.getSettingShallow('camera.enablePan');
    return _threeDManager.updateSetting('camera.enablePan', !b);
  };

  /** @inheritdoc */
  this.toggleDisableZoom = function() {
    let b = _threeDManager.getSettingShallow('camera.enableZoom');
    let res = _threeDManager.updateSetting('camera.enableZoom', !b);
    return _callCommandResultCallback(res);
  };

  /** @inheritdoc */
  this.toggleFullscreen = function(toggle) {
    let b = _threeDManager.getSettingShallow('fullscreen');
    if ( typeof toggle !== 'boolean' ) {
      toggle = !b;
    } else if (toggle === b) {
      return false;
    }
    return _threeDManager.updateSetting('fullscreen', toggle);
  };

  /** @inheritdoc */
  this.toggleSceneBackground = function(show) {
    if (typeof show !== 'boolean') show = true;
    return _threeDManager.updateSetting('material.environmentMapAsBackground', show);
  };

  /** @inheritdoc */
  this.toggleShadows = function() {
    let b = _threeDManager.getSettingShallow('render.shadows');
    return _threeDManager.updateSetting('render.shadows', !b);
  };

  /** @inheritdoc */
  this.toggleTopView = function() {
    let b = _threeDManager.getSettingShallow('camera.type');
    let c;
    if ( b === threeDManagerConstants.cameraViewTypes.PERSPECTIVE )
      c = threeDManagerConstants.cameraViewTypes.TOP;
    else {
      c = threeDManagerConstants.cameraViewTypes.PERSPECTIVE;
    }
    return _threeDManager.updateSetting('camera.type', c);
  };

  /** @inheritdoc */
  this.zoomExt = function(tween) {
    return that.zoomExtents(tween);
  };

  /** @inheritdoc */
  this.zoomExtents = function(tween, duration) {
    let options = {duration: 0};
    if (tween === undefined) tween = true;
    if (tween) {
      if (duration) options.duration = duration;
      else options.duration = 800;
    }
    _threeDManager.cameraHandler.zoomExtents(options);
    return true;
  };


  return this;
};

// export the constructor
module.exports = ApiImplementationV1;
