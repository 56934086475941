"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Setting = /** @class */ (function () {
    function Setting(api, setting, type, name, desc, groupId, orderId, properties, custom) {
        if (setting === void 0) { setting = null; }
        if (groupId === void 0) { groupId = 1; }
        if (orderId === void 0) { orderId = 1; }
        if (properties === void 0) { properties = {}; }
        if (custom === void 0) { custom = false; }
        this.api = api;
        this.setting = setting;
        this.type = type;
        this.name = name;
        this.desc = desc;
        this.groupId = groupId;
        this.orderId = orderId;
        this.properties = properties;
        this.custom = custom;
        this.id = 0;
        this.menuElements = [];
        this.isSaved = false;
        this.old = true;
        Setting.resolveId(this);
        if (!this.type)
            throw new Error("Missing type");
        if (!this.name)
            throw new Error("Missing name");
        if (!this.custom) {
            this.defval = api.getSetting(setting);
        }
        if (this.properties) {
            Object.assign(this, this.properties);
        }
    }
    Setting.prototype.registerMenuElement = function (element) {
        this.menuElements.push(element);
    };
    Setting.prototype.getMenuElements = function () {
        return this.menuElements;
    };
    Setting.resolveId = function (ctx) {
        ctx.id = Setting.id++;
    };
    Setting.id = 0;
    return Setting;
}());
exports.Setting = Setting;
