"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ParameterType_1 = require("../../../../modules/enums/controls/ParameterType");
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var BooleanInputCustomComponent = /** @class */ (function (_super) {
    __extends(BooleanInputCustomComponent, _super);
    // #endregion Properties (4)
    // #region Constructors (1)
    function BooleanInputCustomComponent(id, parameterDefinition, parent, domManager, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, ParameterType_1.ParameterType.CHECKBOX, id, parameterDefinition, parent, domManager, editMode) || this;
        _this._inputId = id + "-checkbox";
        _this._forName = _this.id + "-checkbox";
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(BooleanInputCustomComponent.prototype, "inputCheckboxContainer", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._inputCheckboxContainer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BooleanInputCustomComponent.prototype, "size", {
        get: function () {
            return interfaces_1.ControlElementSize.QTR_WIDTH;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (2)
    // #region Protected Methods (3)
    BooleanInputCustomComponent.prototype.createInput = function () {
        this._boolContainer = this._domManager.createDomElement("label", this.id + "-checkbox-label", this._inputContainer, ['sdv-control-panel-boolean-input-checkbox-label'], { for: this._forName });
        this._inputComponent = this._domManager.createDomElement("input", this._inputId, this._boolContainer, ["shapediver-viewer-element", "sdv-control-panel-control", "sdv-control-panel-boolean-input-checkbox"], { type: "checkbox", name: this._forName });
        this._inputCheckboxContainer = this._domManager.createDomElement("div", this.id + "-checkbox-helper", this._boolContainer, ['sdv-control-panel-boolean-input-boxhelper'], { for: this._forName });
        if (this._parameterDefinition.value) {
            this._inputComponent.value = this._parameterDefinition.value;
        }
    };
    BooleanInputCustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._inputComponent, "change", 10, this.update.bind(this));
    };
    BooleanInputCustomComponent.prototype.update = function (v, event) {
        this._parameterDefinition.update(event.srcElement.checked);
    };
    return BooleanInputCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.BooleanInputCustomComponent = BooleanInputCustomComponent;
