"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LightControls_1 = require("./src/LightControls");
var enums_1 = require("./src/enums/enums");
var ShapeDiverDragControls = /** @class */ (function () {
    // #endregion Properties (2)
    // #region Constructors (1)
    /**
     * Creates shapediver drag controls
     * @param { API } api - ShapeDiver api.
     * @param { THREE } three - THREE dependency.
     */
    function ShapeDiverDragControls(api, three) {
        var _this = this;
        this.api = api;
        this.three = three;
        this.registeredLightEvents = {};
        this.controls = new LightControls_1.LightControls(api, three);
        this.controls.events.on(enums_1.LightControlsEvents.UPDATED, function (lightDef) {
            if (_this.registeredLightEvents[lightDef.id]) {
                _this.registeredLightEvents[lightDef.id](lightDef);
            }
        });
    }
    // #endregion Constructors (1)
    // #region Public Methods (5)
    /**
     * Clears all events.
     */
    ShapeDiverDragControls.prototype.clearOnLightUpdateEvents = function (lightId) {
        delete this.registeredLightEvents[lightId];
    };
    /**
     * Makes the light draggable.
     * @param { string } id
     */
    ShapeDiverDragControls.prototype.makeLightDraggable = function (id) {
        this.controls.makeLightDraggable(id);
    };
    /**
     * Makes the light draggable.
     * @param { string } id
     */
    ShapeDiverDragControls.prototype.makeLightDraggableAndZoomAsync = function (id) {
        this.controls.makeLightDraggableAndZoomAsync(id);
    };
    /**
     * Add on light update event.
     * @param { string } lightId
     * @param { Function } handler
     */
    ShapeDiverDragControls.prototype.onLightUpdate = function (lightId, handler) {
        this.registeredLightEvents[lightId] = handler;
    };
    /**
     * Updates the light.
     * @param { ILightDef } lightDef
     */
    ShapeDiverDragControls.prototype.updateLight = function (lightDef) {
        this.controls.update(lightDef);
    };
    ShapeDiverDragControls.prototype.useLightHelpers = function (val) {
        this.controls.useLightHelpers(val);
    };
    ShapeDiverDragControls.prototype.zoomAsync = function () {
        this.controls.zoomAsync();
    };
    return ShapeDiverDragControls;
}());
exports.ShapeDiverDragControls = ShapeDiverDragControls;
