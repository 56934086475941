
let MaterialHandlerHelpers = function (___handlers, ___refs) {
  const NAME_STANDARD = ___refs.nameStandard,
        NAME_SIMPLE = ___refs.nameSimple,
        MESSAGING_CONSTANTS = require('../../../shared/constants/MessagingConstants'),
        LOGGING_CONSTANTS = require('../../../shared/constants/LoggingConstants'),
        MESSAGE_PROTOTYPE = require('../../../shared/messages/MessagePrototype'),
        _handlers = ___handlers;

  let that;

  class MaterialHandlerHelpers {

    constructor() {
      that = this;
    }

    checkMaterials() {
      let info = _handlers.renderingHandler.getRendererInfo(),
          programs, program;

      if (info && info.programs) {
        programs = info.programs;
        for (let i = 0, len = programs.length; i < len; i++) {
          program = programs[i];

          if (program.diagnostics && program.diagnostics.runnable === false) {

            if(program.name === NAME_SIMPLE) {

              // even MeshBasicMaterial fails, there is nothing simpler than that
              // options:
              // 1. don't display the mesh (I'm against this, as it is highly probable that this is not the only mesh)
              // 2. abort the rendering and display an error (call _handlers.threeDManager.viewerApi.viewports.destroy(_handlers.threeDManager.runtimeId))

              // let's go for option 2 ;-)

              // 2.1. send STATUS_FAILED message which will show a "blue screen" message
              let response = {
                message: program,
                messageLevel: LOGGING_CONSTANTS.loggingLevels.ERROR,
                messageScope: 'Shader program could not be created',
                /* webglreport: #SS-695 include webglreport into this message,
                   use code from https://github.com/AnalyticalGraphicsInc/webglreport
                   and www.http://webglreport.com */
              };
              let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.STATUS_MESSAGE, response);
              _handlers.threeDManager.message(MESSAGING_CONSTANTS.messageTopics.STATUS_FAILED, m);

              // 2.2. log error to console and server
              _handlers.threeDManager.log(response.messageLevel, response.messageScope, response);
              _handlers.threeDManager.log(response.messageLevel | LOGGING_CONSTANTS.loggingLevels.LOG_TO_SERVER, response.messageScope, response);

              // 2.3. abort rendering
              _handlers.threeDManager.viewerApi.viewports.destroy(_handlers.threeDManager.runtimeId);

            } else {
              let objs = _handlers.threeDManager.helpers.getMeshMaterialObjects(),
                  obj;

              for(let j = 0, len2 = objs.length; j < len2; j++) {
                obj = objs[j];
                if(obj.material.program && obj.material.program.id === program.id){
                  obj.material = _handlers.materialHandler.getSimpleMaterial(obj.properties);
                  break;
                }
              }
            }
            delete program.diagnostics;
          }
        }
      }
    }

  }

  return new MaterialHandlerHelpers();
};

module.exports = MaterialHandlerHelpers;
