/**
 * @file The CameraApi is a property of the ViewportApi and must be used by all corresponding ViewportApis.
 *       It is used for all camera related functionality.
 *
 * @module CameraApi
 * @author Michael Oppitz
 */

let CameraApi = function (___api, ___refs) {

  const CameraApiInterface = require('../../interfaces/api/CameraApiInterface'),
        THREE = require('../../../externals/three'),
        APIResponse = require('../../../api/v2/ApiResponse');

  let _api = ___api,
      _threeDManager = ___refs.threeDManager;

  /**
   * @extends module:CameraApiInterface~CameraApiInterface
   * @lends module:CameraApi~CameraApi
   */
  class CameraApi extends CameraApiInterface {
    /**
     * The camera type, see {@link module:CameraApi~CameraApi#TYPE} for values
     *
     * @typedef {Number} module:CameraApi~CameraApi#CameraType
     */

    /**
     * ### ShapeDiver Viewer - Camera API
     *
     * The CameraApi implements all functionality related to the camera.
     * @constructs module:CameraApi~CameraApi
     */
    constructor() {
      super();

      /**
        * Enum for camera types.
        * @readonly
        * @enum {module:CameraApi~CameraApi#CameraType}
        */
      this.TYPE = {
        /** perspective camera */
        PERSPECTIVE: 0,
        /** orthographic camera, top view */
        TOP: 1,
        /** orthographic camera, bottom view */
        BOTTOM: 2,
        /** orthographic camera, right view */
        RIGHT: 3,
        /** orthographic camera, left view */
        LEFT: 4,
        /** orthographic camera, back view */
        BACK: 5,
        /** orthographic camera, front view */
        FRONT: 6,
        /** AR camera */
        AR: 7
      };

      this.get = this.get.bind(this);

      this.updateAsync = this.updateAsync.bind(this);
      this.animateAsync = this.animateAsync.bind(this);
      this.zoomAsync = this.zoomAsync.bind(this);
      this.resetAsync = this.resetAsync.bind(this);
      this.updateMatrices = this.updateMatrices.bind(this);
    }

    /** @inheritdoc */
    get() {
      return APIResponse(null, _threeDManager.cameraHandler.getPositionAndTarget());
    }

    /** @inheritdoc */
    updateAsync(camera, transition) {
      return _threeDManager.cameraHandler.setPositionAndTarget(
        camera.position,
        camera.target,
        transition
      ).then(function (bSuccess) {
        if (bSuccess)
          return APIResponse(null, _threeDManager.cameraHandler.getPositionAndTarget());
        else
          return APIResponse('Setting new camera target and position failed', _threeDManager.cameraHandler.getPositionAndTarget());
      });
    }

    /** @inheritdoc */
    animateAsync(path, transition) {
      if (!Array.isArray(path)) return Promise.resolve(APIResponse('First parameter must be an array of CameraDefinitions'));
      let positions = [], targets = [];
      path.forEach((p) => {
        positions.push(p.position);
        targets.push(p.target);
      });
      return _threeDManager.cameraHandler.setCameraPath(positions, targets, transition).then(function (bSuccess) {
        if (bSuccess)
          return APIResponse(null, _threeDManager.cameraHandler.getPositionAndTarget());
        else
          return APIResponse('Camera path animation failed', _threeDManager.cameraHandler.getPositionAndTarget());
      });
    }

    /** @inheritdoc */
    zoomAsync(scenePathsOrBB, transition) {
      let bb;
      if(scenePathsOrBB instanceof THREE.Box3) {
        bb = scenePathsOrBB;
      } else {
        bb = _threeDManager.computeBoundingBox(scenePathsOrBB);
      }

      return _threeDManager.cameraHandler.zoomExtents(
        transition, bb
      ).then(function (bSuccess) {
        if (bSuccess)
          return APIResponse(null, _threeDManager.cameraHandler.getPositionAndTarget());
        else
          return APIResponse('Zooming to extents failed', _threeDManager.cameraHandler.getPositionAndTarget());
      });
    }

    /** @inheritdoc */
    resetAsync(transition) {
      return _threeDManager.cameraHandler.resetPositionAndTarget(
        transition
      ).then(function (bSuccess) {
        if (bSuccess)
          return APIResponse(null, _threeDManager.cameraHandler.getPositionAndTarget());
        else
          return APIResponse('Resetting camera position or target failed', _threeDManager.cameraHandler.getPositionAndTarget());
      });
    }

    /** @inheritdoc */
    updateMatrices(viewMatrix, projectionMatrix) {
      _threeDManager.cameraHandler.setViewAndProjectionMatrix(viewMatrix, projectionMatrix);
      return APIResponse(null, true);
    }

  }

  return new CameraApi();
};

module.exports = CameraApi;
