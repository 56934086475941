/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *OutputVersion.js*
 *
 * ### Content
 *   * Object containing information to describe a specific state of a 3d scene
 *   * Types defined in this file mirror the ones in the module JSONOutputVersion
 *
 * @module OutputVersion
 * @author Mathias Höbinger <mathias@shapediver.com>
 */

/**
 * Import GlobalUtils
 */
var GlobalUtils = require('../util/GlobalUtils');

/**
 * An output id is a string that is unique within a model and describes a specific
 * part of the scene which can be updated independently of the rest of the scene.
 *
 * @typedef {String} OutputId
 */

/**
  * An output version id is a unique id of a specific state of an output id.
  *
  * @typedef {String} OutputVersionId
  */

/**
  * Output version content item for formats other than geometry (glb, tag2d, tag3d) and material
  * @typedef {Object} OutputVersionDataContentItem
  * @see module:JSONOutputVersion~JSONOutputVersionContentFormat
  * @property {module:JSONOutputVersion~JSONOutputVersionContentFormat} format - The format of this content item
  * @property {Object} data - Data object provided with the output id.
  */

/**
   * geometry data object
   * @typedef {Object} GeometryData
   * @property {String} path - Path of this geometry within the output version, in point notation
   * @property {String} type - One of 'mesh', 'line', 'points', 'tag2d', 'tag3d'
   * @property {THREE.BufferGeometry|THREE.Geometry} geometry - Geometry representation
   * @property {module:MaterialAttributes~MaterialAttributes} [material] - Material definition
   * @property {Boolean} [texCoord] - Does this geometry have texture coordinates (false is assumed)
   * @property {THREE.Matrix4} [initialMatrix] - Transformation matrix to be applied to the geometry object
   */

/**
  * Output version object
  * @typedef {Object} OutputVersion
  * @property {module:OutputVersion~OutputId} id - The output id this object represents a version of.
  * @property {module:OutputVersion~OutputVersionId} version - The output version which is represented by this object.
  * @property {String} name - The human-readable name of the output. There is no guarantee for the uniqueness of this name within the session.
  * @property {module:OutputVersion~GeometryData[]} [geometry] - An array of GeometryData objects corresponding to the geometry type objects in the content array of the corresponding {@link module:JSONOutputVersion~JSONOutputVersion JSONOutputVersion} object
  * @property {module:MaterialAttributes~MaterialAttributes[]} [material] - Array of MaterialAttribute objects corresponding to all {@link module:JSONMaterial~JSONMaterial JSONMaterial} items in the content array of the corresponding {@link module:JSONOutputVersion~JSONOutputVersion JSONOutputVersion} object.
  * @property {String} [materialId] - Id of a differend OuputVersion which holds the materials for the geometry in this one.
  * @property {module:OutputVersion~OutputVersionDataContentItem[]} [data] - Array of content items with formats other than geometry or material ones.
  * @property {THREE.Vector3} [bbmax] - 3d point defining the upper right corner of the bounding box defined by the geometry in this output version
  * @property {THREE.Vector3} [bbmin] - 3d point defining the lower left corner of the bounding box defined by the geometry in this output version
  */


/** Output version object in which all the materials have been compiled in
  *
  * The GeometryData object within this object types all have a material member,
  * therefore the 'material' and 'materialId' members of the {@link module:OutputVersion~OutputVersion OutputVersion} object
  * are not necessary here.
  * Data objects have been removed
  *
  * @typedef {Object} CompiledOutputVersion
  * @property {module:OutputVersion~OutputId} id - The output id this object represents a version of.
  * @property {module:OutputVersion~OutputVersionId} version - The output version which is represented by this object.
  * @property {String} name - The human-readable name of the output. There is no guarantee for the uniqueness of this name within the session.
  * @property {module:OutputVersion~GeometryData[]} geometry - An array of GeometryData objects corresponding to the geometry type objects in the content array of the corresponding {@link module:JSONOutputVersion~JSONOutputVersion JSONOutputVersion} object
  * @property {THREE.Vector3} [bbmax] - 3d point defining the upper right corner of the bounding box defined by the geometry in this output version
  * @property {THREE.Vector3} [bbmin] - 3d point defining the lower left corner of the bounding box defined by the geometry in this output version
  *
  */

/**
 * Object describing a specific state of a 3d SubScene
 *
 * This object includes geometry and associated materials together with
 * several types of meta-data.
 *
 * @param  {module:OutputVersion~OutputVersion[]} ___outputVersions - Array of output versions describing materials and geometry
 */
var SubScene = function(___outputVersions) {

  var that = this;

  /**
   * An index of output versions keyed by output id
   * @type {Object.<String, module:OutputVersion~OutputVersion>}
   * @private
   */
  var _outputs = {};

  var _names = {};

  /**
   * Add an output version to the scene description
   * @param  {module:OutputVersion~OutputVersion} outputVersion - The output version to be added. It will be refused if another output version with the same id was already added.
   * @return {Boolean} true if output version was added
   */
  this.addOutputVersion = function(outputVersion) {

    // sanity checks
    if (!outputVersion.hasOwnProperty('id') || _outputs.hasOwnProperty(outputVersion.id)) {
      return false;
    }

    // add output version to our index
    _outputs[outputVersion.id] = outputVersion;

    // remember names for fast access
    if (outputVersion.hasOwnProperty('name')) {
      _names[outputVersion.name] = _names[outputVersion.name] || [];
      _names[outputVersion.name].push(outputVersion.id);
    }

    return true;
  };

  this.removeOutputVersion = function(outputId) {
    if (!GlobalUtils.typeCheck(outputId, 'string')) {
      return;
    }
    if (_outputs[outputId]) {
      delete _outputs[outputId];
    }
    // this is slow, but this operation should be rare
    let k = Object.keys(_names);
    for (let key of k) {
      let index = _names[key].indexOf(outputId);
      if (index !== -1) _names[key].splice(index, 1);
    }
  };

  /**
   * Retrieve the output version for an id
   * @param  {String} outputId The output id
   * @return {module:OutputVersion~OutputVersion}    The output version if it exists
   */
  this.getOutputVersion = function(id) {
    return _outputs[id];
  };

  /**
   * Get the id of an output version with a specific human-readable name
   * If names appeare more than once, a random match will be returned
   * @param  {String} name Human-readable name of an output version
   * @return {String}      The output versions id
   */
  this.getOutputVersionIdByName = function(name) {
    return _names[name];
  };

  /**
   * List of output ids attached to this scene description
   * @type {OutputId[]}
   */
  this.outputIds = Object.defineProperty(this, 'outputIds', {
    enumerable: true,
    configurable: false,
    readable: true,
    get: function() {
      return Object.keys(_outputs);
    }
  });

  /**
   * List of output names attached to this scene description
   * @type {String[]}
   */
  this.outputNames = Object.defineProperty(this, 'outputNames', {
    enumerable: true,
    configurable: false,
    readable: true,
    get: function() {
      return Object.keys(_names);
    }
  });


  /**
   * Return output version in which any geometry has its material attributed directly attached
   * @param  {String} outputId The output id
   * @param  {module:MaterialAttributes~MaterialAttributes} defaultMaterial Material which will be assigned to any geometry that does not have one defined in some way already
   * @return {module:OutputVersion~CompiledOutputVersion} Output version in which the materials have been assigned directly to each geometry, null on error
   */
  this.getCompiledOutputVersion = function(id, defaultMaterial) {

    if (typeof id !== 'string' || !defaultMaterial) {
      return null;
    }

    // get input output version
    let ov = that.getOutputVersion(id);

    // if the input does not have geometry, compiling doesn't make any sense
    if (!ov || !Array.isArray(ov.geometry) || ov.geometry.length === 0) {
      return null;
    }

    // create new compiled output version object
    let cov = {
      geometry: []
    };

    // copy the trivial properties
    // #SS-931 move definition of these properties to a central place
    ['id', 'version', 'name', 'bbmin', 'bbmax', 'interactionGroup', 'interactionMode', 'duration', 'dragPlaneNormal', 'visible'].forEach((attr) => {
      if (ov.hasOwnProperty(attr)) {
        cov[attr] = ov[attr];
      }
    });

    // check if the materialId points to external materials
    let extMat = null;
    if (ov.hasOwnProperty('materialId')) {
      let matOv = this.getOutputVersion(ov.materialId);
      if (matOv && matOv.material) {
        extMat = matOv.material;
      }
    }

    // go through the geometries and add materials
    for (let geom of ov.geometry) {

      // new GeometryData object
      let g = {};

      // copy the trivial properties
      ['path', 'type', 'geometry', 'texcoord', 'initialMatrix', 'material', 'color'].forEach((attr) => {
        if (geom.hasOwnProperty(attr)) {
          g[attr] = geom[attr];
        }
      });

      // sanity checks
      ['path', 'type'].forEach((attr) => {
        if (!g.hasOwnProperty(attr)) {
          return null;
        }
      });

      // case 1: material is already defined (usually from within the glTF)
      if (g.hasOwnProperty('material')) {
        // nothing to do here
      } else {
        let mat = null;
        if (extMat) {
          // case 2: output version provided external material id
          mat = extMat;
        } else if (ov.hasOwnProperty('material')) {
          // case 3: output version has material definition
          mat = ov.material;
        } else {
          // case 4: we use the default material provided by the caller
          mat = [defaultMaterial];
        }

        // if the geometry was created from a ShapeDiver glTF, its top path
        // level is based on the content array within the output id.
        // we assign materials from the material array according to the shortest
        // list principle.
        let pathArray = g.path.split('.');
        if (pathArray.length > 0) {
          let contentIndexString = null;
          for (let path of pathArray) {
            if (path.includes('content_')) {
              contentIndexString = path;
              break;
            }
          }
          if (contentIndexString !== null) {
            let contentIndexArray = contentIndexString.split('_');
            if (contentIndexArray[0] === 'content' && contentIndexArray.length === 2) {
              let contentIndex = parseInt(contentIndexArray[1]);
              if (!isNaN(contentIndex)) {
                // #SS-837 the following line is not implemented as it should be, but it's kind of dangerous to change this now
                // in case contentIndex exceeds the length of the material list, we should always be assigning the last material
                // from this list, as opposed to cycling through the material list
                g.material = mat[contentIndex % mat.length].clone();
                // apply flat shading if necessary
                if (geom.hasOwnProperty('flatShade')) {
                  // otherwise the flatShading was already set to true via the material (higher priority)
                  if(g.material.flatShading === false)
                    g.material.flatShading = geom.flatShade;
                }
              }
            }
          }
        }
        // fallback to default material
        if (!g.hasOwnProperty('material')) {
          g.material = mat[0];
        }
      }

      // assign selected properties of the defaultMaterial, should they not exist in g.material
      g.material.bumpScale = defaultMaterial.bumpScale;


      // append new GeometryData object to geometry array
      cov.geometry.push(g);
    }

    // every GeometryData object in this output version has its material directly assigned now
    // returning compiled output version
    return cov;
  };

  // add initial output versions if specified
  if (___outputVersions !== null && Array.isArray(___outputVersions)) {
    for (let ov of ___outputVersions) {
      that.addOutputVersion(ov);
    }
  }
};

module.exports = SubScene;
