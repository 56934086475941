"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TYPES;
(function (TYPES) {
    TYPES["SLIDER"] = "slider";
    TYPES["STRING"] = "string";
    TYPES["BOOLEAN"] = "boolean";
    TYPES["COLOR"] = "color";
    TYPES["BUTTON"] = "button";
    TYPES["DROPDOWN"] = "dropdown";
    TYPES["CHECKLIST"] = "form";
    TYPES["FILE"] = "file";
    TYPES["MULTIPLE"] = "multiple";
    TYPES["VECTOR3"] = "vector3";
    TYPES["MODAL"] = "modal";
    //TIME= 'time',
    //DATE= 'date'
})(TYPES = exports.TYPES || (exports.TYPES = {}));
