const Accessor = require('./Accessor');



class BufferView {

  constructor(id, gltf, buffer) {

    this.id = id;
    this.buffer = buffer;
    this.bufferViewRef = gltf.bufferViews[id];
    this.byteOffset = this.bufferViewRef.byteOffset;
    this.byteLength = this.bufferViewRef.byteLength;
    this.byteStride = this.bufferViewRef.byteStride || 0;
    this.target = this.bufferViewRef.target;
    this.isInterleaved = false;
    this.interleavedBuffer = null;

    const thisAcc = gltf.accessors ?  gltf.accessors.filter(x => x.bufferView == this.id) : [];
    if (thisAcc.length > 0) {
      const a = thisAcc[0];
      this.isInterleaved = a.count * this.byteStride === this.byteLength;
    }
    this.numOfTypeArrayElementsPerVertex = 0;
    this.accessors = [];
    this.dataView = new DataView(this.buffer.buffer, this.byteOffset, this.byteLength);

    this.strideOffset = 0;
    for (let i in gltf.accessors) {
      if (gltf.accessors[i].bufferView == id) {
        const accessor = new Accessor(i, gltf, this.buffer, this);
        this.numOfTypeArrayElementsPerVertex += accessor.elementComponentsSize;
        this.accessors.push(accessor);
      }
    }
  }
}

module.exports = BufferView;