"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var SDCommitQueue_1 = require("./SDCommitQueue");
var SDCommitParametersQueue = /** @class */ (function (_super) {
    __extends(SDCommitParametersQueue, _super);
    // #region Constructors (1)
    function SDCommitParametersQueue(api, container, commitParameter) {
        var _this = _super.call(this, api, container, commitParameter) || this;
        api.addEventListener(api.EVENTTYPE.SETTINGS_UPDATE, function (e) {
            if (e.settings.key === "commitParameters") {
                _this.use = e.settings.valueNew;
                _this.clear();
            }
        });
        return _this;
    }
    // #endregion Constructors (1)
    // #region Protected Methods (1)
    SDCommitParametersQueue.prototype.execute = function () {
        var parameters = [];
        for (var id in this.queue) {
            var q = this.queue[id];
            parameters.push({ id: id, value: q.val });
            q.menuEl.setStackToLast();
        }
        this.api.parameters.updateAsync(parameters);
        this.clear();
    };
    return SDCommitParametersQueue;
}(SDCommitQueue_1.SDCommitQueueBase));
exports.SDCommitParametersQueue = SDCommitParametersQueue;
