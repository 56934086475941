/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiPluginInterfaceV2.1.js*
 *
 * ### Content
 *   * Abstract definition of the ShapeDiver 3D Viewer Plugin API V2.1
 *
 * @module ApiPluginInterface
 * @author ShapeDiver <contact@shapediver.com>
 */

////////////
////////////
//
// Plugin Interface
//
////////////
////////////

/**
 * Plugin status type, see {@link module:ApiPluginInterface~ApiPluginInterface#STATUS} for values
 * @typedef {Number} module:ApiPluginInterface~ApiPluginInterface#PluginStatus
 */

/**
  * Plugin state description
  * @typedef {Object} module:ApiPluginInterface~ApiPluginInterface#PluginStatusDescription
  * @property {String} id - runtime id of the plugin instance
  * @property {module:ApiPluginInterface~ApiPluginInterface#PluginStatus} status - status of the plugin instance
  * @property {String} name - name of the plugin
  * @property {String} shortName - short name of the plugin
  *
  */

/**
 * ### ShapeDiver Viewer - Plugin API
 *
 * The plugin interface is part of the global {@link module:ApiInterfaceV2~ApiInterfaceV2 API}.
 *
 * It allows to
 *  * get the status of currently registered plugin instances, and
 *  * register and deregister plugin instances.
 *
 * @interface ApiPluginInterface
 */
var ApiPluginInterface = function () {

  /**
  * Enum for plugin status.
  * @readonly
  * @enum {module:ApiPluginInterface~ApiPluginInterface#PluginStatus}
   */
  this.STATUS = {
    /** the plugin instance was created, a unique runtime id is not available yet  */
    NONE: 'none',
    /** the plugin instance got a unique runtime id and is ready to load  */
    READY: 'ready',
    /** the plugin instance failed for whatever reason and is not usable  */
    FAILED: 'failed',
    /** the plugin instance successfully loaded, any potential initialization is not yet finished */
    LOADED: 'loaded',
    /** plugin is operating, i.e. any potential initialization that was triggered by loading succeeded, ready to receive parameter updates */
    ACTIVE: 'active',
    /** plugin was unloaded and is ready to be garbage collected */
    UNLOADED: 'unloaded'
  };

  /**
   * Retrieve the current state of plugins
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} API response with a data array of type {@link module:ApiPluginInterface~ApiPluginInterface#PluginStatusDescription PluginStatusDescription}.
   *                                            Description of the current state of all plugins currently known to the viewer.
   *                                            Plugins may be asked for their status individually using their getStatus() method.
   */
  this.get = function () { };

  /**
   * Register a plugin
   *
   * Loads the plugin and returns once the plugin attains plugin status {@link module:ApiPluginInterface~ApiPluginInterface#STATUS ACTIVE} or
   * {@link module:ApiPluginInterface~ApiPluginInterface#STATUS FAILED}.
   * Unless the plugin's setting deferGeometryLoading is true, the plugin will be asked to display geometry for default parameter values.
   * @param {Object} plugin - The plugin to register
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with data of type {@link module:ApiPluginInterface~ApiPluginInterface#PluginStatusDescription PluginStatusDescription}
   *                                                      for the plugin to register.
   */
  this.registerPluginAsync = function () { };

  /**
   * Create and register a new instance of the ShapeDiver Communication Plugin
   *
   * Loads the plugin and returns once the plugin attains plugin status {@link module:ApiPluginInterface~ApiPluginInterface#STATUS ACTIVE} or
   * {@link module:ApiPluginInterface~ApiPluginInterface#STATUS FAILED}.
   * Unless the plugin's setting deferGeometryLoading is true, the plugin will be asked to display geometry for default parameter values.
   * @param {Object} settings - Settings to use for creating the CommPlugin instance
   * @param {String} settings.ticket - The ticket defining the model and other attributes of the session
   * @param {String} [settings.authorization=''] - optional authorization token to include with requests to the
   *                                         model view interface (prepend 'Bearer ' yourself if necessary)
   * @param {Boolean} [settings.ignoreSuperseded=true] - If truthy ignore intermediate solutions which at the time of their arrival
   *                                                     have already been superseded by another customization request.
   *                                                     Caution: In case of continuously repeated customization requests this may lead to the scene never being updated.
   * @param {Boolean} [settings.deferGeometryLoading] - Initialise the session but do not load geometry for default parameter values (wait for parameter update)
   * @param {String} [settings.modelViewUrl='https://model-view.shapediver.com:443'] - The model view interface url
   * @param {String} [settings.runtimeId] - Optional runtime id, if none is provided one will be created automatically, must be unique,
   *                                        used for identifying parameters with the same id across multiple plugins
   * @param {Number} [settings.ajaxTimeout=30000] - Timeout in milliseconds for ajax requests
   * @param {Number} [settings.loggingLevel] - Initial logging level - will be overridden once plugin gets registered, hence this is rarely used
   * @param {Number} [settings.messageLoggingLevel] - Initial message logging level - will be overridden once plugin gets registered, hence this is rarely used
   *
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with data of type {@link module:ApiPluginInterface~ApiPluginInterface#PluginStatusDescription PluginStatusDescription}
   *                                                      for the newly registered plugin.
   */
  this.registerCommPluginAsync = function () { };

  /**
   * Refresh the outputs of a plugin by calling its set*ParameterValues function for the current values of its parameters.
   *
   * Use this to initially load geometry if the plugin was created using 'deferGeometryLoading' set to true.
   *
   * @param {String} id - runtime id of the plugin whose outputs should be refreshed
   * @param {*} [payload] - Payload which will be passed through to the response and events related to a call of this function
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse with a data array of {@link ParameterUpdateObject ParameterUpdateObject} objects,
   *                                                      corresponding to the parameter state of the plugin.
   */
  this.refreshPluginAsync = function () {

  };

  /**
   * Save default parameter values for one or all plugins, using the current state of parameter values.
   *
   * This will fail if the plugin does not provide the capability to store model settings.
   * Instances of the CommPlugin provide this capability in case the model view session allows this.
   *
   * Be aware of the following: In case multiple instances of the CommPlugin using the same model are registered,
   * and you call this function without specifying a plugin runtime id, it is undefined which state will get stored.
   *
   * @param {String|String[]} [id] - runtime id(s) of the plugin(s) for which default parameter values should be stored,
   *   leave this undefined to store default parameter values for all plugin which provide the required capability
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be true or false depending on success.
   */
  this.saveDefaultParameterValuesAsync = function () {

  };

  /**
  * Deregister a plugin
  * @param {String} id - runtime id of the plugin to deregister
  * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with data of type
  *   {@link module:ApiPluginInterface~ApiPluginInterface#PluginState PluginStatusDescription} for the plugin deregister.
  */
  this.deregisterPluginAsync = function () { };

};

module.exports = ApiPluginInterface;