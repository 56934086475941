
/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ApiStateImplementationV2.1.js*
 *
 * ### Content
 *   * Implementation of the ShapeDiver 3D Viewer State API V2.1
 *
 * @module StateApi
 * @author ShapeDiver <contact@shapediver.com>
 */

/**
* Imported messaging constant definitions
*/
var messagingConstants = require('../../../shared/constants/MessagingConstants');

/**
* ApiInterfaceV2
*/
var ApiInterfaceV2 = new (require('../ApiInterfaceV2.1'))();

/**
* APIResponse factory
*/
const APIResponse = require('../ApiResponse');


////////////
////////////
//
// State Interface
//
////////////
////////////

/**
  * ShapeDiver 3D Viewer API V2 - State Interface
  * @class
  * @implements {module:ApiStateInterface~ApiStateInterface}
  * @param {Object} api - The global api object to which this api belongs
  * @param {Object} references.messagingHandler - Reference to the messaging handler
  * @param {Object} references.processStatusHandler - Reference to the process status handler
  */
var StateApi = function (_api, ___refs) {

  var that = this;

  // include enums from interface definition
  this.EVENTTYPE = ApiInterfaceV2.state.EVENTTYPE;

  // shortcuts to handlers
  var _messagingHandler = ___refs.messagingHandler;
  var _processStatusHandler = ___refs.processStatusHandler;

  /** @inheritdoc */
  this.get = function () {
    let s = _processStatusHandler.getSummary();
    s.failedStatusMessages = _messagingHandler.getFailedStatusMessages();
    return APIResponse(null, s);
  };

  /** @inheritdoc */
  this.setBusy = function (busy, progress) {
    let data = {};
    if (progress) {
      if (typeof progress !== 'number' || progress < 0 || progress > 1) {
        return APIResponse('progess must be a number between 0 and 1', _processStatusHandler.getSummary());
      }
      data.progress = progress;
    }

    if (busy) {
      data.busy = true;
      _processStatusHandler.updateProcessStatus(_api.getRuntimeId(), data);
    }
    else {
      _processStatusHandler.unregisterProcess(_api.getRuntimeId());
    }
    return this.get();
  };

  /** @inheritdoc */
  this.addEventListener = function (type, cb) {
    // check if event type is supported
    if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
      return APIResponse('Unsupported event type');
    // compose topic and subscribe to message stream
    let t = messagingConstants.messageTopics.STATUS + '.' + type;
    let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
      // create event object, add common event properties
      let event = new CustomEvent(type);
      event.api = msg.api;
      if (msg.token) event.token = msg.token;
      // get relevant data parts from message, add special event properties
      let partTypes = {};
      partTypes[messagingConstants.messageDataTypes.APP_STATUS] = { progress: 1 }; // null: copy all properties
      partTypes[messagingConstants.messageDataTypes.STATUS_MESSAGE] = null; // null: copy all properties
      for (let pt in partTypes) {
        let part = msg.getUniquePartByType(pt);
        if (part) {
          if (part.data) part = part.data;
          // add special event properties
          let props = partTypes[pt] ? partTypes[pt] : part;
          for (let k in props) {
            event[k] = part[k];
          }
        }
      }
      // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
      cb(event);
    });
    return APIResponse(null, subtokens);
  };

  /** @inheritdoc */
  this.removeEventListener = function (token) {
    return APIResponse(null, _api.unsubscribeFromMessageStream(token));
  };
};

module.exports = StateApi;