/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *SettingsHandler.js*
 *
 * ### Content
 *   * Functionality for persistent viewer settings
 *
 * @module SettingsHandler
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
 * Import global utils
 */
const GLOBAL_UTILS = require('../../shared/util/GlobalUtils'),
      TO_TINY_COLOR = require('../../shared/util/toTinyColor');

/**
 * Import ViewerApp constants
 */
var viewerAppConstants = require('../ViewerAppConstants');

/**
 * Messaging constants
 */
var messagingConstants = require('../../shared/constants/MessagingConstants');

/**
  * Imported global plugin constants
  */
var pluginConstantsGlobal = require('../../shared/constants/PluginConstantsGlobal');

/**
 * Message prototype
 */
var MessagePrototype = require('../../shared/messages/MessagePrototype');


/**
  * Constructor of the SettingsHandler mixin
  * @mixin SettingsHandler
  * @author Alex Schiftner <alex@shapediver.com>
  *
  * @param {Object} [settings] - Settings to be used
  * @param {Object} [settings.pluginManager] - Reference to the plugin manager
  * @param {Object} [settings.parameterManager] - Reference to the parameter manager
  * @param {Object} [settings.exportManager] - Reference to the export manager
  * @param {Object} [settings.viewportManager] - Reference to the viewport manager
  * @param {Object} [settings.app] - Reference to the ViewerApp
  */
var SettingsHandler = function(___settings) {

  var that = this;

  const _pluginManager = ___settings.pluginManager,
        _parameterManager = ___settings.parameterManager,
        _exportManager = ___settings.exportManager,
        _viewportManager = ___settings.viewportManager,
        _app = ___settings.app;

  /**
   * Object for collecting public members, this object will be returned instead of the default "this"
   */
  var _o = {};

  /**
   * remember stored settings we have received and used for restoration
   */
  var _storedSettings = false;

  /**
   * remember origin (as received by message) of settings we have received and used for restoration
   */
  var _storedSettingsOrigin; // eslint-disable-line no-unused-vars

  /**
   * remember version of settings we have received and used for restoration
   */
  var _storedSettingsVersion; // eslint-disable-line no-unused-vars

  /**
   * mapping of setting property names to internal settings
   * each property of mapping is an object defining how to handle restoration of the setting
   * this object holds the mappings for settings which are NOT related to threeDManagers
   */
  var _mapping = {};

  /**
   * Mapping definition
   * @typedef {Object} MappingDefinition
   * @property {Object|Function} handler - object whose setting should be set, or a function which should be called for storing the setting
   * @property {Function} [handlerInverse] - in case handler is a function, optional inverse handler to read the setting value
   * @property {String} [setting] - name of the setting which should be set
   * @property {String|Function} [type] - name of data type or callback for type checking
   * @property {Array} [hook] - if restoration of a setting implies restoration of another one, use this (see autoRotateSpeed for an example)
   * @property {Number} [order=0] - optional order which will be used to determine the order in which settings will get restored
   */

  //_mapping.version =

  /**
   * Get mapping for a specific threeDManager
   */
  let _getMapping = function(threeDManager) {
    let mapping = {};

    // Settings related to threeDManager
    if (threeDManager) {
      // // Settings related to threeDManager.lightHandler
      if ( threeDManager.lightHandler ) {
        mapping.lightScenes = {
          handler: threeDManager,
          setting: 'lights.lightScenes',
          type: 'object',
          order: 10
        };
        mapping.lightScene = {
          handler: threeDManager,
          setting: 'lights.lightScene',
          type: 'string',
          order: 20
        };
      }

      mapping.ambientOcclusion = {
        handler: threeDManager,
        setting: 'render.ambientOcclusion',
        type: 'boolean',
        order: 40
      };
      mapping.autoRotateSpeed = {
        handler: threeDManager,
        setting: 'camera.autoRotationSpeed',
        type: 'number',
        hook: (v) => (v != 0 ? [['enableAutoRotation', true]] : []),
        order: 50
      };
      mapping.backgroundColor = {
        type: (v) => (GLOBAL_UTILS.typeCheck(v, 'string') && v.length === 10),
        hook: function(v) {
          let tc = TO_TINY_COLOR(v);
          return [
            ['clearColor', tc.toHexString()],
            ['clearAlpha', tc.getAlpha()]
          ];
        },
        handlerInverse: () => {
          let color = threeDManager.getSetting('render.clearColor');
          let alpha = threeDManager.getSetting('render.clearAlpha');
          let tc = TO_TINY_COLOR(color);
          tc.setAlpha(alpha);
          return '0x' + tc.toHex8();
        },
        order: 60
      };
      mapping.cameraAutoAdjust = {
        handler: threeDManager,
        setting: 'camera.autoAdjust',
        type: 'boolean',
        order: 80
      };
      mapping.cameraMovementDuration = {
        handler: threeDManager,
        setting: 'camera.cameraMovementDuration',
        type: 'number',
        order: 90
      };
      mapping.cameraRevertAtMouseUp = {
        handler: threeDManager,
        setting: 'camera.revertAtMouseUp',
        type: 'boolean',
        order: 100
      };
      mapping.revertAtMouseUpDuration = {
        handler: threeDManager,
        setting: 'camera.revertAtMouseUpDuration',
        type: 'number',
        order: 110
      };
      mapping.clearAlpha = {
        handler: threeDManager,
        setting: 'render.clearAlpha',
        type: 'number',
        order: 120
      };
      mapping.clearColor = {
        handler: threeDManager,
        setting: 'render.clearColor',
        type: 'string',
        order: 130
      };
      mapping.controlDamping = {
        handler: threeDManager,
        setting: 'camera.damping',
        type: 'number',
        order: 140
      };
      mapping.disablePan = {
        handler: threeDManager,
        setting: 'camera.enablePan',
        type: 'boolean',
        transform: (v) => (!v),
        handlerInverse: () => (!threeDManager.getSetting('camera.enablePan')),
        order: 150
      };
      mapping.disableZoom = {
        handler: threeDManager,
        setting: 'camera.enableZoom',
        type: 'boolean',
        transform: (v) => (!v),
        handlerInverse: () => (!threeDManager.getSetting('camera.enableZoom')),
        order: 160
      };
      mapping.enableAutoRotation = {
        handler: threeDManager,
        setting: 'camera.enableAutoRotation',
        type: 'boolean',
        order: 170
      };
      mapping.environmentMapResolution = {
        handler: threeDManager,
        setting: 'material.environmentMapResolution',
        type: (v) => (['256', '512', '1024', '2048'].includes(v)),
        order: 180
      };
      mapping.environmentMap = {
        handler: threeDManager,
        setting: 'material.environmentMap',
        type: (v) => (GLOBAL_UTILS.typeCheck(v, 'string') || GLOBAL_UTILS.isArrayOfType(v, 'string')),
        order: 190
      };
      mapping.fov = {
        handler: threeDManager,
        setting: 'camera.fov',
        type: 'number',
        order: 200
      };
      mapping.pointSize = {
        handler: threeDManager,
        setting: 'render.pointSize',
        type: (v) => (typeof v === 'number' && v >= 0),
        order: 210
      };
      mapping.rotateSpeed = {
        handler: threeDManager,
        setting: 'camera.rotationSpeed',
        type: 'number',
        order: 220
      };
      mapping.showEnvironmentMap = {
        handler: threeDManager,
        setting: 'material.environmentMapAsBackground',
        type: 'boolean',
        order: 230
      };
      mapping.showGrid = {
        handler: threeDManager,
        setting: 'gridVisibility',
        type: 'boolean',
        order: 240
      };
      mapping.showGroundPlane = {
        handler: threeDManager,
        setting: 'groundPlaneVisibility',
        type: 'boolean',
        order: 250
      };
      mapping.showShadows = {
        handler: threeDManager,
        setting: 'render.shadows',
        type: 'boolean',
        order: 260
      };
      mapping.zoomExtentFactor = {
        handler: threeDManager,
        setting: 'camera.zoomExtentsFactor',
        type: 'number',
        order: 270
      };
      mapping.zoomSpeed = {
        handler: threeDManager,
        setting: 'camera.zoomSpeed',
        type: 'number',
        order: 280
      };
      mapping.panSpeed = {
        handler: threeDManager,
        setting: 'camera.panSpeed',
        type: 'number',
        order: 290
      };
      mapping.enableRotation = {
        handler: threeDManager,
        setting: 'camera.enableRotation',
        type: 'boolean',
        order: 300
      };

      mapping.topView = {
        handler: (v) => {
          return threeDManager.constants.cameraViewTypes ?
            threeDManager.updateSettingAsync('camera.type',
              v ? threeDManager.constants.cameraViewTypes.TOP : threeDManager.constants.cameraViewTypes.PERSPECTIVE
            ) : null;
        },
        handlerInverse: () => {
          let cameraType = threeDManager.getSetting('camera.type');
          return threeDManager.constants.cameraViewTypes ? cameraType === threeDManager.constants.cameraViewTypes.TOP ? true : false : false;
        },
        order: 310
      };
      mapping.camera = {
        handler: threeDManager,
        setting: 'camera.defaults.perspective',
        type: 'object',
        handlerInverse: () => {
          if (threeDManager.getSetting('camera.type') === threeDManager.constants.cameraViewTypes.PERSPECTIVE) {
            return threeDManager.cameraHandler.getPositionAndTarget();
          } else {
            return threeDManager.getSetting('camera.defaults.perspective');
          }
        },
        order: 320
      };
      mapping.cameraOrtho = {
        handler: threeDManager,
        setting: 'camera.defaults.orthographic',
        type: 'object',
        handlerInverse: () => {
          if (threeDManager.getSetting('camera.type') === threeDManager.constants.cameraViewTypes.TOP) {
            return threeDManager.cameraHandler.getPositionAndTarget();
          } else {
            return threeDManager.getSetting('camera.defaults.orthographic');
          }
        },
        order: 325
      };

      // ignored settings
      mapping.directUpdates = { // whether to immediately send a customization request after a parameter update in the UI
        handler: () => true
      };
      mapping.edgeColor = { // new viewer does not support automatic edge detection
        handler: () => true
      };
      mapping.edgeColorByObject = { // new viewer does not support automatic edge detection
        handler: () => true
      };
      mapping.showEdges = { // new viewer does not support automatic edge detection
        handler: () => true
      };
      mapping.build_version = { // viewer version which was used to store the settings object
        handler: () => true
      };
      mapping.build_date = { // viewer version which was used to store the settings object
        handler: () => true
      };

    }

    return mapping;
  };


  // ParameterManager.registerConfig is called directly to handle the following:
  // user specified ordering of parameters - array of ids of parameters
  _mapping.controlOrder = {
    handler: () => true
  };
  // key value pairs mapping parameter ids to user specified parameter names
  _mapping.controlNames = {
    handler: () => true
  };
  // user specified array of hidden parameters (ids)
  _mapping.parametersHidden = {
    handler: () => true
  };

  _mapping.version = {
    handler: (v) => {_storedSettingsVersion = v;},
    order: 500
  };

  if (_app) {

    _mapping.defaultMaterialColor = {
      handler: _app,
      setting: 'defaultMaterial.color',
      type: (v) => (GLOBAL_UTILS.typeCheck(v, 'color')),
      transform: (v) => {
        let tc = TO_TINY_COLOR(v);
        return tc.toArray();
      },
      order: 510
    };

    _mapping.bumpAmplitude = {
      handler: _app,
      setting: 'defaultMaterial.bumpAmplitude',
      type: 'number',
      order: 520
    };

    _mapping.commitParameters = {
      handler: _app,
      setting: 'commitParameters',
      type: 'boolean',
      order: 530
    };

  }

  /**
   * Due to changes from different versions, there are some settings that only apply to a specific version.
   * This function is called before the settings are restored, so that some settings can be adapted in advance.
   *
   * @param {String} v the build version
   * @param {Object} threeDManager
   */
  let _beforeRestoreVersionUpdate = function(v, threeDManager) {
    if(!v || GLOBAL_UTILS.versionStringLowerThan(v, '2.14.0')) {
      threeDManager.lightHandler.setLightSceneFromID('legacy');
      if(_storedSettings.ambientIntensity)
        threeDManager.lightHandler.updateLight({
          id: 'ambient0',
          type: 'ambient',
          properties: {
            intensity: parseFloat(_storedSettings.ambientIntensity)
          }
        });
      if(_storedSettings.spotlightIntensity)
        threeDManager.lightHandler.updateLight({
          id: 'directional0',
          type: 'directional',
          properties: {
            intensity: parseFloat(_storedSettings.spotlightIntensity)
          }
        });
    }
  };

  /**
   * Due to changes from different versions, there are some settings that only apply to a specific version.
   * This function is called after the settings are restored, so that some settings can be adapted in the end.
   *
   * @param {String} v the build version
   * @param {Object} threeDManager
   */
  let _afterRestoreVersionUpdate = function(v, threeDManager) {

  };

  /**
   * Get value of a stored setting
   * @param {String} key
   * @return {Object} undefined in case setting does not exist, value of stored setting otherwise
   */
  _o.getStoredSetting = function(key) {
    if (!_storedSettings) return false;
    return GLOBAL_UTILS.deepCopy(GLOBAL_UTILS.getAtPath(_storedSettings, key));
  };

  /**
   * Check whether a stored setting exists
   * @param {String} key
   * @return {Boolean} true in case stored setting exists, false otherwise
   */
  _o.hasStoredSetting = function(key) {
    if (!_storedSettings) return false;
    return GLOBAL_UTILS.getAtPath(_storedSettings, key) !== undefined;
  };

  /**
   * Restore a single setting.
   * @param {String} key
   * @param {Object} value
   * @return {Promise} resolves to true if setting could successfully be restored
   */
  _o.restoreSetting = function(prop, val, mapping) {
    let scope = 'SettingsHandler.restoreSetting';

    // check if we have a mapping for the property
    if (mapping.hasOwnProperty(prop)) {
      let m = mapping[prop];

      // check if handler is a function
      if (m.hasOwnProperty('handler') && typeof m.handler === 'function') {
        let retval = m.handler(val);
        if (retval instanceof Promise) return retval;
        else return Promise.resolve(retval);
      }

      // sanity check
      if (m.handler === undefined || typeof m.handler !== 'object' ||
        !GLOBAL_UTILS.typeCheck(m.setting, 'string') || m.setting.length <= 0 ||
        !m.handler.hasOwnProperty('updateSettingAsync')) {
        // no handler, try if we got a hook configuration
        if (m.hasOwnProperty('hook') && typeof m.hook === 'function') {
          let a = m.hook(val);
          return Promise.all(a.map((h) => (_o.restoreSetting(h[0], h[1], mapping))));
        }
        else {
          that.debug(scope, 'Mapping for property ' + prop + ' not configured correctly', m);
          return Promise.resolve(false);
        }
      }
      // type checking
      if (m.type !== undefined && m.type !== null) {
        if (GLOBAL_UTILS.typeCheck(m.type, 'string')) {
          if (typeof val !== m.type) {
            if (GLOBAL_UTILS.typeCheck(val, 'string') && m.type === 'number') {
              val = parseFloat(val);
              if ( Number.isNaN(val) ) {
                that.warn(scope, 'Not restoring setting ' + prop + ' due to NaN', _storedSettings[prop]);
                return Promise.resolve(false);
              }
            }
            else {
              that.warn(scope, 'Not restoring setting ' + prop + ' due to unexpected data type', _storedSettings[prop]);
              return Promise.resolve(false);
            }
          }
        }
        else if ( typeof m.type === 'function' ) {
          if ( !m.type(val) ) {
            that.warn(scope, 'Not restoring setting ' + prop + ' due to unexpected data type', _storedSettings[prop]);
            return Promise.resolve(false);
          }
        }
      }
      // transformation
      if (m.hasOwnProperty('transform') && typeof m.transform === 'function') {
        val = m.transform(val);
      }
      // update setting
      //console.time(m.setting); // use this for timing restoration of individual settings
      return m.handler.updateSettingAsync(m.setting, val).then(
        (r) => {
          //console.timeEnd(m.setting); // use this for timing restoration of individual settings
          if ( !r ) {
            that.warn(scope, 'Setting ' + prop + ' could not be restored', _storedSettings[prop]);
          }
          else {
            // if there is a hook, execute it
            if (m.hasOwnProperty('hook') && typeof m.hook === 'function') {
              let a = m.hook(val);
              return Promise.all(a.map((h) => (_o.restoreSetting(h[0], h[1], mapping))));
            }
          }
          return r;
        },
        (e) => {
          //console.timeEnd(m.setting); // use this for timing restoration of individual settings
          that.warn(scope, 'Setting ' + prop + ' could not be restored', _storedSettings[prop], e);
          return false;
        }
      );
    }
    else {
      that.warn(scope, 'Unknown setting ' + prop);
      return Promise.resolve(false);
    }
  };

  /**
   * Restore all of the settings from private copy.
   * @param {Object|Array} [api] optional threeDManager api or array of threeDManager apis to apply settings to
   *                       if none is given, settings for the app will be restored, and in return for all currently known threeDManagers
   *                       if a threeDManager api is given, settings will be restored only for this threeDManager
   * @return {Promise} resolves to true if all settings could successfully be restored
   */
  _o.restoreSettings = function(api) {
    let scope = 'SettingsHandler.restoreSettings';

    // check if we have some saved settings to restore
    if ( _storedSettings === undefined || typeof _storedSettings !== 'object' )
      return false;

    // get array of mappings for settings which should be restored
    let mappings = [];

    if (!api) {

      // use mapping for global settings
      mappings = [_mapping];

    } else {

      if(!Array.isArray(api))
        api = [api];

      // get mappings for given threeDManagers
      for (let i = 0, imax = api.length; i < imax; i++) {

        // skip restoring settings for viewport if they have been restored before
        if (api[i].threeDManager.restoredSettings) continue;
        api[i].threeDManager.restoredSettings = true;

        _beforeRestoreVersionUpdate(_storedSettings.build_version, api[i].threeDManager);
        mappings.push( _getMapping(api[i].threeDManager) );
        _afterRestoreVersionUpdate(_storedSettings.build_version, api[i].threeDManager);
      }

    }

    // iterate over all mappings and restore settings
    let prom = Promise.resolve(true),
        bSuccess = true;

    for (let j = 0, jmax = mappings.length; j < jmax; j++) {

      // current mapping
      let current_mapping = mappings[j];

      // get settings to be restored for the current mapping, and ...
      let settings_to_restore = [];
      for ( let prop in _storedSettings ) {
        if ( current_mapping.hasOwnProperty(prop) ) {
          let order = current_mapping[prop].order || 0;
          settings_to_restore.push({prop: prop, order: order});
        }
      }

      // ... order them according to what is defined in current_mapping
      settings_to_restore.sort( (a,b) => {
        return a.order - b.order;
      });

      // restore settings
      settings_to_restore.forEach( (s) => {
        // value to set
        let val = _storedSettings[s.prop];
        prom = prom.then(
          (r) => {
            if (!r) bSuccess = false;
            return _o.restoreSetting(s.prop, val, current_mapping );
          },
          (e) => {
            that.error(scope, e);
            bSuccess = false;
            return _o.restoreSetting(s.prop, val, current_mapping );
          }
        );
      });
    }

    // if we were not given any threeDManagers ...
    if (!api) {
      // ... done restoring app settings, restore settings for all known threeDManagers
      prom = prom.then(
        (r) => {
          if (!r) bSuccess = false;
          return _o.restoreSettings( _viewportManager.getApis() );
        },
        (e) => {
          that.error(scope, e);
          bSuccess = false;
          return _o.restoreSettings( _viewportManager.getApis() );
        }
      );
    }

    return prom.then(() => bSuccess);
  };

  /**
   * Save settings.
   *
   * Settings will be saved using a plugin, where the plugin will be determined by the following logic:
   *   * plugin which was specified as a parameter, if any
   *   * plugin which we received settings from initially, if any
   *   * first plugin which has the capability to store settings, if any
   * @param {String} [runtimeId] - optional runtime id of the plugin to use
   * @return {Promise<Boolean>} resolves to true if saving the settings has succeeded, false or reject otherwise
   */
  _o.saveSettings = function(runtimeId) {
    // look for first plugin which provides the SETTINGS capability if none was specified
    let settingsPlugin;
    if (!GLOBAL_UTILS.typeCheck(runtimeId,'string')) {
      // try whether settings can be saved to plugin defined by _storedSettingsOrigin
      if (_storedSettingsOrigin && _storedSettingsOrigin.plugin) {
        settingsPlugin = _pluginManager.getPluginByRuntimeId(_storedSettingsOrigin.plugin);
        if (settingsPlugin && !settingsPlugin.getCapabilities().includes(pluginConstantsGlobal.pluginCapabilities.SETTINGS)) {
          settingsPlugin = undefined;
        }
      }
      if (!settingsPlugin) {
        settingsPlugin = _pluginManager.getPluginByCapabilities( pluginConstantsGlobal.pluginCapabilities.SETTINGS );
      }
    } else {
      settingsPlugin = _pluginManager.getPluginByRuntimeId( runtimeId );
    }
    if (!settingsPlugin || !GLOBAL_UTILS.typeCheck(settingsPlugin.saveSettings, 'function')) {
      return Promise.resolve(false);
    }
    // compile backwards compatible settings object
    let settingsObject = _o.getCurrentSettingsObject();
    if (!settingsObject) {
      return Promise.resolve(false);
    }
    // add controlNames, controlOrder, parametersHidden of parameters
    let parameterSettings = _parameterManager.getConfig(settingsPlugin.getRuntimeId());
    Object.keys(parameterSettings).forEach((key) => {
      if ( settingsObject.hasOwnProperty(key) ) {
        settingsObject[key] = [...settingsObject[key], ...parameterSettings[key]];
      } else {
        settingsObject[key] = parameterSettings[key];
      }
    });
    // add controlNames, controlOrder, parametersHidden of exports
    let exportSettings = _exportManager.getConfig(settingsPlugin.getRuntimeId());
    Object.keys(exportSettings).forEach((key) => {
      if ( settingsObject.hasOwnProperty(key) ) {
        settingsObject[key] = [...settingsObject[key], ...exportSettings[key]];
      } else {
        settingsObject[key] = exportSettings[key];
      }
    });
    // controlNames array to object
    if ( settingsObject.hasOwnProperty('controlNames') ) {
      let tmp = settingsObject.controlNames;
      settingsObject.controlNames = {};
      tmp.forEach((kvp) => (settingsObject.controlNames[kvp.id] = kvp.name));
    }
    // controlOrder array conversion
    if ( settingsObject.hasOwnProperty('controlOrder') ) {
      settingsObject.controlOrder.sort((a,b)=>(a.order-b.order));
      settingsObject.controlOrder = settingsObject.controlOrder.map((kvp) => (kvp.id));
    }
    // call saveSettings of plugin
    return settingsPlugin.saveSettings(settingsObject)
      .then(
        () => (true)
      )
    ;
  };

  /**
   * Receiver for messages.
   *
   * @param  {String|module:MessagingConstants~MessageToken} token - Unique token of the process
   * @param  {module:MessagingConstants~ProcessStatusMessage} data - data of message part
   * @param  {module:MessagingConstants~MessageDataTypes} type - type of message part (e.g. APP_SETTINGS)
   * @param  {module:MessagingConstants~MessageOrigin} origin - origin of message
   */
  _o.messageReceiver = function(token, data, type, origin) {
    //let scope = 'SettingsHandler.messageReceiver';

    // no need to process settings twice
    if ( _storedSettings )
      return;

    // register initial loading processes
    if ( type === viewerAppConstants.messageDataTypes.APP_SETTINGS ) {
      _storedSettings = data;
      _storedSettingsOrigin =  origin;
      // wait for restoreSettings and send a SETTINGS_REGISTERED message
      _o.restoreSettings().then(
        () => {
          let m = new MessagePrototype(messagingConstants.messageDataTypes.GENERIC, {});
          that.message(messagingConstants.messageTopics.SETTINGS_REGISTERED, m);
        }
      );

      // remember that settings have been restored
      that.updateSetting('hasRestoredSettings', true);
    }

  };

  /**
   * Get current settings object for saving it using a CommPlugin
   * @param {Object|Array} [api] optional threeDManager api or array of threeDManager apis to retrieve settings for
   *                       if none is given, settings for the app will be retrieved, and for all currently known threeDManagers
   *                       if a threeDManager api is given, settings will be retrieved only for this threeDManager
   * @return {Object} settings object ready to be stored
   */
  _o.getCurrentSettingsObject = function(api) {
    let settingsObject = {},
        key,
        settingDefinition,
        settingHandler,
        settingHandlerInverse,
        settingName;

    // store viewer version and build date as part of settings object
    settingsObject.version = that.getSetting('build_version');
    settingsObject.build_version = that.getSetting('build_version');
    settingsObject.build_date = that.getSetting('build_date');

    // get array of mappings for settings which should be restored
    let mappings = [];

    // if no threeDManager is given, retrieve app settings and settings for all known threeDManagers
    if (!api) {
      // use mapping for app settings
      mappings = [_mapping];
      // get currently known threeDManagers
      api = _viewportManager.getApis();
    }

    if(!Array.isArray(api))
      api = [api];

    for (let i = 0, imax = api.length; i < imax; i++) {
      mappings.push( _getMapping(api[i].threeDManager) );
    }

    // reverse priority, app settings come first
    mappings.reverse();

    // for all mappings
    for (let j = 0, jmax = mappings.length; j < jmax; j++) {

      // current mapping
      let mapping = mappings[j];

      // loop over settings defined in current mapping
      for (key in mapping) {
        settingDefinition = mapping[key];
        settingHandler = settingDefinition.handler;
        settingHandlerInverse = settingDefinition.handlerInverse;
        settingName = settingDefinition.setting;
        // check if there is an inverse handler
        if ( GLOBAL_UTILS.typeCheck(settingHandlerInverse, 'function') ) {
          settingsObject[key] = settingHandlerInverse();
        }
        // else check if we can get the setting in the traditional way
        else if ( GLOBAL_UTILS.typeCheck(settingName, 'string') &&
          settingHandler &&
          GLOBAL_UTILS.typeCheck(settingHandler.getSetting, 'function') ) {
          settingsObject[key] = settingHandler.getSetting(settingName);
        }
      }
    }

    return settingsObject;
  };

  return _o;
};

module.exports = SettingsHandler;
