"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent = /** @class */ (function () {
    // #endregion Properties (10)
    // #region Constructors (1)
    function BaseCustomComponent(_type, _id, _parameterDefinition, _parent, _domManager, _editMode) {
        if (_editMode === void 0) { _editMode = false; }
        this._type = _type;
        this._id = _id;
        this._parameterDefinition = _parameterDefinition;
        this._parent = _parent;
        this._domManager = _domManager;
        this._editMode = _editMode;
        // #region Properties (10)
        this._children = [];
        this._labelClasses = ["uk-width-expand", "uk-form-label", "uk-text-small", "uk-margin-small-bottom", "uk-padding-remove", "uk-text-uppercase", "uk-text-truncate"];
        this._inputContainerClasses = ["uk-width-1-1", "uk-padding-remove"];
        this._rowId = this._id + "-controlmenu-row";
        this._labelId = this._id + "-label";
        this._inputContainerId = this._id + "-input-container";
    }
    Object.defineProperty(BaseCustomComponent.prototype, "children", {
        // #endregion Constructors (1)
        // #region Public Accessors (8)
        get: function () {
            return this._children;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "inputComponent", {
        get: function () {
            return this._inputComponent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "order", {
        get: function () {
            return this._parameterDefinition.order || 1;
        },
        set: function (v) {
            this._parameterDefinition.order = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "row", {
        get: function () {
            return this._row;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseCustomComponent.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Abstract Accessors (1)
    // #region Public Methods (1)
    BaseCustomComponent.prototype.destroy = function () {
        this._row.remove();
    };
    // #endregion Public Methods (1)
    // #region Protected Methods (4)
    BaseCustomComponent.prototype.createElement = function () {
        this.createRow();
        this.createLabel();
        this.createInputContainer();
        this.createInput();
    };
    BaseCustomComponent.prototype.createInputContainer = function () {
        this._inputContainer = this._domManager.createDomElement('div', this._inputContainerId, this._row, this._inputContainerClasses);
    };
    BaseCustomComponent.prototype.createLabel = function () {
        var labelAttributes = {
            title: this._parameterDefinition.name
        };
        this._label = this._domManager.createDomElement('label', this._labelId, this._row, this._labelClasses, labelAttributes);
        this._label.innerHTML = this._parameterDefinition.name;
        if (this._editMode) {
            this._label.classList.add("sdv-edit-mode");
        }
    };
    BaseCustomComponent.prototype.createRow = function () {
        this._row = this._domManager.createDomElement('div', this._rowId, this._parent, ["sd-max-width-1", "uk-padding-small", "uk-margin-remove", "sdv-control-panel-row-border"], { "uk-grid": 'margin: "";' });
    };
    return BaseCustomComponent;
}());
exports.BaseCustomComponent = BaseCustomComponent;
