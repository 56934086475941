const MenuComponent = require('../base/sd-controls.components.base').MenuComponent;
const createDOMElement = require('../dom/sd-controls.components.dom.dom-util').createDomElement;
const setDomElementEventListener = require('../dom/sd-controls.components.dom.dom-util').setDomElementEventListener;

/**
   * Creates the button menu element.
   * 
   * @param {String} id The id of the parameter or name of the setting. Unused in case of accordion.
   * @param {TYPES} type The type of menu element that is created.
   * @param {Object} param The parameters that should be used for this menu element.
   *  They can vary widely for different element but must contain an id and name.
   *  The specific values needed can be seen in the createElement functions.
   * @param {Object} controlsContext The controls context which holds all required dependencies, which include following
   * @param {String} [controlsContext.runTimeId] The runtime id.
   * @param {DomElement} [controlsContext.container] The dom object which is container for this controls.
   * @param {Object} [controlsContext.options] The viewer options.
   * @param {Object} [controlsContext.options.settings] If true, these are the controls for the settings
   * @param {Object} [controlsContext.options.editMode] If true, and this is not a settings object, the controls are made in edit mode
   * @param {DomElement} rowParent Changes parent of row, which is by default container. This is mainly for accordions.
   */
function ButtonInputMenuComponent(id, type, param, controlsContext, parentRow) {
  MenuComponent.call(this, type, param, controlsContext, parentRow);
  let title = param.name;

  this.row.removeChild(this.label);

  // create the button dom element
  this.inputComponent = createDOMElement('button', {
    id: this.slugId + '_button', parent: this.inputContainer, innerHTML: title,
    classList: ['uk-button', 'uk-button-default', 'uk-button-small', 'uk-width-expand', 'text-transform-none', 'uk-padding-remove', 'sd-control-button'],
    attributes: { 'type': 'button', 'name': this.slugId + '_button' }
  });

  // set the event listeners
  this.valueConversion = param.valueConversion ? param.valueConversion : 'none';
  setDomElementEventListener(controlsContext.api, id, this, this.inputComponent, 'click', this.update.bind(this));
}


ButtonInputMenuComponent.prototype = Object.create(MenuComponent.prototype);

ButtonInputMenuComponent.prototype.update = function () {
  if(this.inContentEditMode){
    return false;
  }
  return true;
}

ButtonInputMenuComponent.prototype.setEditable = function (val) {

  if (val) {
    this.inputComponent.contentEditable = true;
    this.inputComponent.addEventListener('input', function (event) {
      this.wasEdited = true;
      this.newName = event.target.innerText;
    }.bind(this));
  } else {
    this.inputComponent.removeAttribute('disabled');
  }
  this.inContentEditMode = val;
}

module.exports = ButtonInputMenuComponent;