"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var SDControlsComponentFactory_1 = require("./utils/SDControlsComponentFactory");
var SDControlsResizeManager_1 = require("./utils/SDControlsResizeManager");
var SDControlsDependenciesResolver_1 = require("./utils/SDControlsDependenciesResolver");
var SDControlsSortManager_1 = require("./utils/SDControlsSortManager");
var SDControlComponentContracts_1 = require("./utils/SDControlComponentContracts");
/**
 * Controls manager
 */
var SDControlsManager = /** @class */ (function () {
    // #endregion Properties (8)
    // #region Constructors (1)
    function SDControlsManager(_controlsContext, _container) {
        var _this = this;
        this._controlsContext = _controlsContext;
        this._container = _container;
        // #region Properties (8)
        this._controlsContracts = new SDControlComponentContracts_1.SDControlComponentsContracts();
        this._userControls = [];
        this._dependenciesResolved = false;
        // TODO: Refactor to interface for parameter controls.
        this._parameterControls = [];
        this._dependenciesResolver = new SDControlsDependenciesResolver_1.SDControlsDependenciesResolver(_controlsContext.useSpectrum);
        this._inputComponentsFactory = new SDControlsComponentFactory_1.SDControlsComponentFactory(this._controlsContext.domManager, _controlsContext.sdTinyColorFactory, _controlsContext.fileHelper, _controlsContext.useSpectrum);
        this._resizeManager = new SDControlsResizeManager_1.SDControlsResizeManager(this._controlsContext.isMobileDevice);
        this._sortManager = new SDControlsSortManager_1.SDControlsSortManager();
        this._container.addEventListener("viewer-resized", function (event) { return _this.viewerResized(event); });
    }
    Object.defineProperty(SDControlsManager.prototype, "componentFactory", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._inputComponentsFactory;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SDControlsManager.prototype, "controlsContracts", {
        get: function () {
            return this._controlsContracts;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (2)
    // #region Public Methods (3)
    /**
     * Create the input control
     * @param { IControlParameterDefinition } parameterDef
     */
    SDControlsManager.prototype.createUserDefinedParameter = function (parameterDefinition) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, control;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._controlsContracts.controlParameterDefinitionContract(parameterDefinition);
                        if (!(this._dependenciesResolved !== true)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this._dependenciesResolver.resolveDependenciesAsync()];
                    case 1:
                        _a._dependenciesResolved = _b.sent();
                        _b.label = 2;
                    case 2:
                        control = this._inputComponentsFactory.createUserDefinedParameter(parameterDefinition, this._controlsContext.container, this._controlsContext.editMode);
                        this._userControls.push(control);
                        this._sortManager.sort(this._userControls.concat(this._parameterControls));
                        return [2 /*return*/, new Promise(function (resolve) { return resolve(control.id); })];
                }
            });
        });
    };
    /**
     * Removes user defined parameter.
     * @param { string } id
     */
    SDControlsManager.prototype.removeUserDefinedParameter = function (id) {
        return this.removeParameter(this._userControls, id);
    };
    // TODO: HACK, remove later, this is done just to sort parameters
    SDControlsManager.prototype.resort = function () {
        this._sortManager.sort(this._userControls.concat(this._parameterControls));
    };
    // #endregion Public Methods (3)
    // #region Private Methods (2)
    SDControlsManager.prototype.removeParameter = function (controls, id) {
        for (var i = controls.length - 1; i >= 0; i--) {
            var parameter = controls[i];
            if (parameter.children && parameter.children.length > 0) {
                var res = this.removeParameter(parameter.children, id);
                if (res) {
                    return true;
                }
            }
            if (parameter.id === id) {
                controls.splice(i, 1);
                parameter.destroy();
                return true;
            }
        }
        return false;
    };
    SDControlsManager.prototype.viewerResized = function (event) {
        this._resizeManager.resize(this._userControls, event.detail.mode, event.detail.clientWidth);
    };
    return SDControlsManager;
}());
exports.SDControlsManager = SDControlsManager;
