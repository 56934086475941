/**
 * @file Creates a shared interface for all CameraHandlers.
 *       The CameraHandlerInterface must be implemented by all CameraHandlers.
 *       All described methods must be implemented.
 *
 * @module CameraHandlerInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the CameraHandler.
 * The CameraHandler implements all functionality related to the camera.
 *
 * //@interface CameraHandlerInterface
 */
class CameraHandlerInterface {
  /**
   * The constructor for the {@link module:CameraHandlerInterface~CameraHandlerInterface}.
   * @constructs module:CameraHandlerInterface~CameraHandlerInterface
   */
  constructor() { }

  /**
   * Pause the camera updates.
   *
   * @abstract
   */
  pause() { }

  /**
   * Resume the camera updates.
   *
   * @abstract
   */
  resume() { }

  /**
   * Destroy the cameraHandler.
   *
   * @abstract
   */
  destroy() { }




  /**
   * Sets the camera to the given position.
   *
   * @abstract
   * @param {Object} position The new position
   */
  setPosition(position) { }

  /**
   * Resets the camera to the last set position or the default position.
   * @abstract
   */
  resetPosition() { }

  /**
   * Returns the camera position.
   *
   * @abstract
   * @returns {Object} The camera position
   */
  getPosition() { }




  /**
   * Sets the camera to the given target.
   *
   * @abstract
   * @param {Object} target The new target
   */
  setTarget(target) { }

  /**
   * Resets the camera to the last set target or the default target.
   * @abstract
   */
  resetTarget() { }

  /**
   * Returns the camera target.
   *
   * @abstract
   * @returns {Object} The camera target
   */
  getTarget() { }




  /**
   * Sets the camera to the given position and target.
   *
   * @abstract
   * @param {Object} position The new position
   * @param {Object} target The new target
   * @param {Object} [options] Options object
   * @param {Boolean} [options.default=false] If true store final camera position and target as default.
   * @param {Number} [options.duration] Duration of the camera movement
   * @param {String|Function} [options.easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
   * @param {String} [options.coordinates='cylindrical'] - Defines coordinate system to use for animated camera paths. One of 'spherical' or 'cylindrical'.
   * @return {Promise<Boolean>} Resolves once the camera has been set to the requested location
   */
  setPositionAndTarget(position, target, options) { }

  /**
   * Resets the camera to the last set position or the default position.
   * Resets the camera to the last set target or the default target.
   *
   * @abstract
   * @param {Object} [options] Options object
   * @param {Number} [options.duration] Duration of the camera movement
   * @param {String|Function} [options.easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
   * @return {Promise<Boolean>} Resolves once the camera has been set to the requested location
   */
  resetPositionAndTarget(options) { }

  /**
   * Returns the camera position and target.
   *
   * @abstract
   * @returns {Object} The camera position and target
   */
  getPositionAndTarget() { }

  /**
   * Update camera based on raw camera view and projection matrices.
   *
   * @abstract
   * @param {THREE.Matrix4} viewMatrix camera view matrix, transforming world coordinates to camera coordinates
   * @param {THREE.Matrix4} projectionMatrix camera {@link https://threejs.org/docs/#api/en/cameras/Camera.projectionMatrix projection matrix}
   */
  setViewAndProjectionMatrix() { }

  /**
    * Sets camera to capture all objects in the scene
    *
    * @abstract
    * @param  {Object} [options] Options object
    * @param  {Boolean} [options.default=false] If true store final camera position and target as default.
    * @param  {Number} [options.duration] Duration of camera movement. By default camera is moved instantly.
    * @param {String|Function} [options.easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
    * @param {THREE.Box3} [bb] optional bounding box to zoom to instead of scene bounding box
    * @return {Promise<Boolean>} Resolves once camera has been set to show entire scene
    */
  zoomExtents(options, bb) { }

  /**
   * Moves the camera along a defined path
   *
   * @abstract
   * @param  {Object[]} [positions] Array of positions for the camera to pass through
   * @param  {Object[]} [targets]   Array of target coordinates for the camera to look at
   * @param  {Object} [options]   Options
   * @param  {Boolean} [options.default=false] If true store final camera position and target as default.
   * @param {Number} [options.duration=800] - duration of the transition in milliseconds
   * @param {String|Function} [options.easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
   * @param {String} [options.interpolation='CatmullRom'] - In case a string S is provided, the corresponding interpolation function TWEEN.Interpolation[S] will be used if it exists. Tween supports Linear, Bezier, and CatmullRom.
   * @param {String} [options.coordinates='cylindrical'] - Defines coordinate system to use for animated camera paths. One of 'spherical' or 'cylindrical'.
   * @return {Promise<Boolean>} Resolves with true once path animation has been finished.
   */
  setCameraPath(positions, targets, options) { }




  /**
   * Returns the camera.
   *
   * @abstract
   * @returns {THREE.PerspectiveCamera} The camera
   */
  getCamera() { }

  /**
   * Returns if the camera is currently moving.
   *
   * @abstract
   * @returns {Boolean} true if moving
   */
  isCameraMoving() { }

  /**
   * Updates the orbit contols and registeres for continous rendering if the camera is moving.
   * @abstract
   */
  updateOrbitControls() { }

  /**
   * Updates the position of the camera and the clipping planes according to the bounding sphere.
   *
   * @abstract
   * @param {THREE.Sphere} bs The bounding sphere around the scene
   */
  adjustToBoundingSphere(bs) { }

  /**
   * Sets the all elements of the camera that are related to the size of the renderer.
   *
   * @abstract
   * @param {Number} width The new width
   * @param {Number} height The new height
   */
  onResize(width, height) { }

}

module.exports = CameraHandlerInterface;
