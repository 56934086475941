"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The control type.
 */
var ParameterType;
(function (ParameterType) {
    ParameterType["STRING"] = "string";
    ParameterType["CHECKBOX"] = "checkbox";
    ParameterType["VECTOR3"] = "vector3";
    ParameterType["BUTTON"] = "button";
    ParameterType["CHECKLIST"] = "checklist";
    ParameterType["GROUP"] = "group";
    ParameterType["COLOR"] = "color";
    ParameterType["DROPDOWN"] = "dropdown";
    ParameterType["FILE"] = "file";
    ParameterType["MODAL"] = "modal";
    ParameterType["SLIDER"] = "slider";
    ParameterType["STATICHTML"] = "statichtml";
})(ParameterType = exports.ParameterType || (exports.ParameterType = {}));
