"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SDControlsSortManager = /** @class */ (function () {
    function SDControlsSortManager() {
    }
    // #region Public Methods (1)
    SDControlsSortManager.prototype.sort = function (elements) {
        if (elements.length > 0) {
            var sorted = elements.sort(function (a, b) { return a.order - b.order; });
            var index = sorted.length - 1;
            var current = sorted[index--];
            while (index >= 0) {
                var next = sorted[index--];
                // TOOD: Remove if, this is hack
                if (current.row.parentNode) {
                    current.row.parentNode.insertBefore(next.row, current.row);
                    current = next;
                }
                if (current.children && current.children.length > 0) {
                    this.sort(current.children);
                }
            }
        }
    };
    return SDControlsSortManager;
}());
exports.SDControlsSortManager = SDControlsSortManager;
