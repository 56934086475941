const createDOMElement = require('../dom/sd-controls.components.dom.dom-util').createDomElement;
const setDomElementEventListener = require('../dom/sd-controls.components.dom.dom-util').setDomElementEventListener;
const controlsUtil = require('../util/sd-controls.components.util.util');
const MenuComponent = require('../base/sd-controls.components.base').MenuComponent;

const STRING_SDVCP = 'sdv-control-panel-';
const STRING_SDVCPC = 'sdv-control-panel-control';

/**
  * Creates the check list menu element.
  * 
  * @param {String} id The id of the parameter or name of the setting. Unused in case of accordion.
   * @param {TYPES} type The type of menu element that is created.
   * @param {Object} param The parameters that should be used for this menu element.
   *  They can vary widely for different element but must contain an id and name.
   *  The specific values needed can be seen in the createElement functions.
   * @param {Object} controlsContext The controls context which holds all required dependencies, which include following
   * @param {String} [controlsContext.runTimeId] The runtime id.
   * @param {DomElement} [controlsContext.container] The dom object which is container for this controls.
   * @param {Object} [controlsContext.options] The viewer options.
   * @param {Object} [controlsContext.options.settings] If true, these are the controls for the settings
   * @param {Object} [controlsContext.options.editMode] If true, and this is not a settings object, the controls are made in edit mode
   * @param {DomElement} rowParent Changes parent of row, which is by default container. This is mainly for accordions.
   */
function ChecklistInputMenuComponent(id, type, param, controlsContext, parentRow) {
  MenuComponent.call(this, type, param, controlsContext, parentRow);

  let choices = param.choices,
    defaultVal = param.defval,
    listContainer, rLabel, cInputCheckbox, i, j, len;

  this.pushToValueStack(defaultVal);
  // create the form dom element
  this.cForm = createDOMElement('form', {
    id: this.slugId + '-form', parent: this.inputContainer,
    classList: [STRING_SDVCP + 'dropdown-input-form', STRING_SDVCPC]
  });

  this.inputComponent = [];
  // add all choices
  for (i = 0, len = choices.length; i < len; ++i) {

    // create the div dom element for a choice
    listContainer = createDOMElement('div', { parent: this.cForm });

    // create the label dom element for a choice
    rLabel = createDOMElement('label', {
      id: this.slugId + '-label-' + i, parent: listContainer, innerHTML: choices[i],
      classList: [STRING_SDVCP + 'checklist-input-checkbox-label']
    });

    // create the checkbox dom element for a choice
    cInputCheckbox = createDOMElement('input', {
      id: this.slugId + '-checkbox-' + i, parent: rLabel, innerHTML: choices[i], value: i,
      attributes: { 'type': 'checkbox', 'name': this.slugId + '-checkbox-' + i }
    });

    // create the checkbox helper dom element for a choice
    createDOMElement('div', {
      id: this.slugId + '-checkbox-helper', parent: rLabel,
      classList: STRING_SDVCP + 'checklist-input-boxhelper',
      attributes: { 'for': this.slugId + '-checkbox-' + i }
    });

    // set to checked if it is in the default values
    cInputCheckbox.checked = defaultVal.includes(i);
    this.inputComponent.push(cInputCheckbox);

    // a special value conversion is needed as the event listeners are set for the single options
    // therefore the conversion has to be made to create an array of all ticked boxes
    this.valueConversion = param.valueConversion ? param.valueConversion : (function () {
      let curValArr = [];
      for (j = 0; j < this.inputComponent.length; ++j) {
        if (this.inputComponent[j].checked)
          curValArr.push(j);
      }
      return curValArr;
    });

    // set the event listeners
    setDomElementEventListener(controlsContext.api, id, this, cInputCheckbox, 'change', this.update.bind(this));
  }

}

ChecklistInputMenuComponent.prototype = Object.create(MenuComponent.prototype);

ChecklistInputMenuComponent.prototype.hide = function (val) {
  this.hidden = val;
  controlsUtil.hide(this.cForm, val);
}

ChecklistInputMenuComponent.prototype.update = function (value) {
  this.pushToValueStack(value);
  this.currentValue = value;
  for (let i = 0, len1 = this.inputComponent.length; i < len1; ++i) {
    this.inputComponent[i].checked = value.includes(i);
  }
  return true;
}

module.exports = ChecklistInputMenuComponent;