"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var sd_controls_setting_1 = require("./sd.controls.setting");
var sd_controls_constants_types_1 = require("../../constants/sd-controls.constants.types");
var enums_1 = require("../../enums/enums");
var sd_controls_settings_html_helpers_1 = require("../sd-controls.settings.html-helpers");
var CreateSaveLightSceneModalSetting = /** @class */ (function (_super) {
    __extends(CreateSaveLightSceneModalSetting, _super);
    // #endregion Properties (5)
    // #region Constructors (1)
    function CreateSaveLightSceneModalSetting(api, settingsContext) {
        var _this = _super.call(this, api, "save.lights.scene", sd_controls_constants_types_1.TYPES.MODAL, "Save Light Scene", "Store the current lighting settings in a new light scene definition.", enums_1.SettingsGroups.LIGHTS, Number.POSITIVE_INFINITY) || this;
        _this.settingsContext = settingsContext;
        // #region Properties (5)
        _this.overwriteContent = "<label>The scene already exists. Do you want to overwrite it?</label>\n                          <p class=\"uk-text-right\">\n                            <button id=\"jq-add-lights-scene-modal-confirm\" data-overwrite=\"true\" class=\"uk-button uk-button-primary  sd-control-button-confirm\" type=\"button\">Yes</button>\n                            <button id=\"jq-add-lights-scene-modal-overwrite-cancel\" class=\"uk-button uk-button-default\" type=\"button\">No</button>\n                          </p>";
        _this.modalConfirmId = "jq-add-lights-scene-modal-confirm";
        _this.modalId = "jq-add-lights-scene-modal";
        _this.defval = false;
        _this.modalContent = _this.createModalContent(_this.getNameContent());
        return _this;
    }
    // #endregion Constructors (1)
    // #region Public Methods (3)
    CreateSaveLightSceneModalSetting.prototype.customUpdate = function (id) {
        var _this = this;
        var isOverwrite = document
            .getElementById(this.modalConfirmId)
            .getAttribute("data-overwrite");
        var scenes = this.api.scene.lights.getAllLightScenes().data;
        var nameElem = (document.getElementById("jq-add-lights-scene-name"));
        if (nameElem) {
            this._name = nameElem.value;
        }
        if (!isOverwrite) {
            var alertHtml = document.getElementById("jq-add-lights-scenes-alert");
            if (!this._name) {
                if (alertHtml) {
                    alertHtml.innerHTML = sd_controls_settings_html_helpers_1.HTMLSettingsHelper.createAlert("Please specify a name for the light scene");
                }
                return;
            }
            else if (this.isDefaultOrLegacyScene(this._name)) {
                if (alertHtml) {
                    alertHtml.innerHTML = sd_controls_settings_html_helpers_1.HTMLSettingsHelper.createAlert("Light scene \"" + this._name + "\" cannot be overriden or saved. Please use another name for light scene");
                }
                return;
            }
            else {
                if (alertHtml) {
                    alertHtml.innerHTML = "";
                }
            }
        }
        if (isOverwrite || !scenes.some(function (x) { return x == _this._name; })) {
            this.api.scene.lights.saveLightScene(this._name);
            UIkit.modal("#jq-add-lights-scene-modal").hide();
            document.getElementById("jq-add-lights-scenes-content").innerHTML = this.getNameContent();
            var a = this.settingsContext.settings["lights.lightScene"]
                .getMenuElements()
                .forEach(function (e) {
                var allScenes = _this.api.scene.lights.getAllLightScenes().data;
                var i = 0;
                for (i = 0; i < allScenes.length; i++) {
                    if (allScenes[i] === name) {
                        break;
                    }
                }
                e.createChoices(allScenes, i);
            });
        }
        else {
            document.getElementById("jq-add-lights-scenes-content").innerHTML = this.overwriteContent;
            document.getElementById("jq-add-lights-scene-modal-overwrite-cancel").onclick = function () {
                document.getElementById("jq-add-lights-scenes-content").innerHTML = _this.getNameContent();
                _this.getMenuElements().forEach(function (x) { return x.rebindConfirmEvent(); });
                _this.onModalOpen(null);
            };
        }
    };
    CreateSaveLightSceneModalSetting.prototype.onModalOpen = function (modal) {
        var lightScene = this.api.scene.lights.getLightScene().data;
        var name = this.resolveNameIfDuplicate("scene");
        if (this.api.scene.lights.getAllLightScenes().data.some(function (x) { return x === lightScene.id; })) {
            name = this.resolveNameIfDuplicate(lightScene.id);
        }
        var nameInputField = document.getElementById("jq-add-lights-scene-name");
        if (nameInputField !== null) {
            nameInputField.value = name;
            var dropdownInputField = document.getElementById("jq-add-lights-scene-existing");
            if (dropdownInputField !== null && !this.isDefaultOrLegacyScene(lightScene.id)) {
                dropdownInputField.value = lightScene.id;
            }
        }
        this.bindExistingSceneDropdownChanged();
    };
    CreateSaveLightSceneModalSetting.prototype.registerMenuElement = function (menuElement) {
        _super.prototype.registerMenuElement.call(this, menuElement);
        menuElement["onModalOpen"] = this.onModalOpen.bind(this);
    };
    // #endregion Public Methods (3)
    // #region Private Methods (6)
    CreateSaveLightSceneModalSetting.prototype.bindExistingSceneDropdownChanged = function () {
        var existing = document.getElementById("jq-add-lights-scene-existing");
        if (existing !== null) {
            existing.onchange = function () { return document.getElementById("jq-add-lights-scene-name").value = existing.value; };
        }
    };
    CreateSaveLightSceneModalSetting.prototype.createModalContent = function (nameContent) {
        return "<div class=\"uk-modal-dialog uk-modal-body\">\n            <h2 class=\"uk-modal-title\">Save Light Scene</h2>\n\n              <form id=\"jq-add-lights-scenes-content\" class=\"uk-form-horizontal\">\n                " + nameContent + "\n              </form>\n              <div id=\"jq-add-lights-scenes-alert\">\n\n              </div>\n            </div>";
    };
    CreateSaveLightSceneModalSetting.prototype.getNameContent = function () {
        var _this = this;
        var savedScenes = this.api.scene.lights.getAllLightScenes().data.filter(function (x) { return !_this.isDefaultOrLegacyScene(x); });
        var currentScene = this.api.scene.lights.getLightScene().data;
        var savedSceneContent = '';
        if (savedScenes.length > 0) {
            savedSceneContent = "<div class=\"uk-margin\">\n                              <label class=\"uk-form-label\" for=\"form-horizontal-select\">Saved Scenes:</label>\n                              <div class=\"uk-form-controls\">\n                                  <select class=\"uk-select\" id=\"jq-add-lights-scene-existing\">\n                                      " + savedScenes.map(function (x) { return "<option " + (_this.isDefaultOrLegacyScene(x) ? 'disabled' : '') + " " + (savedScenes.some(function (x) { return x === currentScene.id; }) ? 'selected' : '') + ">" + x + "</option>"; }).join("") + "\n                                  </select>\n                              </div>\n                            </div>";
        }
        return savedSceneContent + "\n            <div class=\"uk-margin\">\n              <label class=\"uk-form-label\" for=\"form-horizontal-text\">Save Current Scene As:</label>\n              <div class=\"uk-form-controls\">\n                  <input id=\"jq-add-lights-scene-name\" class=\"uk-input\" type=\"text\" placeholder=\"type name ...\">\n              </div>\n            </div>\n            <p class=\"uk-text-right\">\n              <button class=\"uk-button uk-button-default uk-modal-close\" type=\"button\">Cancel</button>\n              <button id=\"jq-add-lights-scene-modal-confirm\" class=\"uk-button uk-button-primary sd-control-button-confirm\" type=\"button\">Save</button>\n            </p>";
    };
    CreateSaveLightSceneModalSetting.prototype.isDefaultOrLegacyScene = function (sceneName) {
        return sceneName === "default" || sceneName === "legacy";
    };
    CreateSaveLightSceneModalSetting.prototype.moveNameIndex = function (name) {
        var index = name.lastIndexOf("_");
        if (index > -1) {
            var number = name.substr(index + 1);
            if (isNaN(number) == false) {
                number = Number(number);
                number++;
                name = name.replace(name.substr(index), "");
                return name + "_" + number;
            }
        }
        return name + "_1";
    };
    CreateSaveLightSceneModalSetting.prototype.resolveNameIfDuplicate = function (name) {
        var scenes = this.api.scene.lights.getAllLightScenes().data;
        if (scenes.some(function (x) { return x == name; })) {
            // check if extension is same or name is same
            while (scenes.some(function (x) { return x == name; })) {
                name = this.moveNameIndex(name);
            }
        }
        return name;
    };
    return CreateSaveLightSceneModalSetting;
}(sd_controls_setting_1.Setting));
exports.CreateSaveLightSceneModalSetting = CreateSaveLightSceneModalSetting;
