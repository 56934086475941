"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Enum for control elements sizes
 */
var ControlElementSize;
(function (ControlElementSize) {
    ControlElementSize[ControlElementSize["QTR_WIDTH"] = 0] = "QTR_WIDTH";
    ControlElementSize[ControlElementSize["HALF_WIDTH"] = 1] = "HALF_WIDTH";
    ControlElementSize[ControlElementSize["FULL_WIDTH"] = 2] = "FULL_WIDTH";
})(ControlElementSize = exports.ControlElementSize || (exports.ControlElementSize = {}));
var ViewerMode;
(function (ViewerMode) {
    ViewerMode["PORTRAIT"] = "portrait";
    ViewerMode["LANDSCAPE"] = "landscape";
})(ViewerMode = exports.ViewerMode || (exports.ViewerMode = {}));
