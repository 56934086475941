/**
 * @file Creates a shared interface for all OrbitControlsProxys.
 *       The OrbitControlsProxyInterface must be implemented by all OrbitControlsProxys.
 * 
 * @module OrbitControlsProxyInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the OrbitControlsProxy.
 * The OrbitControlsProxy implements all functionality needed for the initialization of the OrbitControls.
 * 
 * //@interface OrbitControlsProxyInterface
 */
class OrbitControlsProxyInterface {

  /**
   * The constructor for the {@link module:OrbitControlsProxyInterface~OrbitControlsProxyInterface}.
   * @constructs module:OrbitControlsProxyInterface~OrbitControlsProxyInterface
   */
  constructor() { }

  /**
   * Returns the current client size.
   * This method must be implemented!!!
   * @abstract
   * 
   * @typedef {Object} ClientSize
   * @property {Number} width
   * @property {Number} height
   * 
   * @returns {ClientSize} an object with the size of the client
   */
  getClientSize() { }

  /**
   * Method that is called by the OrbitControls when the state changes from NONE to something else.
   * 
   * The default behavior would be to add the 'mousemove', 'mouseup' and 'mouseout' events to the document.
   * @abstract
   */
  onStateChange() { }

  /**
   * Method that is called by the OrbitControls when the state changes to NONE.
   * 
   * The default behavior would be to remove the 'mousemove', 'mouseup' and 'mouseout' events to the document.
   * @abstract
   */
  onStateChangeNone() { }

  /**
   * Method that is called by the OrbitControls when the OrbitControls should be disposed.
   * 
   * The default behavior would be to remove all event listeners.
   * @abstract
   */
  dispose() { }

  /**
   * Method that can be called right after creating the OrbitControls.
   * Can be used to set up eventlisteners etc.
   * @abstract
   */
  initialize() { }

  //////////////////////
  //
  //
  // Methods that are overwritten by the OrbitControls.
  //
  //
  //////////////////////

  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onContextMenu() { }

  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onMouseDown() { }

  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onMouseWheel() { }

  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onTouchStart() { }
  
  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onTouchEnd() { }

  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onTouchMove() { }

  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onMouseMove() { }

  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onMouseUp() { }

  /**
   * This method will be overwritten by the OrbitControls.
   * It can be used as a callback for event listeners or similar.
   * @abstract
   */
  onKeyDown() { }
}

module.exports = OrbitControlsProxyInterface;