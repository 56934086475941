let InteractionHandler = function (___settings, ___handlers) {
  const THREE = require('../../../externals/three'),
        GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        PATH_UTILS = ___settings.pathUtils,
        MESSAGING_CONSTANTS = require('../../../shared/constants/MessagingConstants'),
        MESSAGE_PROTOTYPE = require('../../../shared/messages/MessagePrototype'),
        INTERACTION_IN_PROCESS_ID = 'interactionInProcess',
        HOVER_IN_PROCESS_ID = 'hoverInProcess',
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _geometryNode = ___settings.geometryNode,
        _handlers = ___handlers;

  let that,
      _objects = [],
      _domElement = _handlers.renderingHandler.getDomElement(),
      _mouseDown = false,
      _controls,
      _originalPosition = new THREE.Vector3(),
      _lastPosition = new THREE.Vector3();

  class InteractionHandler {

    constructor() {
      that = this;

      _controls = new THREE.DragControls(_objects, _handlers.cameraHandler.getCamera(), _domElement);
      _controls.addEventListener('dragstart', that._interactionStartEvent);
      _controls.addEventListener('drag', that._interactionEvent);
      _controls.addEventListener('dragend', that._interactionEndEvent);
      _controls.addEventListener('hoveron', that._hoverStartEvent);
      _controls.addEventListener('hover', that._hoverEvent);
      _controls.addEventListener('hoveroff', that._hoverEndEvent);
      _controls.addEventListener('emptyclick', that._interactionEmptyClickEvent);
      _controls.addEventListener('mousedowncustom', function () {
        _mouseDown = true;
      });
      _controls.addEventListener('mouseupcustom', function () {
        _mouseDown = false;
      });

      ////////////
      ////////////
      //
      // Highlighting Handler
      //
      ////////////
      ////////////
      _handlers.highlightingHandler = new (require('./HighlightingHandler'))({
        settings: _settings,
        scene: _scene,
        geometryNode: _geometryNode,
        pathUtils: PATH_UTILS,
      }, _handlers);
      _handlers.threeDManager.highlightingHandler = _handlers.highlightingHandler;
    }


    /**
     * Given an interaction data message, create an Event and dispatch it via the DOM.
     *
     * @param {module:MESSAGING_CONSTANTS~InteractionDataMessage} idm - interaction data message
     */
    _dispatchEvent(idm) {
      let evt = new CustomEvent(idm.type);
      for (let k of idm) {
        evt[k] = idm[k];
      }
      _domElement.dispatchEvent(evt);
      return evt;
    }

    /**
     * The event that is trigger when a drag has been started.
     *
     * @param {Event} event
     */
    _interactionStartEvent(event) {

      // check if the interaction group allows dragging or selecting
      if (!event.object.interactionGroup) return;

      let g = _handlers.interactionGroupManager.getGroup(event.object.interactionGroup);
      if (!g) return;
      if (!(g.isDraggable() == true || g.isSelectable() == true)) return;

      // get reference to object
      let obj3D;
      if (event.object.interactionPath !== undefined && 
        (event.object.parent.meshHelper !== true || event.object.parent.interactionType === 'global' )) {
        obj3D = PATH_UTILS.getPathObject(_geometryNode, event.object.interactionPath + '');
      } else {
        obj3D = event.object.parent;
      }

      if (g.isDraggable() == true) {

        // remember original and last position
        _originalPosition.copy(event.object.position);
        _lastPosition.copy(event.object.position);

        // camera controls must be disabled during dragging
        _handlers.threeDManager.updateSetting('camera.enableOrbitControls', false);

        // dragging an object potentially changes shadows
        _handlers.renderingHandler.updateShadowMap();

        // highlight dragged object
        _handlers.highlightingHandler.setDragged(event.object);
        _handlers.renderingHandler.registerForContinuousRendering(INTERACTION_IN_PROCESS_ID);

        // create interaction data message
        let idm = {
          type: MESSAGING_CONSTANTS.messageTopics.SCENE_DRAG_START,
          viewportRuntimeId: _handlers.threeDManager.runtimeId,
          obj: obj3D,
          scenePath: PATH_UTILS.getObjectPath(obj3D),
          dragPosStart: _originalPosition.clone(),
          clientX: event.event.clientX,
          clientY: event.event.clientY
        };

        // send interaction data message via pubsub
        let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
        _handlers.threeDManager.message(idm.type, m);

        // create event and dispatch it via dom
        that._dispatchEvent(idm);
      }

      if (g.isSelectable() == true) {

        // toggle object selection status and highlighting, create interaction data message
        let idm = {
          viewportRuntimeId: _handlers.threeDManager.runtimeId,
          clientX: event.event.clientX,
          clientY: event.event.clientY
        };

        if (_handlers.highlightingHandler.toggleSelected(event.object)) {
          idm.type = MESSAGING_CONSTANTS.messageTopics.SCENE_SELECT_ON;

          let g = _handlers.interactionGroupManager.getGroup(event.object.interactionGroup);

          if (g.getSelectionMode() === 'single') {
            let currentSelected = _handlers.highlightingHandler.getSelected();

            for (let i = 0, len = currentSelected.length; i < len; i++) {
              if (currentSelected[i].interactionGroup == event.object.interactionGroup &&
                currentSelected[i] != event.object) {

                // send selection off message
                let obj3DPrevSelection;
                if (currentSelected[i].interactionPath !== undefined && 
                  (currentSelected[i].parent.meshHelper !== true || currentSelected[i].parent.interactionType === 'global' )) {
                  obj3DPrevSelection = PATH_UTILS.getPathObject(_geometryNode, currentSelected[i].interactionPath + '');
                } else {
                  obj3DPrevSelection = currentSelected[i].parent;
                } 
                
                _handlers.highlightingHandler.removeSelected(currentSelected[i]);

                let idmPrevSelection = {
                  type: MESSAGING_CONSTANTS.messageTopics.SCENE_SELECT_OFF,
                  obj: obj3DPrevSelection,
                  scenePath: PATH_UTILS.getObjectPath(obj3DPrevSelection),
                  clientX: event.event.clientX,
                  clientY: event.event.clientY
                };
                // send interaction data message via pubsub
                let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idmPrevSelection);
                _handlers.threeDManager.message(idmPrevSelection.type, m);

                // create event and dispatch it via dom
                that._dispatchEvent(idmPrevSelection);

                // there can only be one selected so we can break
                break;
              }
            }
          }
        } else {
          idm.type = MESSAGING_CONSTANTS.messageTopics.SCENE_SELECT_OFF;
        }
        idm.obj = obj3D;
        idm.scenePath = PATH_UTILS.getObjectPath(obj3D);

        if(idm.type === MESSAGING_CONSTANTS.messageTopics.SCENE_SELECT_ON)
          idm.selectPos = event.point;

        // send interaction data message via pubsub
        let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
        _handlers.threeDManager.message(idm.type, m);

        // create event and dispatch it via dom
        that._dispatchEvent(idm);

        // change of selection status requires rendering
        _handlers.renderingHandler.render();
      }

    }

    /**
     * The event that is trigger while dragging is going on.
     *
     * @param {Event} event
     */
    _interactionEvent(event) {

      // check if the interaction group allows dragging
      if (!event.object.interactionGroup) return;
      let g = _handlers.interactionGroupManager.getGroup(event.object.interactionGroup);
      if (!g) return;
      if (g.isDraggable() == false) return;

      // we can't do anything without a position
      if (!event.position) return;

      // get reference to object, move it
      let obj3D;
      if (event.object.interactionPath !== undefined &&
        (event.object.parent.meshHelper !== true || event.object.parent.interactionType === 'global' )) {
        obj3D = PATH_UTILS.getPathObject(_geometryNode, event.object.interactionPath + '');
        // move relative
        obj3D.position.add(event.position.clone().sub(_lastPosition));
      } else {
        obj3D = event.object.parent;
        obj3D.position.add(event.position.clone().sub(_lastPosition));
      }

      // remember last position
      _lastPosition.copy(event.position);

      // create interaction data message
      let idm = {
        type: MESSAGING_CONSTANTS.messageTopics.SCENE_DRAG_MOVE,
        viewportRuntimeId: _handlers.threeDManager.runtimeId,
        obj: obj3D,
        scenePath: PATH_UTILS.getObjectPath(obj3D),
        dragPosStart: _originalPosition.clone(),
        dragPosAbs: _lastPosition.clone(),
        clientX: event.event.clientX,
        clientY: event.event.clientY
      };
      idm.dragPosRel = idm.dragPosAbs.clone().sub(idm.dragPosStart);

      // send interaction data message via pubsub
      let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
      _handlers.threeDManager.message(idm.type, m);

      // create event and dispatch it via dom
      that._dispatchEvent(idm);

      // dragging an object potentially changes shadows
      _handlers.renderingHandler.updateShadowMap();
    }

    /**
     * The event that is trigger when a drag has been ended.
     *
     * @param {Event} event
     */
    _interactionEndEvent(event) {

      // check if the interaction group allows dragging
      if (!event.object.interactionGroup) return;
      let g = _handlers.interactionGroupManager.getGroup(event.object.interactionGroup);
      if (!g) return;
      if (g.isDraggable() == false) return;

      // back to normal
      _handlers.highlightingHandler.removeDragged();

      // create interaction data message
      let idm = {
        type: MESSAGING_CONSTANTS.messageTopics.SCENE_DRAG_END,
        viewportRuntimeId: _handlers.threeDManager.runtimeId,
        clientX: event.event.clientX,
        clientY: event.event.clientY
      };

      let hovered = _handlers.highlightingHandler.getHovered();
      if(hovered) {
        if (hovered.interactionPath !== undefined && 
          (hovered.parent.meshHelper !== true || hovered.parent.interactionType === 'global' )) {
          hovered = PATH_UTILS.getPathObject(_geometryNode, hovered.interactionPath + '');
        } else {
          hovered = hovered.parent;
        }
        idm.hovered = {
          obj: hovered,
          scenePath: PATH_UTILS.getObjectPath(hovered),
        };
      }

      // get final position
      let endPosition, obj3D;
      if (event.object.interactionPath !== undefined && 
        (event.object.parent.meshHelper !== true || event.object.parent.interactionType === 'global' )) {
        obj3D = PATH_UTILS.getPathObject(_geometryNode, event.object.interactionPath + '');
        endPosition = _lastPosition;
      } else {
        obj3D = event.object.parent;
        endPosition = _lastPosition;
      }

      idm.obj = obj3D;
      idm.scenePath = PATH_UTILS.getObjectPath(obj3D);
      idm.dragPosStart = _originalPosition.clone();
      idm.dragPosAbs = endPosition.clone();

      idm.dragPosRel = idm.dragPosAbs.clone().sub(idm.dragPosStart);

      // send interaction data message via pubsub
      let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
      _handlers.threeDManager.message(idm.type, m);

      // reset the rendering status
      _handlers.threeDManager.updateSetting('camera.enableOrbitControls', true);
      _handlers.renderingHandler.unregisterForContinuousRendering(INTERACTION_IN_PROCESS_ID);

      // create event and dispatch it via dom
      that._dispatchEvent(idm);
    }

    /**
     * The event that is trigger when a click is made on no object.
     */
    _interactionEmptyClickEvent() {
      let currentSelected = _handlers.highlightingHandler.getSelected();
      let deselection = false;

      let i = currentSelected.length;
      while (i--) {
        let g = _handlers.interactionGroupManager.getGroup(currentSelected[i].interactionGroup);
        if (g.getSelectionMode() === 'single') {
          // send selection off message
          let obj3DPrevSelection;
          if (currentSelected[i].interactionPath !== undefined && 
            (currentSelected[i].parent.meshHelper !== true || currentSelected[i].parent.interactionType === 'global' )) {
            obj3DPrevSelection = PATH_UTILS.getPathObject(_geometryNode, currentSelected[i].interactionPath + '');
          } else {
            obj3DPrevSelection = currentSelected[i].parent;
          }
          
          _handlers.highlightingHandler.removeSelected(currentSelected[i]);

          let idmPrevSelection = {
            type: MESSAGING_CONSTANTS.messageTopics.SCENE_SELECT_OFF,
            obj: obj3DPrevSelection,
            scenePath: PATH_UTILS.getObjectPath(obj3DPrevSelection)
          };
          // send interaction data message via pubsub
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idmPrevSelection);
          _handlers.threeDManager.message(idmPrevSelection.type, m);

          // create event and dispatch it via dom
          that._dispatchEvent(idmPrevSelection);

          deselection = true;
        }
      }

      // change of selection status requires rendering
      if (deselection == true)
        _handlers.renderingHandler.render();
    }

    /**
     * The event that is triggered when hovering over a new object starts.
     *
     * @param {Event} event
     */
    _hoverStartEvent(event) {
      if (_mouseDown === true && !_handlers.highlightingHandler.getDragged())
        return;

      // check if the interaction group allows hovering
      if (!event.object.interactionGroup) return;
      let g = _handlers.interactionGroupManager.getGroup(event.object.interactionGroup);
      if (!g) return;
      if (g.isHoverable() == false) return;

      // get reference to object
      let obj3D;
      if (event.object.interactionPath !== undefined && 
        (event.object.parent.meshHelper !== true || event.object.parent.interactionType === 'global' )) {
        obj3D = PATH_UTILS.getPathObject(_geometryNode, event.object.interactionPath + '');
      } else {
        obj3D = event.object.parent;
      }

      // highlight the hovered object
      _handlers.highlightingHandler.setHovered(event.object);
      _handlers.renderingHandler.registerForContinuousRendering(HOVER_IN_PROCESS_ID);

      // create interaction data message
      let idm = {
        type: MESSAGING_CONSTANTS.messageTopics.SCENE_HOVER_ON,
        viewportRuntimeId: _handlers.threeDManager.runtimeId,
        obj: obj3D,
        scenePath: PATH_UTILS.getObjectPath(obj3D),
        hoverPos: event.point,
        clientX: event.event.clientX,
        clientY: event.event.clientY
      };

      // send interaction data message via pubsub
      let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
      _handlers.threeDManager.message(idm.type, m);

      // create event and dispatch it via dom
      that._dispatchEvent(idm);
    }

    /**
     * The event that is triggered when hovering over an object.
     *
     * @param {Event} event
     */
    _hoverEvent(event) {
      if (_mouseDown === true && !_handlers.highlightingHandler.getDragged())
        return;

      // check if the interaction group allows hovering
      if (!event.object.interactionGroup) return;
      let g = _handlers.interactionGroupManager.getGroup(event.object.interactionGroup);
      if (!g) return;
      if (g.isHoverable() == false) return;

      // get reference to object
      let obj3D;
      if (event.object.interactionPath !== undefined && 
        (event.object.parent.meshHelper !== true || event.object.parent.interactionType === 'global' )) {
        obj3D = PATH_UTILS.getPathObject(_geometryNode, event.object.interactionPath + '');
      } else {
        obj3D = event.object.parent;
      }

      // the camera was moving when the hovered started so nothing was activated,
      // now that the camera stopped it has to be activated
      if (_handlers.highlightingHandler.getHovered() !== event.object) {
        _handlers.highlightingHandler.setHovered(event.object);
        _handlers.renderingHandler.registerForContinuousRendering(HOVER_IN_PROCESS_ID);

        // create interaction data message
        let idmHover = {
          type: MESSAGING_CONSTANTS.messageTopics.SCENE_HOVER_ON,
          viewportRuntimeId: _handlers.threeDManager.runtimeId,
          obj: obj3D,
          scenePath: PATH_UTILS.getObjectPath(obj3D),
          hoverPos: event.point,
          clientX: event.event.clientX,
          clientY: event.event.clientY
        };

        // send interaction data message via pubsub
        let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idmHover);
        _handlers.threeDManager.message(idmHover.type, m);

        // create event and dispatch it via dom
        that._dispatchEvent(idmHover);
      }

      // create interaction data message
      let idm = {
        type: MESSAGING_CONSTANTS.messageTopics.SCENE_HOVER_OVER,
        viewportRuntimeId: _handlers.threeDManager.runtimeId,
        obj: obj3D,
        scenePath: PATH_UTILS.getObjectPath(obj3D),
        hoverPos: event.point,
        clientX: event.event.clientX,
        clientY: event.event.clientY
      };

      // send interaction data message via pubsub
      let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
      _handlers.threeDManager.message(idm.type, m);

      // create event and dispatch it via dom
      that._dispatchEvent(idm);
    }

    /**
     * The event that is trigger when hovering of an object ends.
     *
     * @param {Event} event
     */
    _hoverEndEvent(event) {
      // if no object has been hovered we can return
      if (!_handlers.highlightingHandler.getHovered())
        return;

      // check if the interaction group allows hovering
      if (!event.object.interactionGroup) return;
      let g = _handlers.interactionGroupManager.getGroup(event.object.interactionGroup);
      if (!g) return;
      if (g.isHoverable() == false) return;

      // get reference to object
      let obj3D;
      if (event.object.interactionPath !== undefined && 
        (event.object.parent.meshHelper !== true || event.object.parent.interactionType === 'global' )) {
        obj3D = PATH_UTILS.getPathObject(_geometryNode, event.object.interactionPath + '');
      } else {
        obj3D = event.object.parent;
      }

      // back to normal
      _handlers.highlightingHandler.removeHovered();

      // create interaction data message
      let idm = {
        type: MESSAGING_CONSTANTS.messageTopics.SCENE_HOVER_OFF,
        viewportRuntimeId: _handlers.threeDManager.runtimeId,
        obj: obj3D,
        scenePath: PATH_UTILS.getObjectPath(obj3D),
        clientX: event.event.clientX,
        clientY: event.event.clientY
      };

      // send interaction data message via pubsub
      let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
      _handlers.threeDManager.message(idm.type, m);

      //reset the rendering status
      _handlers.renderingHandler.unregisterForContinuousRendering(HOVER_IN_PROCESS_ID);

      // create event and dispatch it via dom
      that._dispatchEvent(idm);
    }

    ////////////
    ////////////
    //
    // InteractionHandler API
    //
    ////////////
    ////////////

    /**
     * Adds the object to the interaction group.
     *
     * @param {String} path The path to the object
     * @param {String} group The group name
     * @param {String} [hierarchy='sub'] How to add the object: 'global' for adding the the whole object as one, 'sub' for adding all meshes separately
     * @param {THREE.Vector3} [dragPlaneNormal] Optional normal to the plane which should be used for dragging. If not specified, dragging will happen in a plane normal to the viewing direction.
     * @returns {boolean}
     */
    addToInteractionGroup(path, group, hierarchy, dragPlaneNormal) {
      let scope = 'InteractionHandler.addToInteractionGroup';
      let obj3D = PATH_UTILS.getPathObject(_geometryNode, path + '');
      if (!obj3D) {
        _handlers.threeDManager.warn(scope, 'No valid path provided, interactivity was not set.');
        return false;
      }

      if (!group || !_handlers.interactionGroupManager.isInteractionGroup(group)) {
        _handlers.threeDManager.warn(scope, 'No valid group provided, interactivity was not set.');
        return false;
      }
      let g = _handlers.interactionGroupManager.getGroup(group);
      if (!g) return false;
      let interactable = g.isSelectable(group) ||
        g.isDraggable(group) ||
        g.isHoverable(group);

      // in case of 'global' hierarchy, the global object needs to have the interactionGroup set
      if (hierarchy === 'global')
        obj3D.interactionGroup = group;

      // traverse through sub-meshes, add them to the interaction group
      obj3D.traverse(function (object) {
        if (object.type == 'Mesh') {
          object.interactionGroup = group;
          
          if(object.parent.meshHelper === true){
            object.parent.interactionGroup = group;
            object.interactionPath = PATH_UTILS.getObjectPath(object.parent.parent);
            object.parent.interactionType = hierarchy === 'global' ? 'global' : 'sub';
          }

          if (hierarchy === 'global')
            object.interactionPath = path + '';

          if (interactable)
            _objects.push(object);
        }
      });

      // set plane normal for dragging
      if (dragPlaneNormal) {
        that.setInteractionPlaneNormal(path, dragPlaneNormal);
      }
      else {
        that.removeInteractionPlaneNormal(path);
      }

      _controls.setObjects(_objects);
      _handlers.highlightingHandler.setInteractable(_objects);
      return true;
    }

    /**
     * Removes the object from its interaction group.
     *
     * @param {String} path The path to the object
     */
    removeFromInteractions(path) {
      let scope = 'InteractionHandler.removeFromInteractionGroup';
      let obj3D = PATH_UTILS.getPathObject(_geometryNode, path + '');
      if (!obj3D) {
        _handlers.threeDManager.warn(scope, 'No valid path provided, interactivity was not removed.');
        return false;
      }

      obj3D.traverse(function (object) {

        let selected = _handlers.highlightingHandler.getSelected();
        let idx = -1;
        for (let i = 0; i < selected.length; i++) {
          if (object.interactionPath !== undefined) {
            if (object.interactionPath === selected[i].interactionPath)
              idx = i;
          } else {
            if (selected[i] == object)
              idx = i;
          }
        }
        if (idx !== -1) {
          _handlers.highlightingHandler.removeObject(object);
          let idmPrevSelection = {
            type: MESSAGING_CONSTANTS.messageTopics.SCENE_SELECT_OFF,
            obj: object,
            scenePath: PATH_UTILS.getObjectPath(object)
          };
          // send interaction data message via pubsub
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idmPrevSelection);
          _handlers.threeDManager.message(idmPrevSelection.type, m);

          // create event and dispatch it via dom
          that._dispatchEvent(idmPrevSelection);
        }

        if (object === _handlers.highlightingHandler.getHovered()) {
          _handlers.highlightingHandler.removeHovered();
          _handlers.highlightingHandler.removeObject(object);

          // create interaction data message
          let idm = {
            type: MESSAGING_CONSTANTS.messageTopics.SCENE_HOVER_OFF,
            viewportRuntimeId: _handlers.threeDManager.runtimeId,
            obj: object,
            scenePath: PATH_UTILS.getObjectPath(object),
          };

          // send interaction data message via pubsub
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
          _handlers.threeDManager.message(idm.type, m);

          //reset the rendering status
          _handlers.renderingHandler.unregisterForContinuousRendering(HOVER_IN_PROCESS_ID);

          // create event and dispatch it via dom
          that._dispatchEvent(idm);
        }

        if (object === _handlers.highlightingHandler.getDragged()) {
          _handlers.highlightingHandler.removeDragged();
          _handlers.highlightingHandler.removeObject(object);

          // get final position
          let endPosition;
          if (object.interactionPath !== undefined) {
            endPosition = _lastPosition;
          } else {
            endPosition = object.position;
          }

          let idm = {
            type: MESSAGING_CONSTANTS.messageTopics.SCENE_DRAG_END,
            viewportRuntimeId: _handlers.threeDManager.runtimeId,
            obj: object,
            scenePath: PATH_UTILS.getObjectPath(object),
            dragPosStart: _originalPosition.clone(),
            dragPosAbs: endPosition.clone(),
          };
          idm.dragPosRel = idm.dragPosAbs.clone().sub(idm.dragPosStart);

          // send interaction data message via pubsub
          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION, idm);
          _handlers.threeDManager.message(idm.type, m);

          // reset the rendering status
          _handlers.threeDManager.updateSetting('camera.enableOrbitControls', true);
          _handlers.renderingHandler.unregisterForContinuousRendering(INTERACTION_IN_PROCESS_ID);

          // create event and dispatch it via dom
          that._dispatchEvent(idm);
        }

        if (object.type == 'Mesh') {
          delete object.interactionGroup;
          delete object.interactionPath;

          var index = _objects.indexOf(object);
          if (index > -1)
            _objects.splice(index, 1);
        }
      });
      _controls.setObjects(_objects);
      _handlers.highlightingHandler.setInteractable(_objects);
      return true;
    }

    /**
     * Remove the interactivity from all objects.
     */
    removeAllInteractivity() {
      for (let i = 0, len = _objects.length; i < len; i++) {
        _handlers.highlightingHandler.removeObject(_objects[i]);
        delete _objects[i].interactionGroup;
        delete _objects[i].interactionPath;
      }
      _objects = [];

      _controls.setObjects(_objects);
      _handlers.highlightingHandler.setInteractable(_objects);
      return true;
    }

    /**
     * Selects and deselects objects in the scene.
     * Two optional arrays are given with the paths of the objects.
     *
     * For objects in with the global property the path to the global object path must be given.
     * For objects in with the sub property the path to the specific object path must be given.
     * @param {String[]} select
     * @param {String[]} deselect
     */
    setSelectedPaths(select, deselect) {
      let scope = 'InteractionHandler.setSelectedPaths';

      select = select || [];
      deselect = deselect || [];

      // get all the paths
      let selectedPaths = that.getSelectedPaths();
      let paths = [];
      let types = [];
      for (let i = 0, len = _objects.length; i < len; i++) {
        if (_objects[i].interactionPath !== undefined) {
          paths.push(_objects[i].interactionPath);
          types.push('global');
        } else {
          paths.push(PATH_UTILS.getObjectPath(_objects[i]));
          types.push('sub');
        }
      }

      for (let i = 0, len = select.length; i < len; i++) {
        if (GLOBAL_UTILS.typeCheck(select[i], 'string') !== true) {
          _handlers.threeDManager.warn(scope, 'At least one path is not a string.');
          return false;
        }
        if (!paths.includes(select[i])) {
          _handlers.threeDManager.warn(scope, 'At least one path is not interactable.');
          return false;
        }
      }

      for (let i = 0, len = deselect.length; i < len; i++) {
        if (GLOBAL_UTILS.typeCheck(deselect[i], 'string') !== true) {
          _handlers.threeDManager.warn(scope, 'At least one path is not a string.');
          return false;
        }
        if (!paths.includes(deselect[i])) {
          _handlers.threeDManager.warn(scope, 'At least one path is not interactable.');
          return false;
        }
      }

      for (let i = 0, len = deselect.length; i < len; i++) {
        // otherwise it is already deselected
        if (selectedPaths.includes(deselect[i])) {
          let obj3D = PATH_UTILS.getPathObject(_geometryNode, deselect[i] + '');
          obj3D.traverse(function (obj) {
            if (obj.type == 'Mesh')
              _handlers.highlightingHandler.removeSelected(obj);
          });
        }
      }

      selectedPaths = that.getSelectedPaths();

      for (let i = 0, len = select.length; i < len; i++) {
        // otherwise it is already selected
        if (!selectedPaths.includes(select[i])) {
          let obj3D = PATH_UTILS.getPathObject(_geometryNode, select[i] + '');
          obj3D.traverse(function (obj) {
            if (obj.type == 'Mesh') {
              let g = _handlers.interactionGroupManager.getGroup(obj.interactionGroup);
              // special treatement of objects in a group with single activated
              // to make sure only one object is selected at a time
              if (g.getSelectionMode() === 'single') {
                let currentSelected = _handlers.highlightingHandler.getSelected();
                for (let j = 0, len = currentSelected.length; j < len; j++) {
                  if (currentSelected[j].interactionGroup == obj.interactionGroup) {
                    _handlers.highlightingHandler.removeSelected(currentSelected[j]);
                    break;
                  }
                }
              }
              _handlers.highlightingHandler.setSelected(obj);
            }
          });
        }
      }

      _handlers.renderingHandler.render();
      return true;
    }

    /**
     * Returns the paths of all currently selected objects.
     *
     * @returns {String[]}
     */
    getSelectedPaths() {
      let currentSelected = _handlers.highlightingHandler.getSelected();
      let paths = [];
      for (let i = 0, len = currentSelected.length; i < len; i++) {
        if (currentSelected[i].interactionPath !== undefined) {
          paths.push(currentSelected[i].interactionPath + '');
        } else {
          paths.push(PATH_UTILS.getObjectPath(currentSelected[i]));
        }
      }
      return paths;
    }

    /**
     * Set the plane normal for the interaction.
     */
    setInteractionPlaneNormal(path, vec) {
      let scope = 'InteractionHandler.setInteractionPlaneNormal';
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (!obj) {
        _handlers.threeDManager.warn(scope, 'No valid path provided, interaction plane was not set.');
        return false;
      }
      obj.traverse(function (object) {
        if (object.type == 'Mesh') {
          object.interactionPlaneNormal = vec;
        }
      });
      return true;
    }

    /**
     * Removes the plane normal for the interaction.
     */
    removeInteractionPlaneNormal(path) {
      let scope = 'InteractionHandler.removeInteractionPlaneNormal';
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (!obj) {
        _handlers.threeDManager.warn(scope, 'No valid path provided, interaction plane was not removed.');
        return false;
      }
      obj.traverse(function (object) {
        if ((object.type == 'Mesh') && (object.hasOwnProperty('interactionPlaneNormal'))) {
          delete object.interactionPlaneNormal;
        }
      });
      return true;
    }
  }

  return new InteractionHandler(___settings);
};

module.exports = InteractionHandler;
