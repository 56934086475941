const APIResponse = require('../../api/v2/ApiResponse');
const LightApiInterface = new (require('../interfaces/api/LightApiInterface'))();
const CameraApiInterface = new (require('../interfaces/api/CameraApiInterface'))();

let ViewportManagerApi = function (viewportManager, _apis) {

  let callFunction = function (part, f, args) {
    let apis = null;
    if(!apis) 
      apis = _apis;

    let responses = [];
    for(let i = 0, len = apis.length; i < len; i++)
      responses.push(part ? apis[i][part][f](...args) : apis[i][f](...args));

    if (apis.length === 1) {
      responses = responses[0];
    } else if(apis.length > 1 && responses[0].constructor === APIResponse().constructor) {
      responses = APIResponse(responses.map(value => { return value.err; }), responses.map(value => { return value.data; }), responses.map(value => { return value.payload; }));
    } else if(apis.length > 1 && responses[0] instanceof Promise) {
      responses = Promise.all(responses).then(function(v) {
        return APIResponse(v.map(value => { return value.err; }), v.map(value => { return value.data; }), v.map(value => { return value.payload; }));
      });
    }

    return responses;
  };
  
  viewportManager.api = {
    getViewportRuntimeId: (...args) => { return callFunction(null, 'getViewportRuntimeId', args); },
    getContainer: (...args) => { for(let i = 0; i < _apis.length; i++) if(_apis[i].getViewportRuntimeId() === args[0]) { args.splice(0,1); return _apis[i].getContainer(...args);} return callFunction(null, 'getContainer', args); },
    getScreenshot: (...args) => { return callFunction(null, 'getScreenshot', args); },

    render: (...args) => { return callFunction(null, 'render', args); },
    updateShadowMap: (...args) => { return callFunction(null, 'updateShadowMap', args); },
    startContinuousRendering: (...args) => { return callFunction(null, 'startContinuousRendering', args); },
    stopContinuousRendering: (...args) => { return callFunction(null, 'stopContinuousRendering', args); },
    convertTo2D: (...args) => { return callFunction(null, 'convertTo2D', args); },

    pause: (...args) => { return callFunction(null, 'pause', args); },
    resume: (...args) => { return callFunction(null, 'resume', args); },

    addEventListener: (...args) => { /** TODO: GLOBAL */ },
    removeEventListener: (...args) => { /** TODO: GLOBAL */ },

    getSettingDefinitions: () => { return /* Same for all, just take from first one. */_apis[0].getSettingDefinitions(); },
    getSettings: (...args) => { return callFunction(null, 'getSettings', args); },
    getSetting: (...args) => { return callFunction(null, 'getSetting', args); },
    updateSettingAsync: (...args) => { return callFunction(null, 'updateSettingAsync', args); },
    updateSettingsAsync: (...args) => { return callFunction(null, 'updateSettingsAsync', args); },

    setTransformation: (...args) => { for(let i = 0; i < _apis.length; i++) if(_apis[i].getViewportRuntimeId() === args[0]) { args.splice(0,1); return _apis[i].setTransformation(...args);} return callFunction(null, 'setTransformation', args); },
    getTransformation: (...args) => { for(let i = 0; i < _apis.length; i++) if(_apis[i].getViewportRuntimeId() === args[0]) { args.splice(0,1); return _apis[i].getTransformation(...args);} return callFunction(null, 'getTransformation', args); },
    applyTransformation: (...args) => { for(let i = 0; i < _apis.length; i++) if(_apis[i].getViewportRuntimeId() === args[0]) { args.splice(0,1); return _apis[i].applyTransformation(...args);} return callFunction(null, 'applyTransformation', args); },
    resetTransformation: (...args) => { for(let i = 0; i < _apis.length; i++) if(_apis[i].getViewportRuntimeId() === args[0]) { args.splice(0,1); return _apis[i].resetTransformation(...args);} return callFunction(null, 'resetTransformation', args); },
    setLiveTransformation: (...args) => { for(let i = 0; i < _apis.length; i++) if(_apis[i].getViewportRuntimeId() === args[0]) { args.splice(0,1); return _apis[i].setLiveTransformation(...args);} return callFunction(null, 'setLiveTransformation', args); },

    updateSelected: (...args) => { return callFunction(null, 'updateSelected', args); },
    getSelected: (...args) => { return callFunction(null, 'getSelected', args); },
    toggleGeometry: (...args) => { /** TODO: GLOBAL */ }
  };

  viewportManager.api.camera = {
    get: (...args) => { return callFunction('camera', 'get', args); },
    updateAsync: (...args) => { return callFunction('camera', 'updateAsync', args); },
    animateAsync: (...args) => { return callFunction('camera', 'animateAsync', args); },
    zoomAsync: (...args) => { return callFunction('camera', 'zoomAsync', args); },
    resetAsync: (...args) => { return callFunction('camera', 'resetAsync', args); },
    updateMatrices: (...args) => { return callFunction('camera', 'updateMatrices', args); },
    TYPE: CameraApiInterface.TYPE
  };

  viewportManager.api.lights = {
    updateLight: (...args) => { return callFunction('lights', 'updateLight', args); },
    getLight: (...args) => { return callFunction('lights', 'getLight', args); },
    removeLight: (...args) => { return callFunction('lights', 'removeLight', args); },
    setLightSceneFromID: (...args) => { return callFunction('lights', 'setLightSceneFromID', args); },
    setLightSceneFromDefinition: (...args) => { return callFunction('lights', 'setLightSceneFromDefinition', args); },
    getLightScene: (...args) => { return callFunction('lights', 'getLightScene', args); },
    getAllLightScenes: (...args) => { return callFunction('lights', 'getAllLightScenes', args); },
    saveLightScene: (...args) => { return callFunction('lights', 'saveLightScene', args); },
    LIGHTTYPE: LightApiInterface.LIGHTTYPE,
    LIGHTSCENE: LightApiInterface.LIGHTSCENE
  };
};

module.exports = ViewportManagerApi;