/**
 * @file Creates a shared interface for all ViewportApis.
 *       The ViewportApiInterface must be implemented by all ViewportApis.
 *       All described methods must be implemented.
 * 
 * @module ViewportApiInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the ViewportApi.
 * The ViewportApi implements all functionality related to 3D.
 * 
 * //@interface ViewportApiInterface
 */
class ViewportApiInterface {
  /**
   * The constructor for the {@link module:ViewportApiInterface~ViewportApiInterface}.
   * @constructs module:ViewportApiInterface~ViewportApiInterface
   */
  constructor() {
    /**
     * The camera api for the corresponding viewport api.
     * 
     * @type {module:CameraApiInterface~CameraApiInterface}
     */
    this.camera = null;
  
    /**
     * The light api for the corresponding viewport api.
     * 
     * @type {module:LightApiInterface~LightApiInterface}
     */
    this.lights = null;
  }

  /**
   * Get the runtime id used for this API object.
   * The runtime id can optionally be specified as a setting when creating the API object, otherwise a random one will be created.
   * 
   * @abstract
   * @returns {String} runtime id used for API instance, should be unique
   */
  getViewportRuntimeId() { }

  /**
   * Returns the container in which the viewer is.
   * 
   * @abstract
   * @returns {HTMLElement|Object} the container of the viewer
   */
  getContainer() { }

  /**
   * Get screenshot as a png data URI.
   * Returns a screenshot of the viewer canvas without any control elements as a data URI using the png image format.
   *
   * @abstract
   * @returns {String} Data URI representing the png screeshot image (data:image/png;base64,...).
   */
  getScreenshot() { }




  /**
   * Render the scene.
   * 
   * @abstract
   */
  render() { }

  /**
   * Updates the shadow map if needed. Normally, all updates should be covered.
   */
  updateShadowMap() { }

  /** 
   * Starts continously rendering the scene until {@link module:ViewportApiInterface~ViewportApiInterface#stopContinuousRendering} is called.
   */
  startContinuousRendering() { }

  /** 
   * Stops continously rendering the scene if {@link module:ViewportApiInterface~ViewportApiInterface#startContinuousRendering} was called.
   */
  stopContinuousRendering() { }

  /**
   * Converts the given 3D coordinates into various 2D coordinates
   * @see {@link module:ApiInterfaceV2~ApiSceneInterface#2DCoordinateSummary}
   *
   * @abstract
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#Point3d} position - The 3D point to convert
   * @returns {module:ApiInterfaceV2~ApiSceneInterface#2DCoordinateSummary} A summary of the different 2D coordinates
   */
  convertTo2D(position) { }




  /**
   * Pause the rendering.
   *
   * @abstract
   */
  pause() { }

  /**
   * Resume the rendering.
   *
   * @abstract
   */
  resume() { }




  
  /**
   * Add a callback to be invoked when a certain event occurs
   *
   * @abstract
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ApiEventType} type - Type of event to subscribe to
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerCallback} callback - Function to be called when the event fires
   * @returns {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with an {@link module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken EventListenerToken} object. The event listener token can be used to remove the event listener by calling {@link module:ApiInterfaceV2~ApiInterfaceV2#removeEventListener removeEventListener}.
   */
  addEventListener(type, cb) { }

  /**
   * Remove an event listener
   *
   * @abstract
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#EventListenerToken} token - event listener token for event listener to be removed
   * @returns {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the event listener could be removed.
   */
  removeEventListener(token) { }






  
  /**
   * Provides an object describing all available settings
   *
   * @abstract
   * @return {Object} object describing all available settings, their type, and constraints
   */
  getSettingDefinitions() { }

  /**
   * Provides a copy of all or multiple settings
   * 
   * @abstract
   * @see {@link module:ViewportApiInterface~ViewportApiInterface#getSetting}
   * @see {@link module:ViewportApiInterface~ViewportApiInterface#updateSettingAsync}
   * @see {@link module:ViewportApiInterface~ViewportApiInterface#updateSettingsAsync}
   * @see {@link module:ViewportApiInterface~ViewportApiInterface#getSettingDefinitions}
   * @param {String[]} [keys] - optional array of keys, keys which don't exist are ignored, if omitted all settings will be returned
   * @returns {Object} object containing a copy of the settings
   */
  getSettings(keys) { }

  /**
   * Provides a copy of an individual setting
   *
   * @abstract
   * @param {String} key - name of setting (e.g. 'scene.render.shadows')
   * @returns {*} a copy of the setting, undefined if not found
   */
  getSetting(k) { }

  /**
   * Update an individual setting, if allowed.
   * @see {@link module:ViewportApiInterface~ViewportApiInterface#getSettingDefinitions}
   *
   * @abstract
   * @param {String} key - name of setting
   * @param {Object} val - new value of setting
   * @returns {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be true if setting could be changed, false if not.
   */
  updateSettingAsync(k, val) { }

  /**
   * Update a bunch of settings according to the enumerable properties of an object, if allowed.
   * 
   * @abstract
   * @param {Object} settings - object whose own properties should be used as settings
   * @returns {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} APIResponse whose data property will be set to a copy of the input object, whose property values are replaced by true or false depending on whether the corresponding setting could be updated.
   */
  updateSettingsAsync(settings) { }




  /**
   * Sets a 4x4 transformation matrix for this viewport. 
   * 
   * @abstract
   * @param {module:ApiInterfaceV2~ApiSceneInterface#Transformation|module:ApiInterfaceV2~ApiSceneInterface#Transformation[]} matrix - The transformation matrix or an array of transformation matrices
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type boolean.
   */
  setTransformation(matrix) { }

  /**
   * Returns the 4x4 transformation matrix or an array of them for this viewport. 
   * 
   * @abstract
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type {@link module:ApiInterfaceV2~ApiSceneInterface#Transformation matrix} or an array of {@link module:ApiInterfaceV2~ApiSceneInterface#Transformation matrices}.
   */
  getTransformation() { }
  
  /**
   * Applies a 4x4 transformation matrix or an array of transformation matrices to this viewport. 
   * 
   * @abstract
   * @param {module:ApiInterfaceV2~ApiSceneInterface#Transformation|module:ApiInterfaceV2~ApiSceneInterface#Transformation[]} matrix - The transformation matrix or an array of matrices
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type boolean.
   */
  applyTransformation(matrix) { }

  /**
   * Resets the 4x4 transformation matrix or the array of transformation matrices for this viewport. 
   *  
   * @abstract
   * @return {Promise<module:ApiInterfaceV2~ApiInterfaceV2#APIResponse>} API response with a data array of type boolean.
   */
  resetTransformation() { }

  /**
   * Set a live transformation for this viewport.
   * @see module:ApiInterfaceV2~ApiSceneInterface#setLiveTransformation
   * @abstract
   */
  setLiveTransformation(liveTransformations, duration) { }




  /**
   * Update selection status of objects.
   * 
   * @see module:ApiInterfaceV2~ApiSceneInterface#updateSelected
   * @abstract
   */
  updateSelected(select, deselect) { }

  /**
   * Get scene paths of selected objects.
   * 
   * @see module:ApiInterfaceV2~ApiSceneInterface#getSelected
   * @abstract
   */
  getSelected() { }

  
  /**
   * Show or hide geometry based on its scene path.
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [show] - Optional list of scene paths of objects which should be shown
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [hide] - Optional list of scene paths of objects which should be hidden
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the operation succeeded
   */
  toggleGeometry() { }
}

module.exports = ViewportApiInterface;