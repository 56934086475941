"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BaseSettingComponent = /** @class */ (function () {
    // #region Constructors (1)
    function BaseSettingComponent(_settingDefinition, _component) {
        this._settingDefinition = _settingDefinition;
        this._component = _component;
    }
    Object.defineProperty(BaseSettingComponent.prototype, "children", {
        // #endregion Constructors (1)
        // #region Public Accessors (9)
        get: function () {
            return this._component.children;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "groupId", {
        get: function () {
            return this._settingDefinition.groupId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "id", {
        get: function () {
            return this._settingDefinition.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "inputComponent", {
        get: function () {
            return this._component.inputComponent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "label", {
        get: function () {
            return this._component.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "order", {
        get: function () {
            return this._component.order;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "row", {
        get: function () {
            return this._component.row;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "size", {
        get: function () {
            return this._component.size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseSettingComponent.prototype, "type", {
        get: function () {
            return this._component.type;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (9)
    // #region Public Methods (1)
    BaseSettingComponent.prototype.destroy = function () {
        this._component.destroy();
    };
    return BaseSettingComponent;
}());
exports.BaseSettingComponent = BaseSettingComponent;
