/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *toTinyColor.js*
 *
 * ### Content
 *   * function to convert several types of string, number, and array color formats into a tinycolor object as provided by this library: https://www.npmjs.com/package/tinycolor2
 *
 * @module toTinyColor
 * @author Mathias Höbinger <mathias@shapediver.com>
 */

var tinycolor = require('tinycolor2');

// add a function to the prototype of tinycolor for conversion to THREE.Color
if (tinycolor.prototype.toThreeColor === undefined) {
  var THREE = require('../../externals/three');
  tinycolor.prototype.toThreeColor = function() {
    return new THREE.Color(this.toRgbString());
  };
  tinycolor.prototype.toArray = function() {
    return [this._r, this._g, this._b, 255 * this._a];
  };
}

require('../../polyfills/padStart');
require('../../polyfills/padEnd');

/**
 * Convert several types of string, number, and array color formats into a tinycolor object as provided by this library: https://www.npmjs.com/package/tinycolor2
 * In addition the following representations can be used:
 *   * Number[]: Array of 4 numbers in interval [0,255] (red,green,blue,alpha)
 *   * Number: Will be converted to a hex string representation and used as such, be careful when using this, ideally specify a 6 or 8 digit hex number
 *   * THREE.Color
 * This function is idempotent, it can be called on its own output and the value will stay the same.
 * @param {Object|Number|Number[]|String|THREE.Color} colorInput - input to convert to tinycolor object, may be an existing tinycolor object
 * @param {Number|String} [defaultColor] - optional default color to use, should colorInput not convert to a tinycolor object successfully. Caution: Validity of the defaultColor will not be checked.
 * @return {Object} null on error, tinycolor object as provided by https://www.npmjs.com/package/tinycolor2 on success
 */
var toTinyColor = function(colorInput, defaultColor) {

  // check if we already got a tinycolor instance
  if ( colorInput.hasOwnProperty('getOriginalInput') )
    return colorInput;

  // default color to return in case colorInput can not be parsed
  let defval = null;
  if (defaultColor) {
    defval = tinycolor(defaultColor); // we do not check validity of defval on purpose for now!!!
  }

  // check if we got a number
  if (typeof colorInput === 'number')
  {
    let cs = colorInput.toString(16);
    let cl = cs.length;
    if (cl < 3) cs = cs.padStart(3,0);
    else if (cl < 6) cs = cs.padStart(6,0);
    else if (cl < 8) cs = cs.padEnd(8,0);
    let tc = tinycolor(cs);
    return tc.isValid() ? tc : defval;
  }

  // check if the input is a THREE.Color
  if (colorInput.isColor && typeof colorInput.getHexString == 'function') {
    let tc = tinycolor(colorInput.getHexString());
    return tc.isValid() ? tc : defval;
  }

  // check for array of numbers
  if (Array.isArray(colorInput) && (colorInput.length == 3 || colorInput.length == 4)) {
    let isRGBArray = true;
    for (let i = 0; i < 3; ++i) {
      colorInput[i] = parseFloat(colorInput[i]);
      if (isNaN(colorInput[i])) {
        isRGBArray = false;
      }
    }
    if (!isRGBArray)
      return defval;

    let tc = tinycolor({
      r: Math.max(0, Math.min(colorInput[0], 255)),
      g: Math.max(0, Math.min(colorInput[1], 255)),
      b: Math.max(0, Math.min(colorInput[2], 255))
    });
    if (colorInput.length == 4) {
      let a = parseFloat(colorInput[3]);
      if (!isNaN(a)) {
        tc.setAlpha(Math.max(0, Math.min(a, 255)) / 255);
      }
    }
    return tc.isValid() ? tc : defval;
  }

  // if we got something other than a string, check if
  // tinyurl can work with it
  if (typeof colorInput !== 'string') {
    let tc = tinycolor(colorInput);
    return tc.isValid() ? tc : defval;
  }

  // tinyurl doesn't like 0x
  var tmpColor = colorInput.replace('0x', '#');

  // if we got no alpha value, add full opacity
  if (tmpColor.match(/^#[a-f0-9]{6}$/i) !== null) {
    let tc = tinycolor(tmpColor + 'ff');
    return tc.isValid() ? tc : defval;
  }

  // standard case
  if (tmpColor.match(/^#[a-f0-9]{8}$/i) !== null) {
    let tc = tinycolor(tmpColor);
    return tc.isValid() ? tc : defval;
  }

  // correct number which have the alpha value defined as a single hex digit
  if (tmpColor.match(/^#[a-f0-9]{7}$/i) !== null) {
    let tc = tinycolor(tmpColor.slice(0, 7) + '0' + tmpColor.slice(-1));
    return tc.isValid() ? tc : defval;
  }

  // check if tinyurl understands the string
  let tc = tinycolor(tmpColor);
  return tc.isValid() ? tc : defval;
};

module.exports = toTinyColor;
