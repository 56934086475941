"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sd_controls_components_slider_1 = require("../component-slider/sd-controls.components.slider");
var sd_controls_components_string_input_1 = require("../component-string/sd-controls.components.string-input");
var BooleanCom = require("../component-boolean/sd-controls.components.boolean-input");
var sd_controls_components_color_input_1 = require("../component-color/sd-controls.components.color-input");
var Button = require("../component-button/sd-controls.components.button-input");
var sd_controls_components_dropdown_input_1 = require("../component-dropdown/sd-controls.components.dropdown-input");
var Form = require("../component-checklist/sd-controls.components.checklist-menu");
var FileCom = require("../component-file/sd-controls.components.file");
var sd_controls_components_accordion_1 = require("../component-accordion/sd-controls.components.accordion");
var sd_controls_components_vector3_input_1 = require("../component-vector3/sd-controls.components.vector3-input");
var ModalInputMenuComponent_1 = require("../component-modal/ModalInputMenuComponent");
/**
 * A collection of the creation functions, with the keys being the names of the types. (Used for the assignment)
 */
var MenuElementConstructors = {
    slider: sd_controls_components_slider_1.SliderInputMenuComponent,
    string: sd_controls_components_string_input_1.StringInptMenuComponent,
    boolean: BooleanCom.BooleanInputMenuComponent,
    color: sd_controls_components_color_input_1.ColorInputMenuComponent,
    button: Button,
    dropdown: sd_controls_components_dropdown_input_1.DropdownInputMenuComponent,
    form: Form,
    file: FileCom,
    multiple: sd_controls_components_accordion_1.AccordionInputMenuComponent,
    vector3: sd_controls_components_vector3_input_1.Vector3InputMenuComponent,
    modal: ModalInputMenuComponent_1.ModalInputMenuComponent
    //date: _createCalenderMenu,
    //time: _createClockMenu
};
/**
 * Function constructor for a menu element.c
 * Create a row, creates the content of the row and assigns an update function.
 *
 * @param {TYPES} type The type of menu element that is created.
 * @param {Object} param The parameters that should be used for this menu element.
 *  They can vary widely for different element but must contain an id and name.
 *  The specific values needed can be seen in the createElement functions.
 * @param {Object} controlsContext
 * @param {DomElement} parentRow - optional
 */
function createMenuElement(type, param, controlsContext, parentRow) {
    if (parentRow === void 0) { parentRow = null; }
    if (!controlsContext.api) {
        throw new Error("Controls context needs to have api property.");
    }
    if (!controlsContext.container) {
        throw new Error("Controls context needs to have container property.");
    }
    if (!controlsContext.plugins) {
        throw new Error("Controls context needs to have plugins property.");
    }
    if (!controlsContext.runtimeId) {
        throw new Error("Controls context needs to have runtimeId property.");
    }
    var menuElement = new MenuElementConstructors[type](controlsContext.options && controlsContext.options.settings
        ? param.setting
        : param.id, type, param, controlsContext, parentRow);
    if (menuElement instanceof sd_controls_components_accordion_1.AccordionInputMenuComponent) {
        menuElement.createSubElements(createMenuElement);
    }
    controlsContext.controls.push(menuElement);
    return menuElement;
}
exports.createMenuElement = createMenuElement;
