"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var ParameterType_1 = require("../../../../modules/enums/controls/ParameterType");
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var SDFile = /** @class */ (function (_super) {
    __extends(SDFile, _super);
    function SDFile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SDFile.prototype, "sdtype", {
        // #endregion Properties (1)
        // #region Public Accessors (2)
        get: function () {
            return this._type;
        },
        set: function (val) {
            this._type = val;
        },
        enumerable: true,
        configurable: true
    });
    return SDFile;
}(File));
var FileCustomComponent = /** @class */ (function (_super) {
    __extends(FileCustomComponent, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function FileCustomComponent(id, parameterDefinition, parent, domManager, _fileHelper, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, ParameterType_1.ParameterType.FILE, id, parameterDefinition, parent, domManager, editMode) || this;
        _this._fileHelper = _fileHelper;
        _this._inputId = _this.id + "-button";
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(FileCustomComponent.prototype, "size", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return interfaces_1.ControlElementSize.QTR_WIDTH;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Protected Methods (3)
    FileCustomComponent.prototype.createInput = function () {
        this._inputComponent = this._domManager.createDomElement("button", this._inputId, this._inputContainer, ['uk-button', 'uk-button-default', 'uk-button-small', 'uk-width-expand', 'text-transform-none', 'uk-padding-remove', 'sd-control-button'], { type: 'button', name: this._inputId });
        this._inputComponent.innerHTML = 'Select file...';
        var fileInputId = this._id + "-file";
        this._fileInput = this._domManager.createDomElement("input", fileInputId, this._inputContainer, [], { type: 'file', name: fileInputId });
        this._fileInput.style.display = 'none';
        this._fileInput.multiple = true;
        if (this._parameterDefinition.accept) {
            var accept = this._parameterDefinition.accept;
            if (typeof accept === "string") {
                accept = [accept];
            }
            this._fileInput.accept = accept.join(',');
        }
    };
    FileCustomComponent.prototype.createInputEvents = function () {
        var _this = this;
        this._domManager.setDomEventListener(this._inputComponent, "click", 10, function () { return _this._fileInput.click(); });
        this._domManager.setDomEventListener(this._fileInput, "change", 10, this.update.bind(this));
        this.inputComponent.addEventListener('dragenter', this.dragHandler, false);
        this.inputComponent.addEventListener('dragover', this.dragHandler, false);
        this.inputComponent.addEventListener('drop', this.dropHandler, false);
    };
    FileCustomComponent.prototype.update = function (v, event) {
        this._parameterDefinition.update(this._fileInput.files);
    };
    // #endregion Protected Methods (3)
    // #region Private Methods (2)
    FileCustomComponent.prototype.dragHandler = function (e) {
        e.stopPropagation();
        e.preventDefault();
    };
    FileCustomComponent.prototype.dropHandler = function (e) {
        this.dragHandler(e);
        if (e.dataTransfer) {
            this._fileList = e.dataTransfer.files;
        }
    };
    return FileCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.FileCustomComponent = FileCustomComponent;
