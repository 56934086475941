"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var sd_controls_components_dom_dom_util_1 = require("../components/dom/sd-controls.components.dom.dom-util");
var enums_1 = require("../enums/enums");
var Icon = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function Icon(icon) {
        this.html = "<a><span uk-icon=\"" + icon + "\"></span></a>";
    }
    // #endregion Constructors (1)
    // #region Public Methods (1)
    Icon.prototype.toString = function () {
        return this.html;
    };
    return Icon;
}());
var groupSettings = [
    {
        groupId: enums_1.SettingsGroups.WORLD,
        icon: new Icon("world")
    },
    {
        groupId: enums_1.SettingsGroups.LIGHTS,
        icon: new Icon("bolt")
    },
    {
        groupId: enums_1.SettingsGroups.BACKGROUND,
        icon: new Icon("image")
    },
    {
        groupId: enums_1.SettingsGroups.CAMERA,
        icon: new Icon("video-camera")
    }
];
var GroupSetings = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function GroupSetings(parentEl) {
        var _this = this;
        // #region Properties (1)
        this.groups = {};
        var switchUlElem = sd_controls_components_dom_dom_util_1.createDomElement("ul", {
            parent: parentEl,
            classList: [
                "uk-margin-remove",
                "uk-width-1-1",
                "sdv-control-panel-row-border",
                "shapediver-settings-tabs"
            ],
            attributes: { "uk-tab": "swiping: false;" }
        });
        var contentUlElem = sd_controls_components_dom_dom_util_1.createDomElement("ul", {
            parent: parentEl,
            classList: ["uk-switcher", "uk-width-1-1", "uk-margin-remove-top"]
        });
        groupSettings.forEach(function (gs) {
            var li = sd_controls_components_dom_dom_util_1.createDomElement("li", {
                parent: switchUlElem,
                classList: ["uk-padding-remove", "uk-width-1-4"],
                innerHTML: gs.icon.toString()
            });
            var contentLi = sd_controls_components_dom_dom_util_1.createDomElement("li", {
                parent: contentUlElem,
                attributes: { "uk-grid": "" },
                classList: ["uk-width-1-1", "uk-margin-remove"]
            });
            _this.groups[gs.groupId] = {
                contentEl: contentLi,
                headerEl: li,
                id: gs.groupId
            };
        });
    }
    // #endregion Constructors (1)
    // #region Public Methods (2)
    GroupSetings.prototype.getGroup = function (id) {
        return this.groups[id].contentEl;
    };
    GroupSetings.prototype.headersClick = function (callback) {
        var _this = this;
        var _loop_1 = function (i) {
            this_1.groups[i].headerEl.addEventListener('click', function () { return callback(_this.groups[i].id); });
        };
        var this_1 = this;
        for (var i in this.groups) {
            _loop_1(i);
        }
    };
    return GroupSetings;
}());
exports.GroupSetings = GroupSetings;
