"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ParameterType_1 = require("../../../../modules/enums/controls/ParameterType");
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var StringInputCustomComponent = /** @class */ (function (_super) {
    __extends(StringInputCustomComponent, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function StringInputCustomComponent(id, parameterDefinition, parent, domManager, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, ParameterType_1.ParameterType.STRING, id, parameterDefinition, parent, domManager, editMode) || this;
        // #region Properties (3)
        _this._inputAttributes = { type: 'text' };
        _this._inputClassList = ['uk-input', 'uk-form-small'];
        _this._inputId = id + "-text";
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(StringInputCustomComponent.prototype, "size", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return interfaces_1.ControlElementSize.QTR_WIDTH;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Protected Methods (3)
    StringInputCustomComponent.prototype.createInput = function () {
        this._inputAttributes['name'] = this._inputId + "-text";
        this._inputComponent = this._domManager.createDomElement('input', this._inputId, this._inputContainer, this._inputClassList, this._inputAttributes);
        if (this._parameterDefinition.max) {
            this._inputComponent.maxLength = this._parameterDefinition.max;
        }
        if (this._parameterDefinition.value) {
            this._inputComponent.value = this._parameterDefinition.value;
        }
    };
    StringInputCustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._inputComponent, "input", 1000, this.update.bind(this));
    };
    StringInputCustomComponent.prototype.update = function (v, event) {
        this._parameterDefinition.update(v);
    };
    return StringInputCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.StringInputCustomComponent = StringInputCustomComponent;
