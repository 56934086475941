// export modal dialog using uikit

let UIkitExportModal = function (api, options_) {

  // sanity check
  if (!api) return null;
  options_ = options_ || {};
  if (!options_.domElementIdPrefix) options_.domElementIdPrefix = 'sdv-container-modal-export';
  if (!options_.parentElement) options_.parentElement = document.getElementById("sdv-container") ? document.getElementById("sdv-container") : document.body;
  if (!options_.UIkitOpts) options_.UIkitOpts = { 'escClose': true, 'bgClose': false, 'container': document.getElementById("sdv-container") ? '#sdv-container' : false  };

  // check for UIkit
  if (!window.UIkit) return null;

  //
  let _destroy,
    _modal,
    _modalElement,
    _titleElement,
    _promptElement,
    _buttonCancelElement,
    _buttonOkElement,
    _statusCounter = 0,
    _lastExportToken,
    _ignoredTokens = [],
    _buttonOkHref,
    _shown = false,
    _parentElement = options_.parentElement,
    _domElementIdPrefix = options_.domElementIdPrefix,
    _messageSubscriptionTokens = [],
    _tmp;

  // create elements for modal
  let _createModalElements = function (idPrefix_) {
    // modal dialog element
    _modalElement = document.createElement('div');
    _modalElement['id'] = idPrefix_;
    // body element
    let bodyElement = document.createElement('div');
    bodyElement.classList.add('uk-modal-dialog');
    bodyElement.classList.add('uk-modal-body');
    _modalElement.appendChild(bodyElement);
    // heading
    _titleElement = document.createElement('h2');
    _titleElement['id'] = idPrefix_ + '-title';
    _titleElement.classList.add('uk-modal-title');
    bodyElement.appendChild(_titleElement);
    // prompt element
    _promptElement = document.createElement('p');
    _promptElement['id'] = idPrefix_ + '-prompt';
    bodyElement.appendChild(_promptElement);
    // right alignment
    let rightElement = document.createElement('p');
    rightElement.classList.add('uk-text-right');
    bodyElement.appendChild(rightElement);
    // buttons
    _buttonCancelElement = document.createElement('h2');
    _buttonCancelElement['id'] = idPrefix_ + '-button-cancel';
    _buttonCancelElement['type'] = 'button';
    _buttonCancelElement.classList.add('uk-button');
    _buttonCancelElement.classList.add('uk-button-default');
    _buttonCancelElement.classList.add('uk-modal-close');
    rightElement.appendChild(_buttonCancelElement);
    _buttonOkElement = document.createElement('h2');
    _buttonOkElement['id'] = idPrefix_ + '-button-ok';
    _buttonOkElement['type'] = 'button';
    _buttonOkElement.classList.add('uk-button');
    _buttonOkElement.classList.add('uk-button-primary');
    rightElement.appendChild(_buttonOkElement);

    // append to parent
    _parentElement.appendChild(_modalElement);

    return _modalElement;
  };
  _createModalElements(_domElementIdPrefix);

  // create UIkit modal
  _modal = window.UIkit.modal('#' + _domElementIdPrefix, options_.UIkitOpts);

  // update the dialog using export event data
  let _updateDialog = function (event) {

    if (!event.export) return false;
    let e = event.export;

    // check if token should be ignored
    if (!event.token || !event.token.id) return false;

    // should the token be ignored?
    if (_ignoredTokens.includes(event.token.id)) return false;

    // should the modal dialog be avoided?
    if (event.token.attributes && event.token.attributes.silent) return false;

    // in case the modal dialog is active currently, ignore messages that report a different token
    if (_shown && _lastExportToken !== event.token.id) return false;

    // remember latest export token
    _lastExportToken = event.token.id;

    // title
    if (e.name) {
      _titleElement.innerHTML = 'Export "' + e.name + '"';
    }
    else {
      _titleElement.innerHTML = 'Export';
    }

    // description
    if (e.content || e.result) {
      // description - export finished

      // download
      if (e.type === 'download') {
        if (e.filename && e.content.length > 0 && e.content[0].href) {
          _promptElement.innerHTML = 'File "' + e.filename + '" is available for download.';
        }
        else if (e.msg) {
          _promptElement.innerHTML = e.msg;
        }
        else {
          _promptElement.innerHTML = 'File is available for download.';
        }
        if (e.content && e.content.length > 0) {
          let c0 = e.content[0];
          if (c0.size) {
            _promptElement.innerHTML += '<br/>Download size: ' + c0.size + ' bytes';
          }
          if (c0.format) {
            _promptElement.innerHTML += '<br/>File format: ' + c0.format;
          }
        }
      }
      // email
      else if (e.type === 'email') {
        if (e.result.err) {
          _promptElement.innerHTML = e.result.err;
        }
        else if (e.result.msg) {
          _promptElement.innerHTML = e.result.msg;
        }
        else {
          _promptElement.innerHTML = 'Export has been finished.';
        }
      }
      // shapeways
      else if (e.type === 'shapeways') {
        if (e.result.err) {
          _promptElement.innerHTML = e.result.err;
        }
        else if (e.result.msg) {
          _promptElement.innerHTML = e.result.msg;
        }
        else {
          _promptElement.innerHTML = 'Export to ShapeWays has been finished.';
        }
      }
      else {
        _promptElement.innerHTML = 'Export has been finished.';
      }
    }
    else {
      // description - export still processing
      let s = 'Processing, please wait...';
      // use status message if any
      if (e.msg) s = e.msg;
      for (let i = 1; i <= _statusCounter; i++) s += '.';
      _promptElement.innerHTML = s;
      _statusCounter = (_statusCounter + 1) % 4;
    }

    // buttons
    if (e.content || e.result) {
      // buttons - export finished
      _buttonCancelElement.innerHTML = 'Cancel';
      if (e.type === 'download') {
        if (e.content.length > 0 && e.content[0].href) {
          _buttonOkElement.innerHTML = 'Download';
          _buttonOkHref = e.content[0].href;
        }
      }
      else if (e.type === 'shapeways') {
        if (e.result.err) {
          _buttonOkElement.innerHTML = 'Ok';
        }
        else if (e.result.href) {
          _buttonOkElement.innerHTML = 'Go to ShapeWays';
          _buttonOkHref = e.result.href;
        }
      }
      else { // email
        _buttonOkElement.innerHTML = 'Ok';
      }
      _buttonOkElement.hidden = !(_buttonOkElement.innerHTML && _buttonOkElement.innerHTML.length > 0);
    }
    else {
      // buttons - export still processing
      _buttonCancelElement.innerHTML = 'Cancel';
      _buttonOkHref = '';
      _buttonOkElement.innerHTML = '';
      _buttonOkElement.hidden = true;
    }

    // ok, show dialog
    return true;
  };

  // attach event listeners to API

  // attach event listeners to API - export status events
  _tmp = api.exports.addEventListener(api.exports.EVENTTYPE.STATUS, function (event) {
    //console.log('EXPORT STATUS',event);
    // update the dialog
    // ignore event if it belongs to an export which has been cancelled by the user
    if (_updateDialog(event)) {
      // show the dialog
      _modal.show();
      _shown = true;
    }
  });
  if (_tmp.data) _messageSubscriptionTokens.push(_tmp.data);

  // attach event listeners to API - export available events
  _tmp = api.exports.addEventListener(api.exports.EVENTTYPE.AVAILABLE, function (event) {
    //console.log('EXPORT AVAILABLE',event);
    // update the dialog
    // ignore event if it belongs to an export which has been cancelled by the user
    if (_updateDialog(event)) {
      // show the dialog
      _modal.show();
      _shown = true;
    }
  });
  if (_tmp.data) _messageSubscriptionTokens.push(_tmp.data);

  // cancel button click events
  _buttonCancelElement.onclick = function () {
    // ignore last token
    if (_lastExportToken) _ignoredTokens.push(_lastExportToken);
    _lastExportToken = null;
    // hide the dialog
    _modal.hide();
    _shown = false;
  };

  // ok button click events
  _buttonOkElement.onclick = function () {
    // ignore last token
    if (_lastExportToken) _ignoredTokens.push(_lastExportToken);
    _lastExportToken = null;
    // open href in new tab if there is one
    if (_buttonOkHref) {
      window.open(_buttonOkHref, '_blank');
      _buttonOkHref = '';
    }
    // hide the dialog
    _modal.hide();
    _shown = false;
  };

  // return object allowing to control the modal
  _destroy = function () {
    _buttonOkElement.onclick = null;
    _buttonCancelElement.onclick = null;
    _parentElement.removeChild(_modalElement);
    _messageSubscriptionTokens.forEach((t) => (api.export.removeEventListener(t)));
  };

  return {
    destroy: _destroy,
  };
};

module.exports = UIkitExportModal;
