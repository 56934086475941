"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var ShapeDiverRowSizeDoesNotExists = /** @class */ (function (_super) {
    __extends(ShapeDiverRowSizeDoesNotExists, _super);
    // #region Constructors (1)
    function ShapeDiverRowSizeDoesNotExists(rowSize) {
        return _super.call(this, "Row size " + rowSize + " does not exist. Possible sizes are \"1,2,4\"") || this;
    }
    return ShapeDiverRowSizeDoesNotExists;
}(Error));
/**
 * Private class used to resolve the rows.
 */
var Rows = /** @class */ (function () {
    function Rows() {
        // #region Properties (4)
        this._elements = [];
        this._currentRow = [];
        this._spaceTaken = 0;
        // #endregion Private Methods (1)
    }
    Object.defineProperty(Rows.prototype, "isFull", {
        // #endregion Properties (4)
        // #region Private Accessors (2)
        get: function () {
            return this.rowSize >= Rows.MAX_SIZE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Rows.prototype, "rowSize", {
        get: function () {
            var usedSpace = 0;
            for (var _i = 0, _a = this._currentRow; _i < _a.length; _i++) {
                var tuple = _a[_i];
                usedSpace += tuple[1];
            }
            return usedSpace;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Private Accessors (2)
    // #region Public Methods (2)
    /**
     * Adds element to rows.
     * @param { IResizableComponent } elem - element to add
     * @param { number } spaceReq - space required
     */
    Rows.prototype.addElement = function (elem, spaceReq) {
        if (this._spaceTaken + spaceReq > Rows.MAX_SIZE) {
            this.resolveRow();
            this._currentRow = [];
            this._spaceTaken = 0;
        }
        this._spaceTaken += spaceReq;
        this._currentRow.push([elem, spaceReq]);
    };
    /**
     * Finalizes rows and returns final result of all added rows with their final sizes.
     * @returns { [IResizableComponent, number][] } finalizes and retruns final rows and sizes.
     */
    Rows.prototype.finalize = function () {
        if (this.isFull) {
            var last = this._currentRow.pop();
            this.resolveRow();
            this._currentRow = [last];
            this.resolveRow();
        }
        else {
            this.resolveRow();
        }
        return this._elements;
    };
    // #endregion Public Methods (2)
    // #region Private Methods (1)
    Rows.prototype.resolveRow = function () {
        var _this = this;
        if (!this.isFull) {
            // first expand 1 elems
            for (var i = 0; i < this._currentRow.length; i++) {
                var tuple = this._currentRow[i];
                if (tuple[1] == interfaces_1.ControlElementSize.QTR_WIDTH) {
                    tuple[1] = interfaces_1.ControlElementSize.HALF_WIDTH;
                    if (this.isFull) {
                        break;
                    }
                }
            }
            // expand 2 elems
            if (!this.isFull) {
                for (var i = 0; i < this._currentRow.length; i++) {
                    var tuple = this._currentRow[i];
                    if (tuple[1] == interfaces_1.ControlElementSize.HALF_WIDTH) {
                        tuple[1] = interfaces_1.ControlElementSize.FULL_WIDTH;
                        if (this.isFull) {
                            break;
                        }
                    }
                }
            }
        }
        this._currentRow.forEach(function (x) { return _this._elements.push(x); });
        this._currentRow = [];
    };
    Rows.MAX_SIZE = 4;
    return Rows;
}());
var SDControlsResizeManager = /** @class */ (function () {
    // #endregion Properties (4)
    // #region Constructors (1)
    function SDControlsResizeManager(_isMobileDevice) {
        var _a;
        this._isMobileDevice = _isMobileDevice;
        this._possibleClasses = {
            fullWidth: ["uk-width-1-1", "sd-max-width-4"],
            quarter: ["uk-width-1-4", "sd-max-width-1"],
            half: ["uk-width-1-2", "sd-max-width-2"],
            all: ["uk-width-1-1", "uk-width-1-2", "uk-width-1-4", "uk-width-expand", "sd-max-width-1", "sd-max-width-2", "sd-max-width-4"],
        };
        this._spacesRequiredMap = (_a = {},
            _a[interfaces_1.ControlElementSize.QTR_WIDTH] = 1,
            _a[interfaces_1.ControlElementSize.HALF_WIDTH] = 2,
            _a[interfaces_1.ControlElementSize.FULL_WIDTH] = 4,
            _a);
        this._isClientSizeSmall = false;
    }
    // #endregion Constructors (1)
    // #region Public Methods (1)
    SDControlsResizeManager.prototype.resize = function (elements, mode, clientWidth) {
        var useFullRowWidth = mode === interfaces_1.ViewerMode.LANDSCAPE && !this._isMobileDevice;
        this.adjustSpaceRequiredAccordingToClientWidth(clientWidth);
        this.resizeHandle(elements, useFullRowWidth);
    };
    // #endregion Public Methods (1)
    // #region Private Methods (5)
    SDControlsResizeManager.prototype.addClassesToElem = function (elem, classes) {
        // remove every
        this._possibleClasses.all.forEach(function (cls) {
            if (elem.row.classList.contains(cls)) {
                elem.row.classList.remove(cls);
            }
        });
        // add full row classes
        classes.forEach(function (cls) { return elem.row.classList.add(cls); });
    };
    SDControlsResizeManager.prototype.adjustSpaceRequiredAccordingToClientWidth = function (clientWidth) {
        this._isClientSizeSmall = clientWidth < SDControlsResizeManager.CLIENT_WIDTH_ADJUST_SIZE;
        // for small client sizes expand elements.
        this._spacesRequiredMap[interfaces_1.ControlElementSize.HALF_WIDTH] = this._isClientSizeSmall ? 4 : 2;
        this._spacesRequiredMap[interfaces_1.ControlElementSize.QTR_WIDTH] = this._isClientSizeSmall ? 2 : 1;
    };
    SDControlsResizeManager.prototype.handleFullWidth = function (elements) {
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var elem = elements_1[_i];
            this.addClassesToElem(elem, this._possibleClasses.fullWidth);
        }
    };
    SDControlsResizeManager.prototype.handleResizable = function (elements) {
        var _loop_1 = function (elem) {
            this_1._possibleClasses.all.forEach(function (cls) { return elem.row.classList.remove(cls); });
        };
        var this_1 = this;
        // first remove every.
        for (var _i = 0, elements_2 = elements; _i < elements_2.length; _i++) {
            var elem = elements_2[_i];
            _loop_1(elem);
        }
        var rows = new Rows();
        for (var _a = 0, elements_3 = elements; _a < elements_3.length; _a++) {
            var elem = elements_3[_a];
            rows.addElement(elem, this._spacesRequiredMap[elem.size]);
        }
        var elementSizeTuples = rows.finalize();
        for (var _b = 0, elementSizeTuples_1 = elementSizeTuples; _b < elementSizeTuples_1.length; _b++) {
            var tuple = elementSizeTuples_1[_b];
            switch (tuple[1]) {
                case 1:
                    this.addClassesToElem(tuple[0], this._possibleClasses.quarter);
                    break;
                case 2:
                    this.addClassesToElem(tuple[0], this._possibleClasses.half);
                    break;
                case 4:
                    this.addClassesToElem(tuple[0], this._possibleClasses.fullWidth);
                    break;
                default:
                    throw new ShapeDiverRowSizeDoesNotExists(tuple[1]);
            }
        }
    };
    SDControlsResizeManager.prototype.resizeHandle = function (elements, useFullRowWidth) {
        useFullRowWidth ? this.handleFullWidth(elements) : this.handleResizable(elements);
        // if elements have children, resize children as well. This is in case of modal.
        for (var _i = 0, elements_4 = elements; _i < elements_4.length; _i++) {
            var elem = elements_4[_i];
            this.resizeHandle(elem.children, useFullRowWidth);
        }
    };
    // #region Properties (4)
    SDControlsResizeManager.CLIENT_WIDTH_ADJUST_SIZE = 640;
    return SDControlsResizeManager;
}());
exports.SDControlsResizeManager = SDControlsResizeManager;
