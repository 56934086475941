/**
 * @file The default cameraHandler. Handles all functionality related to the camera.
 *
 * @module CameraHandlerDefault
 * @author Michael Oppitz
 */

let CameraHandler = function (___settings, ___handlers) {
  const THREE = require('../../../externals/three'),
        TWEEN = require('@tweenjs/tween.js'),
        GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        CameraHandlerInterface = require('../../interfaces/handlers/CameraHandlerInterface'),
        THREE_D_MANAGER_CONSTANTS = require('../ThreeDManagerConstants'),
        DETECT_IT = (require('detect-it')).default,
        CAMERA_MOVING_ID = 'cameraMoving',
        TWEEN_ID = 'tweenID',
        _settings = ___settings.settings,
        _geometryNode = ___settings.geometryNode,
        _container = ___settings.container,
        _handlers = ___handlers;

  let that,
      _camera, _orbitControls, p, t,
      _perspectiveCamera, _perspectiveOrbitControls,
      _orthographicCamera, _orthographicOrbitControls,
      _sceneBS = new THREE.Sphere(),
      _cameraMatrix = new THREE.Matrix4(),
      _active = true;

  ////////////
  ////////////
  //
  // the hooks for the settings go below
  //
  ////////////
  ////////////

  /**
   * Sets the FOV of the camera.
   *
   * @param {Number} value The new FOV value
   */
  let _fovHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'notnegative', _handlers.threeDManager.warn, 'CameraHandler.Hook->fov')) return false;

    if (_perspectiveCamera.fov > 10 && value < 10) {
      _perspectiveCamera.near *= 100 * value;
      _perspectiveCamera.far *= 100 * value;
    } else if (_perspectiveCamera.fov < 10 && value > 10) {
      _perspectiveCamera.near /= 100 * _perspectiveCamera.fov;
      _perspectiveCamera.far /= 100 * _perspectiveCamera.fov;
    }
    _perspectiveCamera.fov = value;

    _perspectiveCamera.updateProjectionMatrix();
    _handlers.renderingHandler.render();
    return true;
  };


  /**
   * Sets the camera position of the perspective camera.
   *
   * @param {THREE.Vector3} value The new position
   * @return {Promise<Boolean>} Resolves once the camera has been set to the requested loaction
   */
  let _perspectivePositionHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'vector3obj', _handlers.threeDManager.warn, 'CameraHandler.Hook->defaults.perspective.position')) return Promise.resolve(false);
    return Promise.resolve(true);
    // Alex to Michael: I think it's more clear if a settings update of the default camera position
    //   keeps the current position, but updates the setting only
    //   resetCamera* can be used if wanted
    // What would make sense: In case the perspective camera has not been used yet in this session, and
    //  this hook gets called, then we should update the so far unused perspective camera according to the given value.
    // return _camera instanceof THREE.PerspectiveCamera ?
    //   _out.setPositionAndTarget(value, _settings.getSetting('defaults.perspective.target'), {duration: _settings.getSetting('cameraMovementDuration')}) :
    //   Promise.resolve(true);
  };


  /**
   * Sets the camera target of the perspective camera.
   *
   * @param {THREE.Vector3} value The new target
   * @return {Promise<Boolean>} Resolves once the camera has been set to the requested loaction
   */
  let _perspectiveTargetHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'vector3obj', _handlers.threeDManager.warn, 'CameraHandler.Hook->defaults.perspective.target')) return Promise.resolve(false);
    return Promise.resolve(true);
    // Alex to Michael: I think it's more clear if a settings update of the default camera position
    //   keeps the current position, but updates the setting only
    //   resetCamera* can be used if wanted
    // What would make sense: In case the perspective camera has not been used yet in this session, and
    //  this hook gets called, then we should update the so far unused perspective camera according to the given value.
    // return _camera instanceof THREE.PerspectiveCamera ?
    //   _out.setPositionAndTarget(_settings.getSetting('defaults.perspective.position'), value, {duration: _settings.getSetting('cameraMovementDuration')}) :
    //   Promise.resolve(true);
  };

  /**
   * Sets the perspective camera.
   *
   * @param {Object} value The new position and targer
   * @return {Promise<Boolean>} Resolves once the camera has been set to the requested loaction
   */
  let _perspectiveHook = function (value) {
    let scope = 'CameraHandler.Hook->defaults.perspective';
    if (!(GLOBAL_UTILS.typeCheck(value.position, 'vector3obj', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.target, 'vector3obj', _handlers.threeDManager.warn, scope)))
      return Promise.resolve(false);
    // Alex to Michael: Here we keep updating the camera when the default configuration gets changed,
    // because this hook is used by the SettingsHandler only (not available in API v2)
    // We use duration 0 because there is no need for a camera transition when setting the initial camera position from the SettingsHandler
    return _camera instanceof THREE.PerspectiveCamera ?
      that.setPositionAndTarget(value.position, value.target, { duration: 0 }) :
      Promise.resolve(true);
  };

  /**
   * Sets the camera position of the orthographic camera.
   *
   * @param {THREE.Vector3} value The new position
   * @return {Promise<Boolean>} Resolves once the camera has been set to the requested loaction
   */
  let _orthographicPositionHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'vector3obj', _handlers.threeDManager.warn, 'CameraHandler.Hook->defaults.orthographic.position')) return Promise.resolve(false);
    return Promise.resolve(true);
    // Alex to Michael: I think it's more clear if a settings update of the default camera position
    //   keeps the current position, but updates the setting only
    //   resetCamera* can be used if wanted
    // What would make sense: In case the orthographic camera has not been used yet in this session, and
    //  this hook gets called, then we should update the so far unused orthographic camera according to the given value.
    // return _camera instanceof THREE.OrthographicCamera ?
    //   _out.setPositionAndTarget(value, _settings.getSetting('defaults.orthographic.target'), {duration: _settings.getSetting('cameraMovementDuration')}) :
    //   Promise.resolve(true);
  };

  /**
   * Sets the camera target of the orthographic camera.
   *
   * @param {THREE.Vector3} value The new target
   * @return {Promise<Boolean>} Resolves once the camera has been set to the requested loaction
   */
  let _orthographicTargetHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'vector3obj', _handlers.threeDManager.warn, 'CameraHandler.Hook->defaults.orthographic.target')) return Promise.resolve(false);
    return Promise.resolve(true);
    // Alex to Michael: I think it's more clear if a settings update of the default camera position
    //   keeps the current position, but updates the setting only
    //   resetCamera* can be used if wanted
    // What would make sense: In case the orthographic camera has not been used yet in this session, and
    //  this hook gets called, then we should update the so far unused orthographic camera according to the given value.
    // return _camera instanceof THREE.OrthographicCamera ?
    //   _out.setPositionAndTarget(_settings.getSetting('defaults.orthographic.position'), value, {duration: _settings.getSetting('cameraMovementDuration')}) :
    //   Promise.resolve(true);
  };

  /**
   * Sets the orthographic camera.
   *
   * @param {Object} value The new position and targer
   * @return {Promise<Boolean>} Resolves once the camera has been set to the requested loaction
   */
  let _orthographicHook = function (value) {
    let scope = 'CameraHandler.Hook->defaults.orthographic';
    if (!(GLOBAL_UTILS.typeCheck(value.position, 'vector3obj', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.target, 'vector3obj', _handlers.threeDManager.warn, scope)))
      return Promise.resolve(false);
    // Alex to Michael: Here we keep updating the camera when the default configuration gets changed,
    // because this hook is used by the SettingsHandler only (not available in API v2)
    return _camera instanceof THREE.OrthographicCamera ?
      that.setPositionAndTarget(value.position, value.target, { duration: _settings.getSetting('cameraMovementDuration') }) :
      Promise.resolve(true);
  };

  /**
   * Sets the camera positions and targets.
   *
   * @param {Object} value the positions and targets of the cameras
   * @return {Promise<Boolean>} Resolves to true once the camera has been set to the requested location
   */
  let _defaultsHook = function (value) {
    let scope = 'CameraHandler.Hook->defaults';
    if (!value.perspective ||
      !(GLOBAL_UTILS.typeCheck(value.perspective.position, 'vector3obj', _handlers.threeDManager.warn, scope) &&
        GLOBAL_UTILS.typeCheck(value.perspective.target, 'vector3obj', _handlers.threeDManager.warn, scope)))
      return Promise.resolve(false);
    if (!value.orthographic ||
      !(GLOBAL_UTILS.typeCheck(value.orthographic.position, 'vector3obj', _handlers.threeDManager.warn, scope) &&
        GLOBAL_UTILS.typeCheck(value.orthographic.target, 'vector3obj', _handlers.threeDManager.warn, scope)))
      return Promise.resolve(false);
    return Promise.resolve(true);
    // Alex to Michael: I think it's more clear if a settings update of the default camera position
    //   keeps the current position, but updates the setting only
    //   resetCamera* can be used if wanted
    // return _camera instanceof THREE.PerspectiveCamera ?
    //   _out.setPositionAndTarget(value.perspective.position, value.perspective.target, {duration: _settings.getSetting('cameraMovementDuration')}) :
    //   _out.setPositionAndTarget(value.orthographic.position, value.orthographic.target, {duration: _settings.getSetting('cameraMovementDuration')});
  };


  /**
   * Switch between perspective camera and serveral orthographic views
   *
   * @param  {module:ThreeDManagerConstants~CameraViewType} view
   * @return {Boolean} true if camera could be changed successfully
   */
  let _typeHook = function (view) {
    if (typeof view !== 'number') return false;
    if (view < THREE_D_MANAGER_CONSTANTS.cameraViewTypes.MIN || view > THREE_D_MANAGER_CONSTANTS.cameraViewTypes.MAX)
      return false;

    if (view === THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE) {
      _camera = _perspectiveCamera;
      _orbitControls = _perspectiveOrbitControls;
      _perspectiveOrbitControls.enabled = true;
      _orthographicOrbitControls.enabled = false;

      if (_settings.getSetting('environmentMapAsBackground'))
        _handlers.materialHandler.setSceneBackground(_handlers.materialHandler.getEnvironmentMap());
    } else {
      _camera = _orthographicCamera;
      _orthographicCamera.far = 1000.0 * _sceneBS.radius;
      _orthographicCamera.near = _sceneBS.radius;
      _orbitControls = _orthographicOrbitControls;
      _perspectiveOrbitControls.enabled = false;
      _orthographicOrbitControls.enabled = true;
      switch (view) {
        case THREE_D_MANAGER_CONSTANTS.cameraViewTypes.TOP:
          _orthographicOrbitControls.object.position.set(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.z + 2 * _sceneBS.radius);
          _orthographicOrbitControls.target.set(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.z - _sceneBS.radius);
          break;
        case THREE_D_MANAGER_CONSTANTS.cameraViewTypes.BOTTOM:
          _orthographicOrbitControls.object.position.set(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.z - 2 * _sceneBS.radius);
          _orthographicOrbitControls.target.set(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.z + _sceneBS.radius);
          break;
        case THREE_D_MANAGER_CONSTANTS.cameraViewTypes.RIGHT:
          _orthographicOrbitControls.object.position.set(_sceneBS.center.x + 2 * _sceneBS.radius, _sceneBS.center.y, _sceneBS.center.z);
          _orthographicOrbitControls.target.set(_sceneBS.center.x - _sceneBS.radius, _sceneBS.center.y, _sceneBS.center.z);
          break;
        case THREE_D_MANAGER_CONSTANTS.cameraViewTypes.LEFT:
          _orthographicOrbitControls.object.position.set(_sceneBS.center.x - 2 * _sceneBS.radius, _sceneBS.center.y, _sceneBS.center.z);
          _orthographicOrbitControls.target.set(_sceneBS.center.x + _sceneBS.radius, _sceneBS.center.y, _sceneBS.center.z);
          break;
        case THREE_D_MANAGER_CONSTANTS.cameraViewTypes.BACK:
          _orthographicOrbitControls.object.position.set(_sceneBS.center.x, _sceneBS.center.y + 2 * _sceneBS.radius, _sceneBS.center.z);
          _orthographicOrbitControls.target.set(_sceneBS.center.x, _sceneBS.center.y - _sceneBS.radius, _sceneBS.center.z);
          break;
        case THREE_D_MANAGER_CONSTANTS.cameraViewTypes.FRONT:
          _orthographicOrbitControls.object.position.set(_sceneBS.center.x, _sceneBS.center.y - 2 * _sceneBS.radius, _sceneBS.center.z);
          _orthographicOrbitControls.target.set(_sceneBS.center.x, _sceneBS.center.y + _sceneBS.radius, _sceneBS.center.z);
          break;
      }
      _orbitControls.target.set(_sceneBS.center.x, _sceneBS.center.y, _sceneBS.center.z);
      _handlers.materialHandler.setSceneBackground(null);
    }

    _handlers.lightHandler.adaptLightingToCameraType(view);
    _handlers.renderingHandler.render();
    return true;
  };

  ////////////
  ////////////
  //
  // CameraHandler API - Orbit Control Settings
  //
  ////////////
  ////////////

  /**
   * Toggles the orbit controls.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _enableOrbitControlsHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'CameraHandler.Hook->enableOrbitControls')) return false;

    _orbitControls.enabled = toggle;
    _orbitControls.resetState();
    return true;
  };

  let autoAdjustEventListenerToken = null;

  /**
   * Calls zoom extents every time the geometry updates.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _autoAdjustHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'CameraHandler.Hook->autoAdjust')) return false;

    if (toggle && !autoAdjustEventListenerToken)
      autoAdjustEventListenerToken = _handlers.threeDManager.viewerApi.scene.addEventListener(_handlers.threeDManager.viewerApi.scene.EVENTTYPE.SUBSCENE_PUBLISHED, function () {
        // we call zoomExtents only if scene is visible, this allows us to avoid the call to zoomExtents for the very initial update of the scene after loading
        if (_settings.getSetting('autoAdjust') && _handlers.threeDManager.getSetting('show')) that.zoomExtents({ default: true });
      });
    return true;
  };

  ////////////
  ////////////
  //
  // CameraHandler API - Orbit Control Damping
  //
  ////////////
  ////////////

  /**
   * Sets the damping of the orbit controls.
   *
   * @param {Number} value The new damping value
   */
  let _dampingHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'notnegative', _handlers.threeDManager.warn, 'CameraHandler.Hook->damping')) return false;

    value = Math.min(Math.max(0.05, value), 0.25);
    _orbitControls.dampingFactor = value;
    _orbitControls.panDampingFactor = value;
    _orbitControls.zoomDampingFactor = value;
    _orbitControls.rotateDampingFactor = value;
    return true;
  };

  ////////////
  ////////////
  //
  // CameraHandler API - Orbit Control Rotation
  //
  ////////////
  ////////////

  /**
   * Toggles the possibility to rotate.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _enableRotationHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'CameraHandler.Hook->enableRotation')) return false;

    _orbitControls.enableRotation = toggle;
    return true;
  };

  /**
   * Sets the rotation speed of the orbit controls.
   *
   * @param {Number} value The new rotation value
   */
  let _rotationSpeedHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'float', _handlers.threeDManager.warn, 'CameraHandler.Hook->rotationSpeed')) return false;

    _orbitControls.rotationSpeed = value;
    return true;
  };

  ////////////
  ////////////
  //
  // CameraHandler API - Orbit Control Auto Rotation
  //
  ////////////
  ////////////

  /**
   * Toggles the auto rotation.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _enableAutoRotationHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'CameraHandler.Hook->enableAutoRotation')) return false;

    _orbitControls.enableAutoRotation = toggle;
    if (toggle)
      _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Sets the auto rotation speed of the orbit controls.
   *
   * @param {Number} value The new rotation value
   */
  let _autoRotationSpeedHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'float', _handlers.threeDManager.warn, 'CameraHandler.Hook->autoRotationSpeed')) return false;

    _orbitControls.autoRotationSpeed = value;
    if (value !== 0) {
      _settings.updateSetting('enableAutoRotation', true);
    } else {
      _settings.updateSetting('enableAutoRotation', false);
    }

    return true;
  };

  ////////////
  ////////////
  //
  // CameraHandler API - Orbit Control Zoom
  //
  ////////////
  ////////////

  /**
   * Toggles the possibility to zoom.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _enableZoomHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'CameraHandler.Hook->enableZoom')) return false;

    _orbitControls.enableZoom = toggle;
    return true;
  };

  /**
   * Sets the zoom speed of the orbit controls.
   *
   * @param {Number} value The new zoom speed value
   */
  let _zoomSpeedHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'notnegative', _handlers.threeDManager.warn, 'CameraHandler.Hook->zoomSpeed')) return false;

    _orbitControls.zoomSpeed = value;
    return true;
  };

  ////////////
  ////////////
  //
  // CameraHandler API - Orbit Control Pan
  //
  ////////////
  ////////////

  /**
   * Toggles the possibility to pan.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _enablePanHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'CameraHandler.Hook->enablePan')) return false;

    _orbitControls.enablePan = toggle;
    return true;
  };

  /**
   * Sets the pan speed of the orbit controls.
   *
   * @param {Number} value The new pan speed value
   */
  let _panSpeedHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'number', _handlers.threeDManager.warn, 'CameraHandler.Hook->panSpeed')) return false;

    _orbitControls.panSpeed = value;
    return true;
  };

  ////////////
  ////////////
  //
  // CameraHandler API - Orbit Control Key Pan
  //
  ////////////
  ////////////

  /**
   * Toggles the possibility to pan with keys.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _enableKeyPanHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'CameraHandler.Hook->enableKeyPan')) return false;

    _orbitControls.enableKeyPan = toggle;
    return true;
  };

  /**
   * Sets the key pan speed of the orbit controls.
   *
   * @param {Number} value The new key pan speed value
   */
  let _keyPanSpeedHook = function (value) {
    if (!GLOBAL_UTILS.typeCheck(value, 'number', _handlers.threeDManager.warn, 'CameraHandler.Hook->keyPanSpeed')) return false;

    _orbitControls.keyPanSpeed = value * 7;
    return true;
  };

  ////////////
  ////////////
  //
  // CameraHandler API - Orbit Control Restrictions
  //
  ////////////
  ////////////

  /**
   * Restricts the camera position with two vectors.
   * These vectors can be seen as the minimum and the maximum of a cube.
   *
   * @param {THREE.Vector3} minPosition The minimum of the cube
   * @param {THREE.Vector3} maxPosition The maximum of the cube
   */
  let _restrictionsPositionCubeHook = function (value) {
    // TODO, restrictions testing
    let scope = 'CameraHandler.Hook->restrictions.position.cube';
    if (!(GLOBAL_UTILS.typeCheck(value.min, 'vector3any', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.max, 'vector3any', _handlers.threeDManager.warn, scope)))
      return false;
    _orbitControls.minPosition = GLOBAL_UTILS.toVector3(value.min);
    _orbitControls.maxPosition = GLOBAL_UTILS.toVector3(value.max);
    _handlers.renderingHandler.render();
    return true;
  };


  /**
   * Restricts the position of the camera with a sphere.
   *
   * @param {THREE.Vector3} position The center of the sphere
   * @param {Number} radius The radius of the sphere
   */
  let _restrictionsPositionSphereHook = function (value) {
    // TODO, restrictions testing
    let scope = 'CameraHandler.Hook->restrictions.position.sphere';
    if (!(GLOBAL_UTILS.typeCheck(value.center, 'vector3any', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.radius, 'notnegative', _handlers.threeDManager.warn, scope)))
      return false;

    _orbitControls.positionSphereCenter = GLOBAL_UTILS.toVector3(value.center);
    _orbitControls.positionSphereRadius = value.radius;
    _handlers.renderingHandler.render();
    return true;
  };


  /**
   * Restrcts the panning and therefore the camera target with two vectors.
   * These vectors can be seen as the minimum and the maximum of a cube.
   *
   * @param {THREE.Vector3} minTarget The minimum of the cube
   * @param {THREE.Vector3} maxTarget The maximum of the cube
   */
  let _restrictionsTargetCubeHook = function (value) {
    // TODO, restrictions testing
    let scope = 'CameraHandler.Hook->restrictions.target.cube';
    if (!(GLOBAL_UTILS.typeCheck(value.min, 'vector3any', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.max, 'vector3any', _handlers.threeDManager.warn, scope)))
      return false;

    _orbitControls.minTarget = GLOBAL_UTILS.toVector3(value.min);
    _orbitControls.maxTarget = GLOBAL_UTILS.toVector3(value.max);
    _handlers.renderingHandler.render();
    return true;
  };


  /**
   * Restricts the panning and therefore the camera target with a sphere.
   *
   * @param {THREE.Vector3} position The center of the sphere
   * @param {Number} radius The radius of the sphere
   */
  let _restrictionsTargetSphereHook = function (value) {
    // TODO, restrictions testing
    let scope = 'CameraHandler.Hook->restrictions.target.sphere';
    if (!(GLOBAL_UTILS.typeCheck(value.center, 'vector3any', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.radius, 'notnegative', _handlers.threeDManager.warn, scope)))
      return false;

    _orbitControls.targetSphereCenter = GLOBAL_UTILS.toVector3(value.center);
    _orbitControls.targetSphereRadius = value.radius;
    _handlers.renderingHandler.render();
    return true;
  };


  /**
   * Restricts the rotation with restrictions to the two angles.
   *
   * Polar Angle:
   *  from 0 to 180° (PI), where 180° is looking from the bottom up and 0 from the top down
   * Azimuth Angle:
   *  from -180° to 180°, horizontal rotation
   *
   * @param {Number} minPolarAngle
   * @param {Number} maxPolarAngle
   * @param {Number} minAzimuthAngle
   * @param {Number} maxAzimuthAngle
   */
  let _restrictionsRotationHook = function (value) {
    // TODO, restrictions testing
    let scope = 'CameraHandler.Hook->restrictions.rotation';
    if (!(GLOBAL_UTILS.typeCheck(value.minPolarAngle, 'number', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.maxPolarAngle, 'number', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.minAzimuthAngle, 'number', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.maxAzimuthAngle, 'number', _handlers.threeDManager.warn, scope)))
      return false;

    _orbitControls.minPolarAngle = value.minPolarAngle * Math.PI / 180.0;
    _orbitControls.maxPolarAngle = value.maxPolarAngle * Math.PI / 180.0;
    _orbitControls.minAzimuthAngle = value.minAzimuthAngle * Math.PI / 180.0;
    _orbitControls.maxAzimuthAngle = value.maxAzimuthAngle * Math.PI / 180.0;
    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Restricts the zoom with a minimum and maximum distance.
   *
   * @param {Object} value - The restrictions object
   * @param {Number} value.minDistance - The minimum distance
   * @param {Number} value.maxDistance - The maximum distance
   */
  let _restrictionsZoomHook = function (value) {
    // TODO, restrictions testing
    let scope = 'CameraHandler.Hook->restrictions.zoom';
    if (!(GLOBAL_UTILS.typeCheck(value.minDistance, 'number', _handlers.threeDManager.warn, scope) &&
      GLOBAL_UTILS.typeCheck(value.maxDistance, 'number', _handlers.threeDManager.warn, scope)))
      return false;

    _orbitControls.minDistance = value.minDistance;
    _orbitControls.maxDistance = value.maxDistance;
    _handlers.renderingHandler.render();
    return true;
  };

  let revertAtMouseUpEventListenerToken = null,
      revertAtTouchEndEventListenerToken = null,
      revertAtMouseWheelEventListenerToken = null;


  /**
   * Reverts to the stored camera position every time the mouse is released.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _revertAtMouseUpHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', _handlers.threeDManager.warn, 'CameraHandler.Hook->revertAtMouseUp')) return false;

    // if passive events are supported by the browser, add passive option
    let useCapture = false;
    if (DETECT_IT.passiveEvents === true) {
      useCapture = {
        capture: false,
        passive: true
      };
    }

    let domElement = _handlers.renderingHandler.getDomElement();

    if (!revertAtMouseUpEventListenerToken)
      revertAtMouseUpEventListenerToken = domElement.addEventListener('mouseup', function () {
        if (_settings.getSetting('revertAtMouseUp')) that.resetPositionAndTarget({ duration: _settings.getSetting('revertAtMouseUpDuration') });
      }, useCapture);

    if (!revertAtTouchEndEventListenerToken)
      revertAtTouchEndEventListenerToken = domElement.addEventListener('touchend', function () {
        if (_settings.getSetting('revertAtMouseUp')) that.resetPositionAndTarget({ duration: _settings.getSetting('revertAtMouseUpDuration') });
      }, useCapture);

    if (!revertAtMouseWheelEventListenerToken) {

      let zoomResizeTimer = 0;
      let mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? 'DOMMouseScroll' : 'mousewheel'; //FF doesn't recognize mousewheel as of FF3.x
      revertAtMouseWheelEventListenerToken = domElement.addEventListener(mousewheelevt, function (/*event*/) {
        clearTimeout(zoomResizeTimer);
        if (_settings.getSetting('revertAtMouseUp')) {
          zoomResizeTimer = setTimeout(function () {
            that.resetPositionAndTarget({ duration: _settings.getSetting('revertAtMouseUpDuration') });
          }, 300);
        }
      }, useCapture);
    }

    return true;
  };

  /**
   * Notification when the factor for the zoom extents was changed.
   *
   * @param {Number} value - The factor
   */
  let _zoomExtentsFactorNotifier = function (/*value*/) {
    that.zoomExtents();
  };

  /**
   * @extends module:CameraHandlerInterface~CameraHandlerInterface
   * @lends module:CameraHandlerDefault~CameraHandler
   */
  class CameraHandler extends CameraHandlerInterface {

    /**
     * Constructor of the Camera Handler
     *
     * @param {Object} ___settings - Instantiation settings
     * @param {Object} ___settings.settings - The default settings object
     * @param {THREE.Scene} ___settings.scene - The 3D scene
     * @param {HTMLElement} ___settings.container - The container that the renderer should be in
     */
    constructor(___settings) {
      super(___settings);
      that = this;

      /**
       * The camera of the scene.
       */
      _perspectiveCamera = new THREE.PerspectiveCamera(_settings.getSetting('fov'), _container.offsetWidth / _container.offsetHeight, _settings.getSetting('near'), _settings.getSetting('far'));
      p = _settings.getSetting('defaults.perspective.position');
      _perspectiveCamera.position.set(p.x, p.y, p.z);
      _perspectiveCamera.up.set(0, 0, 1);
      _handlers.threeDManager.helpers.addSceneObject(_perspectiveCamera);

      let perspectiveProxy = new (require('../../helpers/OrbitControlsProxyStandardImplementation'))(_handlers);
      /**
       * The camera controls. We currently use orbit controls.
       * @private
       */
      _perspectiveOrbitControls = new THREE.OrbitControls(_perspectiveCamera, perspectiveProxy);

      // set the event listeners
      perspectiveProxy.initialize();

      // initialize with the default settings
      _perspectiveOrbitControls.enableDamping = true;
      _perspectiveOrbitControls.dampingFactor = _settings.getSetting('damping');
      _perspectiveOrbitControls.panDampingFactor = _settings.getSetting('damping');
      _perspectiveOrbitControls.zoomDampingFactor = _settings.getSetting('damping');
      _perspectiveOrbitControls.rotateDampingFactor = _settings.getSetting('damping');

      _perspectiveOrbitControls.enableRotation = _settings.getSetting('enableRotation');
      _perspectiveOrbitControls.rotationSpeed = _settings.getSetting('rotationSpeed');

      _perspectiveOrbitControls.enableAutoRotation = _settings.getSetting('enableAutoRotation');
      _perspectiveOrbitControls.autoRotationSpeed = _settings.getSetting('autoRotationSpeed');

      _perspectiveOrbitControls.enableZoom = _settings.getSetting('enableZoom');
      _perspectiveOrbitControls.zoomSpeed = _settings.getSetting('zoomSpeed');

      _perspectiveOrbitControls.enablePan = _settings.getSetting('enablePan');
      _perspectiveOrbitControls.panSpeed = _settings.getSetting('panSpeed');

      _perspectiveOrbitControls.enableKeyPan = _settings.getSetting('enableKeyPan');
      _perspectiveOrbitControls.keyPanSpeed = _settings.getSetting('keyPanSpeed') * 7;

      t = _settings.getSetting('defaults.perspective.target');
      _perspectiveOrbitControls.target.set(t.x, t.y, t.z);

      _perspectiveOrbitControls.addEventListener('change', function () {
        if (_orbitControls == _perspectiveOrbitControls && _orbitControls.isMoving) _handlers.renderingHandler.registerForContinuousRendering(CAMERA_MOVING_ID);
      });

      /**
       * The orthographic camera of the scene.
       * The specified radius determines the initial near and far planes.
       */
      _orthographicCamera = new THREE.OrthographicCamera(50 / - 2, 50 / 2, 50 / 2, 50 / - 2, .01, 10000);
      p = _settings.getSetting('defaults.orthographic.position');
      _orthographicCamera.position.set(p.x, p.y, p.z);
      _orthographicCamera.up.set(0, 0, 1);

      let orthographicProxy = new (require('../../helpers/OrbitControlsProxyStandardImplementation'))(_handlers);
      /**
       * The camera controls. We currently use orbit controls.
       * @private
       */
      _orthographicOrbitControls = new THREE.OrbitControls(_orthographicCamera, orthographicProxy);

      // set the event listeners
      orthographicProxy.initialize();

      _orthographicOrbitControls.enableDamping = true;
      _orthographicOrbitControls.dampingFactor = _settings.getSetting('damping');
      _orthographicOrbitControls.panDampingFactor = _settings.getSetting('damping');
      _orthographicOrbitControls.zoomDampingFactor = _settings.getSetting('damping');
      _orthographicOrbitControls.rotateDampingFactor = _settings.getSetting('damping');

      _orthographicOrbitControls.enableRotation = false;
      _orthographicOrbitControls.enableAutoRotation = false;
      _orthographicOrbitControls.enablePan = true;
      _orthographicOrbitControls.enableKeyPan = true;

      _orthographicOrbitControls.enableZoom = _settings.getSetting('enableZoom');
      _orthographicOrbitControls.zoomSpeed = _settings.getSetting('zoomSpeed');

      _orthographicOrbitControls.addEventListener('change', function () {
        if (_orbitControls == _orthographicOrbitControls && _orbitControls.isMoving) _handlers.renderingHandler.registerForContinuousRendering(CAMERA_MOVING_ID);
      });
      t = _settings.getSetting('defaults.orthographic.target');
      _orthographicOrbitControls.target.set(t.x, t.y, t.z);

      _camera = _perspectiveCamera;
      _orbitControls = _perspectiveOrbitControls;
      _perspectiveOrbitControls.enabled = true;
      _orthographicOrbitControls.enabled = false;

      _sceneBS.radius = 25.0;

      _settings.registerHook('autoAdjust', _autoAdjustHook);
      _settings.registerHook('fov', _fovHook);
      _settings.registerHook('defaults.perspective.position', _perspectivePositionHook);
      _settings.registerHook('defaults.perspective.target', _perspectiveTargetHook);
      _settings.registerHook('defaults.perspective', _perspectiveHook);
      _settings.registerHook('defaults.orthographic.position', _orthographicPositionHook);
      _settings.registerHook('defaults.orthographic.target', _orthographicTargetHook);
      _settings.registerHook('defaults.orthographic', _orthographicHook);
      _settings.registerHook('defaults', _defaultsHook);
      _settings.registerHook('type', _typeHook);
      _settings.registerHook('enableOrbitControls', _enableOrbitControlsHook);
      _settings.registerHook('damping', _dampingHook);
      _settings.registerHook('enableRotation', _enableRotationHook);
      _settings.registerHook('rotationSpeed', _rotationSpeedHook);
      _settings.registerHook('enableAutoRotation', _enableAutoRotationHook);
      _settings.registerHook('autoRotationSpeed', _autoRotationSpeedHook);
      _settings.registerHook('enableZoom', _enableZoomHook);
      _settings.registerHook('zoomSpeed', _zoomSpeedHook);
      _settings.registerHook('enablePan', _enablePanHook);
      _settings.registerHook('panSpeed', _panSpeedHook);
      _settings.registerHook('enableKeyPan', _enableKeyPanHook);
      _settings.registerHook('keyPanSpeed', _keyPanSpeedHook);
      _settings.registerHook('restrictions.position.cube', _restrictionsPositionCubeHook);
      _settings.registerHook('restrictions.position.cube.min', (value) => {
        let s = _settings.getSetting('restrictions.position.cube');
        s.min = value;
        return _restrictionsPositionCubeHook(s);
      });
      _settings.registerHook('restrictions.position.cube.max', (value) => {
        let s = _settings.getSetting('restrictions.position.cube');
        s.max = value;
        return _restrictionsPositionCubeHook(s);
      });
      _settings.registerHook('restrictions.position.sphere', _restrictionsPositionSphereHook);
      _settings.registerHook('restrictions.position.sphere.center', (value) => {
        let s = _settings.getSetting('restrictions.position.sphere');
        s.center = value;
        return _restrictionsPositionSphereHook(s);
      });
      _settings.registerHook('restrictions.position.sphere.radius', (value) => {
        let s = _settings.getSetting('restrictions.position.sphere');
        s.radius = value;
        return _restrictionsPositionSphereHook(s);
      });
      _settings.registerHook('restrictions.target.cube', _restrictionsTargetCubeHook);
      _settings.registerHook('restrictions.target.cube.min', (value) => {
        let s = _settings.getSetting('restrictions.target.cube');
        s.min = value;
        return _restrictionsTargetCubeHook(s);
      });
      _settings.registerHook('restrictions.target.cube.max', (value) => {
        let s = _settings.getSetting('restrictions.target.cube');
        s.max = value;
        return _restrictionsTargetCubeHook(s);
      });
      _settings.registerHook('restrictions.target.sphere', _restrictionsTargetSphereHook);
      _settings.registerHook('restrictions.target.sphere.center', (value) => {
        let s = _settings.getSetting('restrictions.target.sphere');
        s.center = value;
        return _restrictionsTargetSphereHook(s);
      });
      _settings.registerHook('restrictions.target.sphere.radius', (value) => {
        let s = _settings.getSetting('restrictions.target.sphere');
        s.radius = value;
        return _restrictionsTargetSphereHook(s);
      });
      _settings.registerHook('restrictions.rotation', _restrictionsRotationHook);
      _settings.registerHook('restrictions.rotation.minPolarAngle', (value) => {
        let s = _settings.getSetting('restrictions.rotation');
        s.minPolarAngle = value;
        return _restrictionsRotationHook(s);
      });
      _settings.registerHook('restrictions.rotation.maxPolarAngle', (value) => {
        let s = _settings.getSetting('restrictions.rotation');
        s.maxPolarAngle = value;
        return _restrictionsRotationHook(s);
      });
      _settings.registerHook('restrictions.rotation.minAzimuthAngle', (value) => {
        let s = _settings.getSetting('restrictions.rotation');
        s.minAzimuthAngle = value;
        return _restrictionsRotationHook(s);
      });
      _settings.registerHook('restrictions.rotation.maxAzimuthAngle', (value) => {
        let s = _settings.getSetting('restrictions.rotation');
        s.maxAzimuthAngle = value;
        return _restrictionsRotationHook(s);
      });
      _settings.registerHook('restrictions.zoom', _restrictionsZoomHook);
      _settings.registerHook('restrictions.zoom.minDistance', (value) => {
        let s = _settings.getSetting('restrictions.zoom');
        s.minDistance = value;
        return _restrictionsZoomHook(s);
      });
      _settings.registerHook('restrictions.zoom.maxDistance', (value) => {
        let s = _settings.getSetting('restrictions.zoom');
        s.maxDistance = value;
        return _restrictionsZoomHook(s);
      });
      _settings.registerHook('revertAtMouseUp', _revertAtMouseUpHook);
      _settings.registerNotifier('zoomExtentsFactor', _zoomExtentsFactorNotifier);
    }


    /**
     * Set missing values of a transition function definition to default values,
     * and replace 'easing' and 'interpolation' properties by corresponding functions.
     * @param {Object} [trans]   Options
     * @param {Boolean} [options.default=false] If true store final camera position and target as default.
     * @param {Number} [trans.duration] - duration of the transition in milliseconds
     * @param {String} [options.coordinates='cylindrical'] - Defines coordinate system to use for animated camera paths. One of 'spherical' or 'cylindrical'.
     * @param {String|Function} [trans.easing='Quartic.InOut'] - In case a string S is provided, the corresponding easing function TWEEN.Easing[S] will be used if it exists. The easing function may also be passed directly, e.g. one of the many provided by {@link https://github.com/tweenjs/tween.js/blob/master/docs/user_guide.md Tween}, see also {@link https://5013.es/toys/tween.audio/ TweenExplained}, or a manually defined one.
     * @param {String} [trans.interpolation='CatmullRom'] - In case a string S is provided, the corresponding interpolation function TWEEN.Interpolation[S] will be used if it exists. Tween supports Linear, Bezier, and CatmullRom.
     * @return {Object} Transition function definition with default values set
     */
    _transitionDefaults(trans) {
      // make sure we have an object
      let t = {};

      // default duration
      if (GLOBAL_UTILS.typeCheck(trans.duration, 'notnegative')) {
        t.duration = trans.duration;
      } else {
        t.duration = _settings.getSetting('cameraMovementDuration');
      }

      // default coordinate system
      if (GLOBAL_UTILS.typeCheck(trans.coordinates, 'string'))
        t.coordinates = trans.coordinates;
      if (t.coordinates !== 'spherical' && t.coordinates !== 'cylindrical')
        t.coordinates = 'cylindrical';

      // define easing
      t.easing = null;
      if (trans.easing !== undefined) {
        if (GLOBAL_UTILS.typeCheck(trans.easing, 'string')) {
          t.easing = GLOBAL_UTILS.getAtPath(TWEEN.Easing, trans.easing);
        }
        else if (typeof trans.easing === 'function') {
          t.easing = trans.easing;
        }
      }
      t.easing = t.easing || TWEEN.Easing.Quartic.InOut;

      // define interpolation
      t.interpolation = null;
      if (trans.interpolation !== undefined) {
        if (GLOBAL_UTILS.typeCheck(trans.interpolation, 'string')) {
          t.interpolation = GLOBAL_UTILS.getAtPath(TWEEN.Interpolation, trans.interpolation);
        }
        else if (typeof trans.interpolation === 'function') {
          t.interpolation = trans.interpolation;
        }
      }
      t.interpolation = t.interpolation || TWEEN.Interpolation.CatmullRom;

      // do not update default position
      if (typeof t.default !== 'boolean') t.default = false;

      return t;
    }

    /**
     * Checks if camera is ortographic camera.
     * @returns {Boolean} True for orthographic camera, false otherwise.
     */
    _isOrthographicCameraType() {
      return _orbitControls.object.type === 'OrthographicCamera';
    }

    /**
     * Checks if camera is perspective camera.
     * @returns {Boolean} True for perspective camera, false otherwise.
     */
    _isPerspectiveCameraType() {
      return _orbitControls.object.type === 'PerspectiveCamera';
    }


    ////////////
    ////////////
    //
    // CameraHandler API
    //
    ////////////
    ////////////

    /** @inheritdoc */
    pause() {
      _active = false;
      _orbitControls.enabled = false;
      _orbitControls.resetState();
    }

    /** @inheritdoc */
    resume() {
      _active = true;
      _perspectiveCamera.updateProjectionMatrix();
      _orbitControls.enabled = _settings.getSetting('enableOrbitControls');
    }

    /** @inheritdoc */
    setViewAndProjectionMatrix(viewMatrix, projectionMatrix) {
      // update camera view matrix
      _cameraMatrix.getInverse(viewMatrix);
      _cameraMatrix.decompose(_camera.position, _camera.quaternion, _camera.scale);

      // update camera projection matrix
      _camera.projectionMatrix.copy(projectionMatrix);
    }


    /** @inheritdoc */
    destroy() {
      // destroy the camera handler
    }




    /** @inheritdoc */
    setPosition(position) {
      // TODO, restrictions testing
      if (!GLOBAL_UTILS.typeCheck(position, 'vector3obj', _handlers.threeDManager.warn, 'CameraHandler.setPosition')) return false;

      _orbitControls.object.position.set(position.x, position.y, position.z);
      _handlers.renderingHandler.render();
      return true;
    }

    /** @inheritdoc */
    resetPosition() {
      // TODO, restrictions testing
      return _camera instanceof THREE.PerspectiveCamera ?
        that.setPosition(_settings.getSetting('defaults.perspective.position')) :
        that.setPosition(_settings.getSetting('defaults.orthographic.position'));
    }

    /** @inheritdoc */
    getPosition() {
      return GLOBAL_UTILS.deepCopy(_orbitControls.object.position);
    }




    /** @inheritdoc */
    setTarget(target) {
      // TODO, restrictions testing
      if (!GLOBAL_UTILS.typeCheck(target, 'vector3obj', _handlers.threeDManager.warn, 'CameraHandler.setTarget')) return false;

      _orbitControls.target.set(target.x, target.y, target.z);
      _handlers.renderingHandler.render();
      return true;
    }

    /** @inheritdoc */
    resetTarget() {
      // TODO, restrictions testing
      return _camera instanceof THREE.PerspectiveCamera ?
        that.setTarget(_settings.getSetting('defaults.perspective.target')) :
        that.setTarget(_settings.getSetting('defaults.orthographic.target'));
    }

    /** @inheritdoc */
    getTarget() {
      return GLOBAL_UTILS.deepCopy(_orbitControls.target);
    }



    /** @inheritdoc */
    setPositionAndTarget(position, target, options) {
      // TODO, restrictions testing
      let scope = 'CameraHandler.setPositionAndTarget';

      if (!(GLOBAL_UTILS.typeCheck(position, 'vector3any', _handlers.threeDManager.warn, scope) &&
        GLOBAL_UTILS.typeCheck(target, 'vector3any', _handlers.threeDManager.warn, scope)))
        return Promise.resolve(false);

      // convert input to THREE.Vector3
      let position_to = GLOBAL_UTILS.toVector3(position);
      let target_to = GLOBAL_UTILS.toVector3(target);

      // get transition function definition
      options = options || {};
      let opt = that._transitionDefaults(options);

      // store default position if caller wishes
      if (options.default) {
        let prefix = _camera instanceof THREE.PerspectiveCamera ? 'defaults.perspective' : 'defaults.orthographic';
        _settings.updateSetting(prefix + '.position', position_to);
        _settings.updateSetting(prefix + '.target', target_to);
      }

      // case 1 - animate to new position and target
      if (GLOBAL_UTILS.typeCheck(opt.duration, 'positive')) {
        return new Promise(function (resolve) {

          // preparation
          let position_from = _orbitControls.object.position;
          let target_from = _orbitControls.target;
          let diff_from = position_from.clone().sub(target_from);
          let diff_to = position_to.clone().sub(target_to);
          // console.log('position_from', position_from.x, position_from.y, position_from.z);
          // console.log('target_from', target_from.x, target_from.y, target_from.z);
          // console.log('diff_from', diff_from.x, diff_from.y, diff_from.z);
          // console.log('position_to', position_to.x, position_to.y, position_to.z);
          // console.log('target_to', target_to.x, target_to.y, target_to.z);
          // console.log('diff_to', diff_to.x, diff_to.y, diff_to.z);

          // compute a transformation such that
          //  diff_from gets transformed to [X,0,0] and
          //  diff_to is contained in the notnegative y halfspace
          let xBasis = diff_from.clone().normalize();
          let zBasis;
          if (opt.coordinates === 'cylindrical') zBasis = new THREE.Vector3(0, 0, 1);
          else zBasis = new THREE.Vector3().crossVectors(xBasis, diff_to);
          zBasis.sub(xBasis.clone().multiplyScalar(xBasis.dot(zBasis)));
          zBasis.normalize();
          // make sure our matrix will have full rank
          if (zBasis.lengthSq() < 1e-12) {
            zBasis.set(0, 0, 1);
            zBasis.sub(xBasis.clone().multiplyScalar(xBasis.dot(zBasis)));
            zBasis.normalize();
          }
          if (zBasis.lengthSq() < 1e-12) {
            zBasis.set(0, 1, 0);
            zBasis.sub(xBasis.clone().multiplyScalar(xBasis.dot(zBasis)));
            zBasis.normalize();
          }
          let yBasis = new THREE.Vector3().crossVectors(zBasis, xBasis);

          let transInv = new THREE.Matrix4().makeBasis(xBasis, yBasis, zBasis);
          let trans = new THREE.Matrix4();
          try {
            trans.getInverse(transInv, true);
          } catch (e) {
            trans.identity();
            transInv = trans;
          }

          // apply transformation to diff_from and diff_to
          diff_from.applyMatrix4(trans);
          diff_to.applyMatrix4(trans);
          // console.log('diff_from trans', diff_from.x, diff_from.y, diff_from.z);
          // console.log('diff_to trans', diff_to.x, diff_to.y, diff_to.z);

          // compute spherical coordinates for diff_from and diff_to
          let diff_from_polar = GLOBAL_UTILS.cartesianToPolar(diff_from.x, diff_from.y, diff_from.z);
          let diff_to_polar = GLOBAL_UTILS.cartesianToPolar(diff_to.x, diff_to.y, diff_to.z);
          // console.log('diff_from_polar', diff_from_polar);
          // console.log('diff_to_polar', diff_to_polar);

          let tweenProperties = {
            dR: diff_from_polar[0],
            dTheta: diff_from_polar[1],
            dPhi: diff_from_polar[2],
            targetX: _orbitControls.target.x,
            targetY: _orbitControls.target.y,
            targetZ: _orbitControls.target.z
          };

          let tweenCamera = new TWEEN.Tween(tweenProperties).easing(opt.easing)
            .to({
              dR: diff_to_polar[0],
              dTheta: diff_to_polar[1],
              dPhi: diff_to_polar[2],
              targetX: target.x,
              targetY: target.y,
              targetZ: target.z
            }, opt.duration)
            .onUpdate(function () {
              target_from.set(tweenProperties.targetX, tweenProperties.targetY, tweenProperties.targetZ);
              let diff = GLOBAL_UTILS.polarToCartesian(tweenProperties.dR, tweenProperties.dTheta, tweenProperties.dPhi);
              diff = GLOBAL_UTILS.toVector3(diff);
              diff.applyMatrix4(transInv);
              let pos = target_from.clone().add(diff);
              position_from.copy(pos);
              that.updateOrbitControls();
              _handlers.renderingHandler.render();
            })
            .onComplete(function () {
              position_from.copy(position_to);
              target_from.copy(target_to);
              that.updateOrbitControls();
              _handlers.renderingHandler.unregisterForContinuousRendering(TWEEN_ID);
              resolve(true);
            });

          _handlers.renderingHandler.registerForContinuousRendering(TWEEN_ID);
          tweenCamera.start();
        });
      }

      // case 2 - no animation, directly update camera position and target
      _orbitControls.object.position.copy(position_to);
      _orbitControls.target.copy(target_to);

      _handlers.renderingHandler.render();
      return Promise.resolve(true);
    }

    /** @inheritdoc */
    resetPositionAndTarget(options) {
      // TODO, restrictions testing
      if(!options) options = {};
      options.default = false;
      return _camera instanceof THREE.PerspectiveCamera ?
        that.setPositionAndTarget(_settings.getSetting('defaults.perspective.position'), _settings.getSetting('defaults.perspective.target'), options) :
        that.setPositionAndTarget(_settings.getSetting('defaults.orthographic.position'), _settings.getSetting('defaults.orthographic.target'), options);
    }

    /** @inheritdoc */
    getPositionAndTarget() {
      return {
        position: that.getPosition(),
        target: that.getTarget()
      };
    }




    /** @inheritdoc */
    zoomExtents(options, bb) {
      let scope = 'CameraHandler.zoomExtents';
      if (this._isPerspectiveCameraType() === false) {
        // TODO: implement logic for orthographic camera
        _handlers.threeDManager.warn(scope, `Not implemented for orthographic camera.`);
        return Promise.resolve(true);
      }


      if (!(bb && bb instanceof THREE.Box3)) {
        bb = _geometryNode.computeSceneBoundingBox();

        if (bb.min.distanceTo(bb.max) == 0) {
          let mv = Number.MIN_VALUE;
          bb = new THREE.Box3(new THREE.Vector3(-mv, -mv, -mv), new THREE.Vector3(mv, mv, mv));
        }
      }

      let bbCenter = new THREE.Vector3();
      bb.getCenter(bbCenter);

      // correct with factor
      if (_settings.hasSetting('zoomExtentsFactor')) {
        let tmpDir = new THREE.Vector3();
        tmpDir.subVectors(bb.max, bbCenter);
        tmpDir.multiplyScalar(_settings.getSetting('zoomExtentsFactor'));
        bb = new THREE.Box3(new THREE.Vector3().subVectors(bbCenter, tmpDir), new THREE.Vector3().addVectors(bbCenter, tmpDir));
      }

      // test points
      let testPt = [];
      testPt.push(bb.min);
      testPt.push(bb.max);
      testPt.push(new THREE.Vector3(bb.max.x, bb.min.y, bb.min.z));
      testPt.push(new THREE.Vector3(bb.max.x, bb.max.y, bb.min.z));
      testPt.push(new THREE.Vector3(bb.max.x, bb.min.y, bb.max.z));
      testPt.push(new THREE.Vector3(bb.min.x, bb.max.y, bb.max.z));
      testPt.push(new THREE.Vector3(bb.min.x, bb.min.y, bb.max.z));
      testPt.push(new THREE.Vector3(bb.min.x, bb.max.y, bb.min.z));

      let offsetFromCenter = 0.0;

      // see https://threejs.org/docs/#api/en/cameras/Camera.getWorldDirection
      let cDir = new THREE.Vector3();
      _orbitControls.object.getWorldDirection(cDir);
      cDir.normalize();

      let cDirLine = new THREE.Line3(bbCenter.clone().addScaledVector(cDir, -100), bbCenter.clone());
      let cX = new THREE.Vector3();
      cX.crossVectors(cDir, _orbitControls.object.up);
      cX.normalize();
      let cY = new THREE.Vector3();
      cY.crossVectors(cX, cDir);
      cY.normalize();

      for (let i = 0, bound = testPt.length; i < bound; i++) {

        let testPtProjected = new THREE.Vector3();
        cDirLine.closestPointToPoint(testPt[i], false, testPtProjected);
        let lineNormal = testPt[i].clone();
        lineNormal.sub(testPtProjected);

        let point2DX = lineNormal.dot(cX);
        let point2DY = lineNormal.dot(cY);

        // see https://threejs.org/docs/#api/en/cameras/PerspectiveCamera.fov
        // and https://threejs.org/docs/#api/en/cameras/PerspectiveCamera.aspect
        let tanfov = Math.tan((0.5 * _orbitControls.object.fov) * (Math.PI / 180));
        let neededDistCameraToTestPtProjected = Math.max(
          Math.abs(point2DX / (_orbitControls.object.aspect * tanfov)),
          Math.abs(point2DY / tanfov)
        );

        let centerToProjectedTestPoint = testPtProjected.clone().sub(bbCenter);
        let distCenterToProjectedTestPt = centerToProjectedTestPoint.dot(cDir);

        offsetFromCenter = Math.max(offsetFromCenter, neededDistCameraToTestPtProjected - distCenterToProjectedTestPt);
      }

      cDir.multiplyScalar(-1 * offsetFromCenter);
      let newPos = new THREE.Vector3();
      newPos.addVectors(bbCenter, cDir);

      // actually set camera
      return that.setPositionAndTarget(newPos, bbCenter, options);
    }


    /** @inheritdoc */
    setCameraPath(positions, targets, options) {
      // TODO, restrictions testing
      let scope = 'CameraHandler.setCameraPath';
      let i, v, iBound;

      // at least positions or targets must be defined
      if (positions == null && targets == null) {
        return Promise.resolve(false);
      }
      positions = positions || [];
      targets = targets || [];

      // check input sanity
      for (v of [positions, targets]) {
        if (!Array.isArray(v)) {
          return Promise.resolve(false);
        }
        for (i = 0, iBound = v.length; i < iBound; ++i) {
          if (!GLOBAL_UTILS.typeCheck(v[i], 'vector3any', _handlers.threeDManager.warn, scope)) return Promise.resolve(false);
          v[i] = GLOBAL_UTILS.toVector3(v[i]);
        }
      }

      // if positions / targets are not defined, we define them as constant
      if (positions.length == 0) {
        for (i = 0, iBound = targets.length; i < iBound; ++i) {
          positions.push({ x: _orbitControls.object.position.x, y: _orbitControls.object.position.y, z: _orbitControls.object.position.z });
        }
      }
      if (targets.length == 0) {
        for (i = 0, iBound = positions.length; i < iBound; ++i) {
          targets.push({ x: _orbitControls.target.x, y: _orbitControls.target.y, z: _orbitControls.target.z });
        }
      }

      // get transition function definition
      let opt = that._transitionDefaults(options);

      // initial point in spherical coordinates
      // #SS-68 comment by Alex: I think polar coordinates like this only make sense if they are defined
      // relative to the target
      let fromSpherical = GLOBAL_UTILS.cartesianToPolar(_orbitControls.object.position.x, _orbitControls.object.position.y, _orbitControls.object.position.z);

      // convert path coordinates to spherical coordinates for interpolation
      let arrR = [];
      let arrTheta = [];
      let arrPhi = [];
      positions.forEach(function (v) {
        let s = GLOBAL_UTILS.cartesianToPolar(v.x, v.y, v.z);
        arrR.push(s[0]);
        arrTheta.push(s[1]);
        arrPhi.push(s[2]);
      });

      let arrTx = [];
      let arrTy = [];
      let arrTz = [];
      targets.forEach(function (v) {
        arrTx.push(v.x);
        arrTy.push(v.y);
        arrTz.push(v.z);
      });

      return new Promise(function (resolve) {

        let obj = {
          objectR: fromSpherical[0],
          objectTheta: fromSpherical[1],
          objectPhi: fromSpherical[2],
          targetX: _orbitControls.target.x,
          targetY: _orbitControls.target.y,
          targetZ: _orbitControls.target.z
        };
        let tweenCamera = new TWEEN.Tween(obj)
          .to({
            objectR: arrR,
            objectTheta: arrTheta,
            objectPhi: arrPhi,
            targetX: arrTx,
            targetY: arrTy,
            targetZ: arrTz
          }, opt.duration)
          .onUpdate(function () {
            let positionCartesian = GLOBAL_UTILS.polarToCartesian(obj.objectR, obj.objectTheta, obj.objectPhi);
            _orbitControls.object.position.set(positionCartesian[0], positionCartesian[1], positionCartesian[2]);
            _orbitControls.target.set(obj.targetX, obj.targetY, obj.targetZ);
            that.updateOrbitControls();
            _handlers.renderingHandler.render();
          })
          .onComplete(function () {
            let lastIndex = positions.length - 1;
            _orbitControls.object.position.set(positions[lastIndex].x, positions[lastIndex].y, positions[lastIndex].z);
            lastIndex = targets.length - 1;
            _orbitControls.target.set(targets[lastIndex].x, targets[lastIndex].y, targets[lastIndex].z);
            that.updateOrbitControls();
            _handlers.renderingHandler.unregisterForContinuousRendering(TWEEN_ID);
            // store default position if caller wishes
            if (options.default) {
              let prefix = _camera instanceof THREE.PerspectiveCamera ? 'defaults.perspective' : 'defaults.orthographic';
              _settings.updateSetting(prefix + '.position', positions[lastIndex]);
              _settings.updateSetting(prefix + '.target', targets[lastIndex]);
            }
            resolve(true);
          });
        _handlers.renderingHandler.registerForContinuousRendering(TWEEN_ID);
        tweenCamera.easing(opt.easing).interpolation(opt.interpolation).start();
      });
    }




    /** @inheritdoc */
    getCamera() {
      return _camera;
    }

    /** @inheritdoc */
    isCameraMoving() {
      return _orbitControls.isMoving;
    }

    /** @inheritdoc */
    updateOrbitControls() {
      // no update if orbit controls are disable (important for AR)
      if (!_orbitControls.enabled) return;

      _orbitControls.update();
      _handlers.renderingHandler.unregisterForContinuousRendering(CAMERA_MOVING_ID);
      if (_orbitControls.isMoving)
        _handlers.renderingHandler.registerForContinuousRendering(CAMERA_MOVING_ID, false);
    }

    /** @inheritdoc */
    adjustToBoundingSphere(bs) {
      _sceneBS = bs;

      _orthographicCamera.left = -bs.radius * _perspectiveCamera.aspect;
      _orthographicCamera.bottom = -bs.radius;
      _orthographicCamera.right = bs.radius * _perspectiveCamera.aspect;
      _orthographicCamera.top = bs.radius;
      _orthographicCamera.far = 10000.0 * bs.radius;
      _orthographicCamera.near = .01 * bs.radius;
      _orthographicCamera.updateProjectionMatrix();

      _perspectiveCamera.far = _perspectiveCamera.fov < 10 ? _perspectiveCamera.fov * 100.0 * 100.0 * bs.radius : 100.0 * bs.radius;
      _perspectiveCamera.near = _perspectiveCamera.fov < 10 ? _perspectiveCamera.fov * 100.0 * 0.01 * bs.radius : 0.01 * bs.radius;
      _perspectiveCamera.updateProjectionMatrix();
    }

    /** @inheritdoc */
    onResize(width, height) {
      _perspectiveCamera.aspect = width / height;
      _perspectiveCamera.setViewOffset(width, height, 0, 0, width, height);
      _perspectiveCamera.updateProjectionMatrix();
    }
  }

  return new CameraHandler(___settings);
};

module.exports = CameraHandler;
