"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LightEvents;
(function (LightEvents) {
    LightEvents["ADD"] = "add";
    LightEvents["REMOVE"] = "removed";
    LightEvents["CLEAR_LIGHTS"] = "clear.lights";
    LightEvents["DROPDOWN_CUSTOM_UPDATE"] = "dropdown.customUpdate";
})(LightEvents = exports.LightEvents || (exports.LightEvents = {}));
;
var SettingsGroups;
(function (SettingsGroups) {
    SettingsGroups[SettingsGroups["WORLD"] = 1] = "WORLD";
    SettingsGroups[SettingsGroups["LIGHTS"] = 2] = "LIGHTS";
    SettingsGroups[SettingsGroups["BACKGROUND"] = 3] = "BACKGROUND";
    SettingsGroups[SettingsGroups["CAMERA"] = 4] = "CAMERA";
})(SettingsGroups = exports.SettingsGroups || (exports.SettingsGroups = {}));
