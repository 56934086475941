const THREE = require('../../../externals/three');

class Node {
  constructor(id, gltf, parent, allNodes, rootNode) {

    this.id = id;
    this.gltf = gltf;
    this.nodeRef = gltf.nodes[id];
    this.parent = parent || null;
    this.nodes = [];
    this.materials = [];
    this.rootNode = rootNode;
    this.mesh = isNaN(this.nodeRef.mesh) ? null : gltf.meshes[this.nodeRef.mesh];
    this.camera = isNaN(this.nodeRef.camera) ? null : gltf.cameras[this.nodeRef.camera];
    this.matrix = new THREE.Matrix4();
    this.matrix.multiply(gltf.rotationMatrix);
    this.transformedMatrix = new THREE.Matrix4();
    this.transformationsOrder = [];

    if (this.nodeRef.matrix) {
      this.transformedMatrix.fromArray(this.nodeRef.matrix);

    } else {

      if (this.nodeRef.translation) {
        const m = new THREE.Matrix4();
        m.makeTranslation(...this.nodeRef.translation);
        this.transformedMatrix.multiply(m);
      }
    
      if (this.nodeRef.rotation) {
        const quaternion = new THREE.Quaternion(...this.nodeRef.rotation);
        const m = new THREE.Matrix4();
        m.makeRotationFromQuaternion(quaternion);
        this.transformedMatrix.multiply(m);
      }

      if (this.nodeRef.scale) {
        const m = new THREE.Matrix4();
        m.makeScale(...this.nodeRef.scale);
        this.transformedMatrix.multiply(m);
      }
    }

    this._setTransformationOrder();
    this._createChildNodes(gltf, allNodes);
    this._resolveFinalMatrix(gltf);
    this._createMaterials(gltf);


    allNodes.push(this);
  }

  _mat4CoordChange(mat) {
    const t = new THREE.Matrix4();
    t.fromArray(mat);
    t.multiply(this.gltf.rotationMatrix);
    return t;
  }

  _setTransformationOrder() {
    let parent = this.parent;

    this.transformationsOrder.push(this.transformedMatrix.clone());

    while (parent) {
      if (parent.transformedMatrix && this.transformationsOrder.length) {
        this.transformationsOrder.push(parent.transformedMatrix.clone());
      }
      parent = parent.parent;
    }

    this.transformationsOrder.reverse();
  }

  _createChildNodes(gltf, allNodes) {
    if (this.nodeRef.children && this.nodeRef.children.length > 0) {
      this.nodeRef.children.forEach(childId =>
        this.nodes.push(
          new Node(childId, gltf, this, allNodes)));
    }
  }

  _resolveFinalMatrix() {

    const mi = this.transformationsOrder[0];
    for (let i = 1; i < this.transformationsOrder.length; i++) {
      let m = this.transformationsOrder[i];
      mi.multiply(m);
    }
    this.matrix.multiply(mi);
  }

  _createMaterials(gltf) {
    if (this.mesh) {
      this.mesh.primitives.forEach(primitive => {

        if (primitive.hasOwnProperty('material')) {
          this.materials.push(gltf.materials[primitive.material]);
        }
      });
    }
  }
}

module.exports = Node;