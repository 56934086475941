const BufferView = require('./BufferView');
const DATA_URI_REGEX = /^data:(.*?)(;base64)?,(.*)$/;

class Buffer {
  constructor(id, gltf) {
    this.id = id;
    this.bufferRef = gltf.buffers[id];
    this.buffer = null;
    this.byteLength = this.bufferRef.byteLength;
    this.bufferViews = [];

    this.onLoad = new Promise((resolve, reject) => {

      if (DATA_URI_REGEX.test(this.bufferRef.uri)) {
        // to array buffer
        const dataParts = DATA_URI_REGEX.exec(this.bufferRef.uri);
        this.buffer = this._decodeDataUri(dataParts, 'arraybuffer');
        this._createBufferViews(gltf);
        resolve(this);
      } else if (gltf.isGlb) {

        const length = gltf.dataView.byteLength;
        const buffer = new ArrayBuffer(length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < gltf.dataView.byteLength; i++) {
          view[i] = gltf.dataView.getUint8(i);
        }
        this.buffer = buffer;
        this._createBufferViews(gltf);
        resolve(this);
      } else {

        const url = `${gltf.baseUrl}/${this.bufferRef.uri}`;
        this._loadArrayBuffer(url, promise => {
          // to array buffer
          this.buffer = promise;
          this._createBufferViews(gltf);
          resolve(this);
        }, err => reject(err));

      }
    });
  }

  _decodeDataUri(dataUriRegexResult, responseType) {
    responseType = typeof responseType !== 'undefined' ? responseType : '';
    const mimeType = dataUriRegexResult[1];
    const isBase64 = !!dataUriRegexResult[2];
    const data = dataUriRegexResult[3];

    switch (responseType) {
      case '':
      case 'text':
        return this._decodeDataUriText(isBase64, data);
      case 'arraybuffer':
        return this._decodeDataUriArrayBuffer(isBase64, data);
      case 'blob': {
        const buffer = this._decodeDataUriArrayBuffer(isBase64, data);
        return new Blob([buffer], {
          type: mimeType
        });
      }
      case 'document': {
        const parser = new DOMParser();
        return parser.parseFromString(this._decodeDataUriText(isBase64, data), mimeType);
      }
      case 'json':
        return JSON.parse(this._decodeDataUriText(isBase64, data));
      default:
        throw 'Unhandled responseType: ' + responseType;
    }
  }

  _decodeDataUriText(isBase64, data) {
    const result = decodeURIComponent(data);
    if (isBase64) {
      return atob(result);
    }
    return result;
  }

  _decodeDataUriArrayBuffer(isBase64, data) {
    const byteString = this._decodeDataUriText(isBase64, data);
    const buffer = new ArrayBuffer(byteString.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
      view[i] = byteString.charCodeAt(i);
    }
    return buffer;
  }

  _createBufferViews(gltf) {
    // buffer views

    for (let i in gltf.bufferViews) {

      if (gltf.bufferViews[i].buffer == this.id) {
        this.bufferViews.push(new BufferView(i, gltf, this));
      }
    }
  }

  _binaryAgent3(str) {
    return str.split(' ')
      .map(elem => String.fromCharCode(parseInt(elem, 2))
        .join(''));
  }

  _loadArrayBuffer(url, success, error) {
    this._loadWithXhr(url, 'arraybuffer', success, error);
  }

  _loadWithXhr(url, responseType, success, error) {
    var dataUriRegexResult = DATA_URI_REGEX.exec(url);
    if (dataUriRegexResult !== null) {
      success(this._decodeDataUri(dataUriRegexResult, responseType));
      return;
    }

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);

    if (typeof responseType !== 'undefined') {
      xhr.responseType = responseType;
    }

    xhr.onload = function (e) {
      if (xhr.status === 200) {
        success(xhr.response);
      } else {
        error(e);
      }
    };

    xhr.onerror = function (e) {
      error(e);
    };

    xhr.send();
  }

}

module.exports = Buffer;